<template>
    <div v-if="periodo == 1">
        <div v-for="dev in this.listDevices" :key="dev">
            <div v-if="testLoad"> 
                <div class="card" style="border-radius:15px">
                    <div class="grid formgrid">
                        <div class="field col-12 md:col-6 md:pr-6 pr-0">
                            <div class="custom-skeleton p-4">
                                <Skeleton width="100%" height="250px"></Skeleton>
                                <div class="flex justify-content-center mt-3">
                                    <Skeleton width="100%" height="80px"></Skeleton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card" style="margin-top: 2rem; border-radius:15px">
                    <div class="grid formgrid">
                        <div class="field col-12 md:col-6 md:pr-6 pr-0">
                            <div class="custom-skeleton p-4">
                                <Skeleton width="100%" height="250px"></Skeleton>
                                <div class="flex justify-content-center mt-3">
                                    <Skeleton width="100%" height="80px"></Skeleton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
            <div v-else>
                <div v-if="dev.type == 'flow' && this.amountDataFlow > 0" class="charts">
                    <highcharts v-if="dev.type == 'flow'" class="hc" :constructorType="'stockChart'" :options="chartOptionsFlowHourly" ref="chart"></highcharts>
                    <div class="hidden smaller"></div>
                </div>  
                <div v-if="dev.type == 'flow' && this.amountDataVolume > 0" class="charts">
                    <highcharts class="hc" :constructorType="'stockChart'" :options="chartOptionsVolumeHourly" :redrawOnUpdate="true" ref="chart"></highcharts>
                    <div class="hidden smaller"></div>
                </div>        
                <div v-if="dev.type == 'pressure' && this.amountDataPressure > 0" class="charts">
                    <highcharts class="hc" :options="chartOptionsPressureHourly" :constructorType="'stockChart'" :redrawOnUpdate="true" ref="chart"></highcharts>
                    <div class="hidden smaller"></div>
                </div>

                <div v-if="dev.type == 'noise' && this.amountDataNoise >0" class="charts">
                    <highcharts v-if="dev.type == 'noise'" class="hc" :constructorType="'stockChart'" :options="chartOptionsNoiseHourly" :redrawOnUpdate="true" ref="chart"></highcharts>
                    <div class="hidden smaller"></div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="periodo == 2">
        <div v-for="dev in this.listDevices" :key="dev">
            <div v-if="dev.type == 'flow' && this.amountDataFlow > 0" class="charts">
                <highcharts v-if="dev.type == 'flow'" class="hc" :constructorType="'stockChart'" :options="chartOptionsFlowDaily" ref="chart"></highcharts>
                <div class="hidden smaller"></div>
            </div>
            <div v-if="dev.type == 'flow' && this.amountDataVolume > 0" class="charts">
                <highcharts class="hc" :constructorType="'stockChart'" :options="chartOptionsVolumeDaily" :redrawOnUpdate="true" ref="chart"></highcharts>
                <div class="hidden smaller"></div>
            </div>   
            <div v-if="dev.type == 'pressure' && this.amountDataPressure > 0" class="charts">
                <highcharts v-if="dev.type == 'pressure'" :constructorType="'stockChart'" class="hc" :options="chartOptionsPressureDaily" ref="chart"></highcharts>
                <div class="hidden smaller"></div>
            </div>
            <div v-if="dev.type == 'noise' && this.amountDataNoise > 0" class="charts">
                <highcharts :constructorType="'stockChart'" class="hc" :options="chartOptionsNoiseDaily" ref="chart"></highcharts>
                <div class="hidden smaller"></div>
            </div>
        </div>
    </div>
    <div v-if="periodo == 3">
        <div v-for="dev in this.listDevices" :key="dev">
             <div v-if="dev.type == 'flow' && this.amountDataFlow > 0" class="charts">
                 <highcharts v-if="dev.type == 'flow'" class="hc" :constructorType="'stockChart'" :options="chartOptionsFlowWeekly" ref="chart"></highcharts>
                <div class="hidden smaller"></div>
            </div>
            <div v-if="dev.type == 'flow' && this.amountDataVolume > 0" class="charts">
                <highcharts class="hc" :constructorType="'stockChart'" :options="chartOptionsVolumeWeekly" :redrawOnUpdate="true" ref="chart"></highcharts>
                <div class="hidden smaller"></div>
            </div>  
            <div v-if="dev.type == 'pressure' && this.amountDataPressure > 0" class="charts">
                <highcharts v-if="dev.type == 'pressure'" :constructorType="'stockChart'" class="hc" :options="chartOptionsPressureWeekly" ref="chart"></highcharts>                
                <div class="hidden smaller"></div>
            </div>
            <div v-if="dev.type == 'noise' && this.amountDataNoise > 0" class="charts">
                <highcharts :constructorType="'stockChart'" class="hc" :options="chartOptionsNoiseWeekly" ref="chart"></highcharts>
                <div class="hidden smaller"></div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import { Chart } from 'highcharts-vue'
import Highcharts from "highcharts";
import HighchartsNoData from 'highcharts/modules/no-data-to-display'
import stockInit from "highcharts/modules/stock";
import highchartsMore from "highcharts/highcharts-more";
import { readingsCharts } from "../services/dashboard.js";
import { store } from '@/services/store.js';
import { useToast } from "primevue/usetoast";
import Skeleton from 'primevue/skeleton';

Highcharts.setOptions({
    lang: {
        months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
        shortMonths: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
        weekdays: ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'],
        loading: ['Atualizando o gráfico...aguarde'],
        contextButtonTitle: 'Exportar gráfico',
        decimalPoint: ',',
        thousandsSep: '.',
        downloadJPEG: 'Baixar imagem JPEG',
        downloadPDF: 'Baixar arquivo PDF',
        downloadPNG: 'Baixar imagem PNG',
        downloadSVG: 'Baixar vetor SVG',
        printChart: 'Imprimir gráfico',
        rangeSelectorFrom: 'De',
        rangeSelectorTo: 'Para',
        rangeSelectorZoom: 'Zoom',
        resetZoom: 'Limpar Zoom',
        resetZoomTitle: 'Voltar Zoom para nível 1:1',
    }
});

stockInit(Highcharts)
highchartsMore(Highcharts)
HighchartsNoData(Highcharts)

export default {
  name: 'Charts',
  components: {
    highcharts: Chart,
    Skeleton,
  },
  props: {
    dataInicial: String,
    dataFinal: String,
    periodo: String,
    devices: Object,
    upGrafico: Number,
  },
  data() {
    return {
        testLoad: null,
        listDevices: [],
        readingsFlow: null,
        readingsVolume: null,
        readingsPressure: null,
        readingsNoise: null,
        qtdePressure: 0,
        amountDataFlow: 0,
        amountDataVolume: 0,
        amountDataPressure:0,
        amountDataNoise: 0,
        alarmFlow: {},
        alarmPressure: {},
        local: null,
        chartOptionsVolumeHourly: {
            yAxis: {
                title: {
                    text: 'Volume (m³/H)'
                }
            },
            rangeSelector: {
                enabled: false
            },
            title: {
                text: 'Volume'
            },
            series: [{
                name: 'Volume',
                type: 'spline',
                tooltip: {
                    valueDecimals: 2
                }
            }]
        },
        chartOptionsVolumeDaily: {
            yAxis: {
                title: {
                    text: 'Volume (m³/H)'
                }
            },
            rangeSelector: {
                enabled: false
            },
            title: {
                text: 'Volume'
            },
            series: [{
                name: 'Volume',
                type: 'spline',
                tooltip: {
                    valueDecimals: 2
                }
            }]
        },
        chartOptionsVolumeWeekly: {
            yAxis: {
                title: {
                    text: 'Volume (m³/H)'
                }
            },
            rangeSelector: {
                enabled: false
            },
            title: {
                text: 'Volume'
            },      
            series: [{
                name: 'Volume',
                type: 'spline',
                tooltip: {
                    valueDecimals: 2
                }
            }]
        },

        
        chartOptionsFlowHourly: {
             title: {
                text: 'Vazão'
            },
            rangeSelector: {
                selected: 2
            },
            tooltip: {
                pointFormat: 'Vazão: <b>{point.y} m³/h</b>'
            },
            yAxis: [{
                title: {
                    text: 'Vazão (m³/h)'
                }
            }],
            series: [{
                type: 'column',
                name: 'Vazão (m³/h)',
                color: '#6EFF94'
            }]
        },

        chartOptionsFlowDaily: {
            chart: {
                type: 'column',
                zoomType: 'xy'
            },
            title: {
                text: 'Vazão'
            },
            rangeSelector: {
                selected: 1,
                enabled: true
            },
            yAxis: {
                title: {
                    text: 'Vazão'
                }
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: [{
                type: 'column',
                name: 'Vazão (m³/h)',
                color: '#6EFF94'
            }]
        },

        chartOptionsFlowWeekly: {
            chart: {
                type: 'column',
                zoomType: 'xy'
            },
            title: {
                text: 'Vazão'
            },
            rangeSelector: {
                selected: 1,
                enabled: true
            },
            yAxis: {
                title: {
                    text: 'Vazão'
                }
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: [{
                type: 'column',
                name: 'Vazão (m³/h)',
                color: '#6EFF94'
            }]
        },
        
        chartOptionsPressureHourly: {
            yAxis: {
                title: {
                    text: 'Pressão'
                }
            },
            rangeSelector: {
                enabled: false
            },
            title: {
                text: 'Pressão'
            },
            series: [{
                name: 'Pressão',
                type: 'spline',
                tooltip: {
                    valueDecimals: 2
                }
            }]
        },

        chartOptionsPressureDaily: {
            type: 'boxplot',
            rangeSelector: {
                enabled: false
            },
            title: {
                text: 'Pressão'
            },
            legend: {
                enabled: false
            },
            series: [{
                type: 'boxplot',
                name: 'Pressão',
            }]
        },
        chartOptionsPressureWeekly: {
            type: 'boxplot',
            rangeSelector: {
                enabled: false
            },
            title: {
                text: 'Pressão'
            },
            legend: {
                enabled: false
            },
            series: [{
                type: 'boxplot',
                name: 'Pressão'
            }]
        },

        chartOptionsNoiseHourly: {
            yAxis: {
                title: {
                    text: 'Ruído'
                }
            },
            rangeSelector: {
                enabled: false
            },
            title: {
                text: 'Ruído'
            },
            series: [{
                name: 'Ruído',
                type: 'spline',
                tooltip: {
                    valueDecimals: 2
                }
            }]
        },
        chartOptionsNoiseDaily: {
            rangeSelector: {
                enabled: false
            },
            title: {
                text: 'Ruído'
            },
            series: [{
                type: 'candlestick',
                name: 'Ruído'
            }]
        },
        chartOptionsNoiseWeekly: {
            rangeSelector: {
                enabled: false
            },
            title: {
                text: 'Ruído'
            },
            series: [{
                type: 'candlestick',
                name: 'Ruído'
            }]
        }
    }
  },
  watch: {
    readingsFlow: async function (){ 
        this.redrawFlow();
        await this.createdList(); 
    },
    readingsPressure: function (){ 
        this.redrawPressure();
    },
    readingsNoise: function (){ 
        this.redrawNoise();
    },
    devices: async function (){
        await this.createdList(); 
        await this.getData(); 
        this.redrawFlow();
        this.redrawPressure();
        this.redrawNoise();
    },
    async upGrafico () {
        await this.getData();
    },
  },
  methods: {
    redrawFlow: function () {
        this.testLoad = true;
        const legendaFinal = [];
        const dateFinal = [];
        // const legendaFinalDaily = [];
        const dateFinalDaily = [];
        // const legendaFinalWeekly = [];
        const dateFinalWeekly = [];
        
        var dataVolumeHourly = (this.readingsVolume && this.readingsVolume.cum_hourly)? this.readingsVolume.cum_hourly : [];
        var dataVolumeDaily = (this.readingsVolume && this.readingsVolume.cum_daily)? this.readingsVolume.cum_daily : [];
        var dataVolumeWeekly = (this.readingsVolume && this.readingsVolume.cum_weekly)? this.readingsVolume.cum_weekly : [];

        var dataFlowHourly = (this.readingsFlow && this.readingsFlow.hourly)? this.readingsFlow.hourly : [];
        var dataFlowDaily = (this.readingsFlow && this.readingsFlow.daily)? this.readingsFlow.daily : [];
        var dataFlowWeekly = (this.readingsFlow && this.readingsFlow.weekly)? this.readingsFlow.weekly : [];
        var dataAlarmHourly = (this.readingsFlow && this.readingsFlow.alarm_hourly)? this.readingsFlow.alarm_hourly : [];
        var dataAlarmDaily = (this.readingsFlow && this.readingsFlow.alarm_daily)? this.readingsFlow.alarm_daily : [];
        var dataAlarmWeekly = (this.readingsFlow && this.readingsFlow.alarm_weekly)? this.readingsFlow.alarm_weekly : [];

        this.amountDataFlow = 0;
        this.amountDataVolume = 0;
 
        if (dataVolumeHourly.length > 0) {
            this.amountDataVolume = 1;
        }

        if (dataFlowHourly.length > 0) {
            if (dataFlowHourly[0].length > 0) {
                this.amountDataFlow = 1;
            }
        }

        if (dataFlowDaily.length > 0) {
            if (dataFlowHourly[0].length > 0) {
                this.amountDataFlow = 1;
            }
        }

        if (dataFlowWeekly.length > 0) {
            if (dataFlowHourly[0].length > 0) {
                this.amountDataFlow = 1;
            }
        }

        if (dataFlowHourly.length > 0 && dataFlowHourly[0].length > 0) {
            let total = dataFlowHourly[0].length;
            
            for (let index = 0; index < total; index++) {
                let legenda = dataFlowHourly[0][index];
                let hourly = dataFlowHourly[1][index];
                
                if (dataAlarmHourly && dataAlarmHourly.length > 0) {
                    if (dataAlarmHourly.find(alarm => alarm == legenda)) {
                        dateFinal.push({x: legenda, y: hourly, color: '#80E29C'});
                    } else {
                        dateFinal.push({x: legenda, y: hourly, color: '#6EFF94'});   
                    }
                } else {
                    dateFinal.push({x: legenda, y: hourly, color: '#6EFF94'});   
                }
                    
                legendaFinal.push(legenda);
            }
            this.testLoad = false;
        }   

        if (dataFlowDaily.length > 0 && dataFlowDaily[0].length > 0) {
            let total = dataFlowDaily.length;
            for (let index = 0; index < total; index++) {
                let legenda = dataFlowDaily[index][0];
                let daily = dataFlowDaily[index][5];
                
                if (dataAlarmDaily && dataAlarmDaily.length > 0) {
                    if (dataAlarmDaily.find(alarm => alarm == legenda)) {
                        dateFinalDaily.push({x: legenda, y: daily, color: 'red'});
                    } else {
                        dateFinalDaily.push({x: legenda, y: daily, color: '#6EFF94'});   
                    }
                } else {
                    dateFinalDaily.push({x: legenda, y: daily, color: '#6EFF94'});   
                }
                    
                legendaFinal.push(legenda);
            }
            this.testLoad = false;
        }   

        if (dataFlowWeekly.length > 0 && dataFlowWeekly[0].length > 0) {
            let total = dataFlowWeekly.length;
            
            for (let index = 0; index < total; index++) {
                let legenda = dataFlowWeekly[index][0];
                let weekly = dataFlowWeekly[index][5];
                
                if (dataAlarmWeekly && dataAlarmWeekly.length > 0) {
                    if (dataAlarmWeekly.find(alarm => alarm == legenda)) {
                        dateFinalWeekly.push({x: legenda, y: weekly, color: 'red'});
                    } else {
                        dateFinalWeekly.push({x: legenda, y: weekly, color: '#6EFF94'});   
                    }
                } else {
                    dateFinalWeekly.push({x: legenda, y: weekly, color: '#6EFF94'});   
                }
                    
                legendaFinal.push(legenda);
            }
            this.testLoad = false;
        }   
        this.chartOptionsFlowHourly = {
            time: {
                timezoneOffset: this.local  * 60,
            },
            chart: {
                type: 'column',
                zoomType: 'xy'
            },
            rangeSelector: {
                selected: 1,
                enabled: false
            },
            title: {
                text: 'Vazão'
            },
            tooltip: {
                pointFormat: 'Vazão: <b>{point.y} m³/h</b>'
            },
            yAxis: {
            title: {
                text: 'Vazão (m³/h)'
            },
            plotLines: [{
                value: this.alarmFlow.lower_limit,
                color: 'red',
                dashStyle: 'shortdash',
                width: 2,
                label: {
                    text: 'Valor mínimo'
                },
                zIndex: 10
            }, {
                value: this.alarmFlow.upper_limit,
                color: 'red',
                dashStyle: 'shortdash',
                width: 2,
                label: {
                    align: 'right',
                    text: 'Valor máximo',
                    x: -40
                },
                zIndex: 10
            }]
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: [{
                type: 'column',
                name: 'Vazão (m³/h)',
                data: dateFinal,
                color: '#6EFF94'
            }]
        } 

        this.chartOptionsFlowDaily = {
            time: {
                timezoneOffset: this.local  * 60,
            },
            chart: {
                type: 'column',
                zoomType: 'xy'
            },
            title: {
                text: 'Vazão'
            },
            rangeSelector: {
                selected: 1,
                enabled: false
            },
            tooltip: {
                pointFormat: 'Vazão: <b>{point.y} m³/h</b>'
            },
            yAxis: [{
                title: {
                    text: 'Vazão (m³/h)'
                }
            }],
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: [{
                type: 'column',
                name: 'Vazão (m³/h)',
                data: dateFinalDaily,
                color: '#6EFF94'
            }]
        } 

        this.chartOptionsFlowWeekly = {
            time: {
                timezoneOffset: this.local  * 60,
            },
            chart: {
                type: 'column',
                zoomType: 'xy'
            },
            title: {
                text: 'Vazão'
            },
            rangeSelector: {
                selected: 1,
                enabled: false
            },
            tooltip: {
                pointFormat: 'Vazão: <b>{point.y} m³/h</b>'
            },
            yAxis: [{
                title: {
                    text: 'Vazão (m³/h)'
                }
            }],
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: [{
                type: 'column',
                name: 'Vazão (m³/h)',
                data: dateFinalWeekly,
                color: '#6EFF94'
            }]
        } 

        const legendaFinalVolumeHourly = [];
        const dateFinalVolumeHourly = [];
        const legendaFinalVolumeDaily = [];
        const dateFinalVolumeDaily = [];
        const legendaFinalVolumeWeekly = [];
        const dateFinalVolumeWeekly = [];
        
        if (dataVolumeHourly.length > 0) {
            let total = dataVolumeHourly.length;
            for (let index = 0; index < total; index++) {
                let hourlyVolume = dataVolumeHourly[index];
                dateFinalVolumeHourly.push(hourlyVolume); 
                legendaFinalVolumeHourly.push(hourlyVolume[0]);
            }
            this.testLoad = false;
        }  

        if (dataVolumeDaily.length > 0) {
            let total = dataVolumeDaily.length;
            for (let index = 0; index < total; index++) {
                let hourlyVolume = dataVolumeDaily[index];
                dateFinalVolumeDaily.push(hourlyVolume); 
                legendaFinalVolumeDaily.push(hourlyVolume[0]);
            }
            this.testLoad = false;
        }  

        if (dataVolumeWeekly.length > 0) {
            let total = dataVolumeWeekly.length;
            for (let index = 0; index < total; index++) {
                let hourlyVolume = dataVolumeWeekly[index];
                dateFinalVolumeWeekly.push(hourlyVolume); 
                legendaFinalVolumeWeekly.push(hourlyVolume[0]);
            }
            this.testLoad = false;
        }  
        this.testLoad = false;
        this.chartOptionsVolumeHourly = {
            time: {
                timezoneOffset: this.local  * 60,
            },
            chart: {
                zoomType: 'x'
            },
            yAxis: {
                title: {
                    text: 'Volume (m³)'
                }
            },
            rangeSelector: {
                enabled: false
            },
            title: {
                text: 'Volume'
            },
            xAxis: {
                categories: legendaFinalVolumeHourly
            },
            tooltip: {
                pointFormat: 'Volume: <b>{point.y} m3</b>'
            },
            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: false,
                        borderRadius: 2,
                        valueDecimals: 2
                    }
                }
            },
            series: [{
                marker: {
                    enabled: true
                },
                name: 'Volume',
                data: dateFinalVolumeHourly,
                type: 'spline',
                tooltip: {
                    valueDecimals: 2
                }
            }]
        }    
        
        this.chartOptionsVolumeDaily = {
            time: {
                timezoneOffset: this.local  * 60,
            },
            chart: {
                zoomType: 'x'
            },
            yAxis: {
                title: {
                    text: 'Volume (m³)'
                }
            },
            rangeSelector: {
                enabled: false
            },
            title: {
                text: 'Volume'
            },
            xAxis: {
                categories: legendaFinalVolumeDaily,
            },
            tooltip: {
                pointFormat: 'Volume: <b>{point.y} m3</b>'
            },
            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: false,
                        borderRadius: 2,
                        valueDecimals: 2
                    }
                }
            },
            series: [{
                marker: {
                    enabled: true
                },
                name: 'Volume',
                data: dateFinalVolumeDaily,
                type: 'spline',
                tooltip: {
                    valueDecimals: 2
                }
            }]
        }    
        
        this.chartOptionsVolumeWeekly = {
            time: {
                timezoneOffset: this.local  * 60,
            },
            chart: {
                zoomType: 'x'
            },
            yAxis: {
                title: {
                    text: 'Volume (m³)'
                }
            },
            rangeSelector: {
                enabled: false
            },
            title: {
                text: 'Volume'
            },
            xAxis: {
                categories: legendaFinalVolumeWeekly
            },
            tooltip: {
                pointFormat: 'Volume: <b>{point.y} m3</b>'
            },
            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: false,
                        borderRadius: 2,
                        valueDecimals: 2
                    }
                }
            },
            series: [{
                marker: {
                    enabled: true
                },
                name: 'Volume',
                data: dateFinalVolumeWeekly,
                type: 'spline',
                tooltip: {
                    valueDecimals: 2
                }
            }]
        } 
    },
    redrawPressure: function () {
        this.testLoad = true;
        var dataPressureHourly = (this.readingsPressure && this.readingsPressure.time)? this.readingsPressure.time : [];
        var dataPressureDaily = (this.readingsPressure && this.readingsPressure.daily)? this.readingsPressure.daily : [];
        var dataPressureWeekly = (this.readingsPressure && this.readingsPressure.weekly)? this.readingsPressure.weekly : [];
        var dataAlarmHourly = (this.readingsPressure && this.readingsPressure.alarm_time)? this.readingsPressure.alarm_time : [];
        var dataAlarmDaily = (this.readingsPressure && this.readingsPressure.alarm_daily)? this.readingsPressure.alarm_daily : [];
        var dataAlarmWeekly = (this.readingsPressure && this.readingsPressure.alarm_weekly)? this.readingsPressure.alarm_weekly : [];

        this.amountDataPressure = 0;

        if (dataPressureHourly.length > 0) {
            this.amountDataPressure = 1;
        }
        
        const legendaFinal = [];
        const dateFinal = [];
        const dateFinalDaily = [];
        const dateFinalWeekly = [];

        if (dataPressureHourly.length > 0) {
            let total = dataPressureHourly.length;
            const _data_hourly = new Set(dataAlarmHourly);
            for (let index = 0; index < total; index++) {
                let hourly = dataPressureHourly[index];
                if (dataAlarmHourly && dataAlarmHourly.length > 0) {
                    if (_data_hourly.has(hourly[0])) {
                        dateFinal.push({x: hourly[0], y: hourly[1], marker: {
                            fillColor: 'red'
                        }}); 
                    } else {
                        dateFinal.push({x: hourly[0], y: parseFloat(hourly[1].toFixed(2))});   
                    }
                } else {
                    dateFinal.push({x: hourly[0], y: parseFloat(hourly[1].toFixed(2))});   
                }
                legendaFinal.push(moment(hourly[0]).format('DD/MM/YYYY HH:mm:SS'));
            }
            this.testLoad = false;
        }   
        this.testLoad = false;
        this.chartOptionsPressureHourly = {
            time: {
                timezoneOffset: this.local  * 60,
            },
            chart: {
                zoomType: 'x'
            },
            yAxis: {
            title: {
                    text: 'Pressão (m.c.a.)'
                },
            plotLines: [{
                value: this.alarmPressure.lower_limit,
                color: 'red',
                dashStyle: 'shortdash',
                width: 2,
                label: {
                    text: 'Valor mínimo'
                },
                zIndex: 10
            }, {
                value: this.alarmPressure.upper_limit,
                color: 'red',
                dashStyle: 'shortdash',
                width: 2,
                label: {
                    align: 'right',
                    text: 'Valor máximo',
                    x: -40
                },
                zIndex: 10
            }]
            },
            rangeSelector: {
                enabled: false
            },
            title: {
                text: 'Pressão'
            },
            tooltip: {
                pointFormat: 'Pressão: <b>{point.y} m.c.a.</b>'
            },
            xAxis: {
                categories: legendaFinal
            },
            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: false,
                        borderRadius: 2,
                        valueDecimals: 2
                    },
                    turboThreshold: 10000
                }
            },
            series: [{
                marker: {
                    enabled: true
                },
                name: 'Pressão (MCA)',
                data:  dateFinal,
                type: 'spline',
                tooltip: {
                    valueDecimals: 2
                }
            }]
        }    
        
        if (dataPressureDaily.length > 0) {
            for (let index = 0; index < dataPressureDaily.length; index++) {
                let daily = dataPressureDaily[index];

                if (dataAlarmDaily && dataAlarmDaily.length > 0) {
                    if (dataAlarmDaily.find(alarm => alarm == daily[0])) {
                        dateFinalDaily.push({
                            x: daily[0],
                            low: daily[1],
                            q1: daily[2],
                            median: daily[3],
                            q3: daily[4],
                            high: daily[5],
                            color: "red"
                        }); 
                    } else {
                        dateFinalDaily.push({
                            x: daily[0],
                            low: daily[1],
                            q1: daily[2],
                            median: daily[3],
                            q3: daily[4],
                            high: daily[5],
                            color: ""
                        });  
                    }
                } else {
                    dateFinalDaily.push({
                        x: daily[0],
                        low: daily[1],
                        q1: daily[2],
                        median: daily[3],
                        q3: daily[4],
                        high: daily[5],
                        color: ""
                    }); 
                }
            }
        }   
        
        this.chartOptionsPressureDaily = {
            time: {
                timezoneOffset: this.local  * 60,
            },
            rangeSelector: {
                enabled: false
            },
            title: {
                text: 'Pressão'
            },
            yAxis: {
                title: {
                    text: 'Pressão (m.c.a.)'
                }
            },
            legend: {
                enabled: false
            },
            series: [{
                type: 'boxplot',
                name: 'Pressão',
                data: dateFinalDaily
            }]
        }

        if (dataPressureWeekly.length > 0) {
            for (let index = 0; index < dataPressureWeekly.length; index++) {
                let weekly = dataPressureWeekly[index]; 
                
                if (dataAlarmWeekly && dataAlarmWeekly.length > 0) {
                    if (dataAlarmWeekly.find(alarm => alarm == weekly[0])) {
                        dateFinalWeekly.push({
                            x: weekly[0],
                            low: weekly[1],
                            q1: weekly[2],
                            median: weekly[3],
                            q3: weekly[4],
                            high: weekly[5],
                            color: "red"
                        }); 
                    } else {
                        dateFinalWeekly.push({
                            x: weekly[0],
                            low: weekly[1],
                            q1: weekly[2],
                            median: weekly[3],
                            q3: weekly[4],
                            high: weekly[5],
                            color: ""
                        });  
                    }
                } else {
                    dateFinalWeekly.push({
                        x: weekly[0],
                        low: weekly[1],
                        q1: weekly[2],
                        median: weekly[3],
                        q3: weekly[4],
                        high: weekly[5],
                        color: ""
                    }); 
                }
            }
        }   
        
        this.chartOptionsPressureWeekly = {
            time: {
                timezoneOffset: this.local  * 60,
            },
            rangeSelector: {
                enabled: false
            },
            title: {
                text: 'Pressão'
            },
            yAxis: {
                title: {
                    text: 'Pressão (m.c.a.)'
                }
            },
            series: [{
                type: 'boxplot',
                name: 'Pressão',
                data: dateFinalWeekly
            }]
        }
    },
    redrawNoise: function () {
        this.testLoad = true;
        var dataNoiseHourly = (this.readingsNoise && this.readingsNoise.hourly)? this.readingsNoise.hourly : [];
        var dataNoiseDaily = (this.readingsNoise && this.readingsNoise.daily)? this.readingsNoise.daily : [];
        var dataNoiseWeekly = (this.readingsNoise && this.readingsNoise.weekly)? this.readingsNoise.weekly : [];
        var dataAlarmHourly = (this.readingsNoise && this.readingsNoise.alarm_hourly)? this.readingsNoise.alarm_hourly : [];
        var dataAlarmDaily = (this.readingsNoise && this.readingsNoise.alarm_daily)? this.readingsNoise.alarm_daily : [];
        var dataAlarmWeekly = (this.readingsNoise && this.readingsNoise.alarm_weekly)? this.readingsNoise.alarm_weekly : [];

        this.amountDataNoise = 0;

        if (dataNoiseHourly.length > 0) {
            this.amountDataNoise = 1;
        }
        
        const legendaFinal = [];
        const dateFinal = [];
        const dateFinalDaily = [];
        const dateFinalWeekly = [];
        
        if (dataNoiseHourly.length > 0) {
            let total = dataNoiseHourly.length;
            for (let index = 0; index < total; index++) {
                let hourly = dataNoiseHourly[index];
                
                if (dataAlarmHourly && dataAlarmHourly.length > 0) {
                    if (dataAlarmHourly.find(alarm => alarm == hourly[0])) {
                        dateFinal.push({x: hourly[0], y: hourly[1], marker: {
                            fillColor: 'red'
                        }}); 
                    } else {
                        dateFinal.push({x: hourly[0], y: parseFloat(hourly[1].toFixed(2))});     
                    }
                } else {
                    dateFinal.push({x: hourly[0], y: parseFloat(hourly[1].toFixed(2))});   
                }
                legendaFinal.push(moment(hourly[0]).format('DD/MM/YYYY HH:mm:SS'));
            }
            this.testLoad = false;
        }   
        
        this.chartOptionsNoiseHourly = {
            time: {
                timezoneOffset: this.local  * 60,
            },
            chart: {
                zoomType: 'x'
            },
            yAxis: {
                title: {
                    text: 'Ruído %'
                },
                labels: {
                    format: '{value}%'
                }
            },
            rangeSelector: {
                enabled: false
            },
            title: {
                text: 'Ruído'
            },
            tooltip: {
                pointFormat: 'Ruído: <b>{point.y}%</b>'
            },            
            xAxis: {
                categories: legendaFinal
            },
            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: false,
                        borderRadius: 2,
                        valueDecimals: 2
                    }
                }
            },
            series: [{
                marker: {
                    enabled: true
                },
                name: 'Ruído',
                data:  dateFinal,
                type: 'spline',
                tooltip: {
                    valueDecimals: 2
                }
            }]
        }    

        if (dataNoiseDaily.length > 0) {
            for (let index = 0; index < dataNoiseDaily.length; index++) {
                let daily = dataNoiseDaily[index];

                if (dataAlarmDaily && dataAlarmDaily.length > 0) {
                    if (dataAlarmDaily.find(alarm => alarm == daily[0])) {
                        dateFinalDaily.push({
                            x: daily[0],
                            open: daily[1],
                            high: daily[2],
                            low: daily[3],
                            close: daily[4],
                            color: "red"
                        }); 
                    } else {
                        dateFinalDaily.push({
                            x: daily[0],
                            open: daily[1],
                            high: daily[2],
                            low: daily[3],
                            close: daily[4],
                            color: ""
                        });  
                    }
                } else {
                    dateFinalDaily.push({
                        x: daily[0],
                        open: daily[1],
                        high: daily[2],
                        low: daily[3],
                        close: daily[4],
                        color: ""
                    }); 
                }
            }
        } 

        this.chartOptionsNoiseDaily = {
            time: {
                timezoneOffset: this.local  * 60,
            },
            rangeSelector: {
                enabled: false
            },
            title: {
                text: 'Ruído'
            },
            yAxis: {
                title: {
                    text: 'Ruído %'
                },
                labels: {
                    format: '{value}%'
                }
            },
            series: [{
                type: 'candlestick',
                name: 'Ruído',
                data: dateFinalDaily
            }]
        }

        if (dataNoiseWeekly.length > 0) {
            for (let index = 0; index < dataNoiseWeekly.length; index++) {
                let weekly = dataNoiseWeekly[index];

                if (dataAlarmWeekly && dataAlarmWeekly.length > 0) {
                    if (dataAlarmWeekly.find(alarm => alarm == weekly[0])) {
                        dateFinalWeekly.push({
                            x: weekly[0],
                            open: weekly[1],
                            high: weekly[2],
                            low: weekly[3],
                            close: weekly[4],
                            color: "red"
                        }); 
                    } else {
                        dateFinalWeekly.push({
                            x: weekly[0],
                            open: weekly[1],
                            high: weekly[2],
                            low: weekly[3],
                            close: weekly[4],
                            color: ""
                        });  
                    }
                } else {
                    dateFinalWeekly.push({
                        x: weekly[0],
                        open: weekly[1],
                        high: weekly[2],
                        low: weekly[3],
                        close: weekly[4],
                        color: ""
                    }); 
                }
            }
        }  

        this.chartOptionsNoiseWeekly = {
            time: {
                timezoneOffset: this.local  * 60,
            },
            rangeSelector: {
                enabled: false
            },
            title: {
                text: 'Ruído'
            },
            yAxis: {
                title: {
                    text: 'Ruído %'
                },
                labels: {
                    format: '{value}%'
                }
            },
            series: [{
                type: 'candlestick',
                name: 'Ruído',
                data: dateFinalWeekly
            }]
        }
    },
    
    async getData () {
        this.readingsFlow = [];
        this.readingsVolume = [];
        this.readingsPressure = [];
        this.readingsNoise = [];
        let jsonFilter = {};
        let jsonFilter2 = {};
        let adjTz = moment().tz(`${Intl.DateTimeFormat().resolvedOptions().timeZone}`).format('Z').toString().split(':')[0];
        adjTz = (parseInt(adjTz)*-1).toString()
        adjTz = adjTz.length == 1 ? ` 0${adjTz}:00` : ` ${adjTz}:00`

        if (this.listDevices.length > 0) {

                this.listDevices.map(async (device) => {
                jsonFilter.device_id = device.id;
                jsonFilter.from = this.dataInicial+adjTz;
                jsonFilter.to = this.dataFinal;
                jsonFilter.time_format = "timestamp";

                switch (device.type) {
                    case 'flow':
                        jsonFilter.hourly_type = "column";
                        jsonFilter.daily_type = "boxplot";
                        jsonFilter.weekly_type = "boxplot";
                        jsonFilter.cummulated = true;
                        jsonFilter.delta = true;
                        jsonFilter.interpolated = false;
                        
                        jsonFilter2.device_id = device.id;
                        jsonFilter2.from = this.dataInicial+adjTz;
                        jsonFilter2.to = this.dataFinal;
                        jsonFilter2.time_format = "timestamp";
                        jsonFilter2.hourly_type = "column";
                        jsonFilter2.daily_type = "boxplot";
                        jsonFilter2.weekly_type = "boxplot";
                        jsonFilter2.cummulated = true;
                        jsonFilter2.delta = false;
                        jsonFilter2.interpolated = false;
                        break;
                    case 'pressure':
                        jsonFilter.hourly_type = "line";
                        jsonFilter.daily_type = "boxplot";
                        jsonFilter.weekly_type = "boxplot";
                        jsonFilter.cummulated = false;
                        jsonFilter.interpolated = false;
                        jsonFilter.delta = false;
                        break;
                    case 'noise':
                        jsonFilter.hourly_type = "line";
                        jsonFilter.daily_type = "candlestick";
                        jsonFilter.weekly_type = "candlestick";
                        jsonFilter.cummulated = false;
                        jsonFilter.delta = false;
                        jsonFilter.interpolated = false;
                        break;
                    default:
                        break;
                }
                
                try {

                    const response = await readingsCharts(jsonFilter)
                    .then((res) => {
                        return res;
                    })
                    .catch((error) => {
                        this.showErrorServer(error);
                    })

                    let responseVolume = {}
                    if (device.type == 'flow') {
                        responseVolume = await readingsCharts(jsonFilter2)
                        .then((res) => {
                            return res;
                        })
                        .catch((error) => {
                            this.showErrorServer(error);
                        })
                    }
                    if (response && response.message) {
                        alert('Dispositivo não encontrado')
                    }
                    switch (device.type) {
                        case 'flow':
                            this.readingsFlow = response.data;
                            this.readingsVolume = responseVolume.data;
                            break;
                        case 'pressure':
                            this.readingsPressure = response.data
                            break;
                        case 'noise':
                            this.readingsNoise = response.data
                            break;
                        default:
                            break;
                    }
                } catch (error) {
                    this.showErrorServer(error);
                    this.testLoad = false;
                } 
            })
        }
    },
    async createdList () {
        this.listDevices = [];

        const deviceFlow = this.devices.find(device => device.type == 'flow');
        if (deviceFlow) {
            this.listDevices.push(deviceFlow)
            this.alarmFlow = (deviceFlow.slider) ? {'lower_limit':deviceFlow.slider[0], 'upper_limit':deviceFlow.slider[1]}: deviceFlow.alarm_boundaries
        }
        const deviceNoise = this.devices.find(device => device.type == 'noise');
        if (deviceNoise) this.listDevices.push(deviceNoise)

        const devicePressure = this.devices.find(device => device.type == 'pressure');
        if (devicePressure) {
            this.listDevices.push(devicePressure)
            this.alarmPressure = (devicePressure.slider) ? {'lower_limit':devicePressure.slider[0], 'upper_limit':devicePressure.slider[1]}: devicePressure.alarm_boundaries
        }        

    }
  },
  
  async created () { 
    await this.createdList(); 
    await this.getData(); 
  },
  mounted () {
      this.local = store.local;
      if(store.summer == true) {
              this.local--
          }
  },
  setup() {
    const toast = useToast();
    const showErrorServer = () => {
      toast.add({severity:'error', summary: 'Error', detail:" Erro ao acessar o servidor!", life: 4000});
    }
    return {showErrorServer}
  }
}
</script>
<style>
body {
    background: #F1EEEE;
}
.charts {
    position: relative;
    margin: 0px auto 10px auto;
}
.hc {
    position: relative;
    background-color: #FFF;
    padding-top: 1rem;
    border-radius: 15px;
}
.hidden {
    height: 20px;
    width: 20%;
    position: absolute;
    bottom: 0px;
    background: #fff;
    z-index: 9;
    right: 0px;
    border-radius: 25px;
}
.hidden.smaller {
    height: 12px;
}
.highcharts-data-labels .highcharts-label.highcharts-data-label.highlight text {
    fill: red !important;
    color: red !important;
}
</style>