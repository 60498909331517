import { createRouter, createWebHistory } from "vue-router";
import Login from '../components/Login.vue';
import RedefinirSenha from '../components/RedefinirSenha.vue';
import Dashboard from '../components/Dashboard.vue';
import Sensores from '../components/Sensores.vue';
import AlterarSenha from '../components/AlterarSenha.vue';
import ParametrosConfig from '../components/ParametrosConfig.vue';
import CadClientes from '../components/CadClientes.vue';
import CadUsuarios from '../components/CadUsuarios.vue';
import CadDispositivos from '../components/CadDispositivos.vue';
import ListDispositivos from '../components/ListDispositivos.vue';
import ListUsuarios from '../components/ListUsuarios.vue';
import ListClientes from '../components/ListClientes.vue';
import ListComissionamento from '../components/ListComissionamento.vue';
import CadComissionamento from '../components/CadComissionamento.vue';
import PontosIntalacao from '../components/PontosIntalacao.vue';
import NovaTag from '../components/NovaTag.vue';
import CadAtivacao from '../components/CadAtivacao.vue';
import ListAtivacao from '../components/ListAtivacao.vue';
import { store } from '../services/store.js'

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/redefinirSenha',
    name: 'RedefinirSenha',
    component: RedefinirSenha
  },
  {
    path: '/pontosInstalacao',
    name: 'PontosInstalacao',
    component: PontosIntalacao
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/sensores',
    name: 'Sensores',
    component: Sensores,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/alterarSenha',
    name: 'AlterarSenha',
    component: AlterarSenha,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/parametrosConfig',
    name: 'ParametrosConfig',
    component: ParametrosConfig,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/cadastro/clientes',
    name: 'CadClientes',
    component: CadClientes,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/cadastro/usuarios',
    name: 'CadUsuarios',
    component: CadUsuarios,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/cadastro/dispositivos',
    name: 'CadDispositivos',
    component: CadDispositivos,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/listagem/dispositivos',
    name: 'ListDispositivos',
    component: ListDispositivos,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/novaTag',
    name: 'NovaTag',
    component: NovaTag , 
    meta: {
      requiresAuth: true
    }   
  },
  {
    path: '/listagem/usuarios',
    name: 'ListUsuarios',
    component: ListUsuarios,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/listagem/clientes',
    name: 'ListClientes',
    component: ListClientes,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/listagem/comissionamento',
    name: 'ListComissionamento',
    component: ListComissionamento,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/cadastro/comissionamento',
    name: 'CadComissionamento',
    component: CadComissionamento,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/cadastro/ativacao',
    name: 'CadAtivacao',
    component: CadAtivacao,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/listagem/ativacao',
    name: 'ListAtivacao',
    component: ListAtivacao,
    meta: {
      requiresAuth: true
    }
  }
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to) => {
  const aux = localStorage.getItem('userData');
  const tokenLocalStorage = aux ? JSON.parse(aux) : null;
  if(tokenLocalStorage){
      store.client_id = tokenLocalStorage.client_id
      store.token = tokenLocalStorage.token
      store.user_id = tokenLocalStorage.user_id
      store.name = tokenLocalStorage.name
      store.type_user = tokenLocalStorage.type_user
      store.trading_name = tokenLocalStorage.trading_name
      store.email = tokenLocalStorage.email
  }
  if(to.meta.requiresAuth && !store.token){
    return {name: 'Login'}
  }
})
export default router