<template>
    <Toast />
  <div
    class="head-btn"   
  >
    <h2>Listagem de clientes</h2>
    <div class="groupCadastros">
      <GroupbtnAdmin></GroupbtnAdmin>
    </div>
    <div class="d-grid gap-2 col-2" style="justify-items: self-end">
      <router-link to="/cadastro/clientes">
        <!-- <button class="btn btn-login" type="button" style="line-height: 2rem">
          Cadastrar
        </button> -->
        <div class="btn btn-login" type="button">
          Cadastrar
        </div>
      </router-link>
    </div>
  </div>
  <div class="card">
    <DataTable
      :value="products"
      v-model:selection="selectedProduct1"
      selectionMode="single"
      dataKey="id"
      :paginator="true"
      :rows="10"
      class="paginator-custom"
      @rowSelect="onRowSelect"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 20, 50]"
      responsiveLayout="scroll"
      currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords}"
    >
      <Column field="trading_name" header="Nome Fantasia" :sortable="true"></Column>
      <Column field="corporate_name" header="Razão Social"></Column>      
      <Column field="contact" header="Contato"></Column>    
      <Column field="telephone" header="Telefone"></Column>    
      <Column field="created_at" header="Data de Criação">
        <template #body="{ data }">{{
          new Date(data.created_at).toLocaleString("pt-br")
        }}</template>
      </Column>
      <Column field="active" header="Ativo">
        <template #body="{ data }">
          {{ data.active === true ? "Sim" : "Não" }}
        </template>
      </Column>
    </DataTable>
  </div>
  <Dialog
    header="Edição"
    class="dialog-custom"
    v-model:visible="displayBasic"
    :style="{ width: '75vw' }"
  >
    <div class="row" v-if="local_country == 'pt-BR'">
      <p style="font-size:1rem">* Campos Obrigatórios</p>
      <div class="col-md-6">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            autocomplete="off"
            placeholder="Nome Fantasia"
            v-model="nome_fantasia"
            aria-describedby="addon-wrapping"
            id="floatingInputGrid"
          />
          <label for="floatingInputGrid">Nome Fantasia *</label>
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            autocomplete="off"
            placeholder="Razão Social"
            v-model="razao_social"
            aria-describedby="addon-wrapping"
            id="floatingInputGrid"
          />
          <label for="floatingInputGrid">Razão Social *</label>
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            placeholder="CNPJ"
            v-model="cnpj"
            autocomplete="off"
            aria-describedby="addon-wrapping"
            id="floatingInputGrid"
          />
          <label for="floatingInputGrid">CNPJ *</label>
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            placeholder="CEP"
            autocomplete="off"
            v-model="cep"
            aria-describedby="addon-wrapping"
            id="floatingInputGrid"
          />
          <label for="floatingInputGrid">CEP</label>
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            placeholder="Endereço"
            autocomplete="off"
            v-model="endereco"
            aria-describedby="addon-wrapping"
            id="floatingInputGrid"
          />
          <label for="floatingInputGrid">Endereço</label>
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            placeholder="Número"
            v-model="numero"
            autocomplete="off"
            aria-describedby="addon-wrapping"
            id="floatingInputGrid"
          />
          <label for="floatingInputGrid">Número</label>
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-floating">
          <input
            type="text"
            autocomplete="off"
            class="form-control"
            placeholder="Bairro"
            v-model="bairro"
            aria-describedby="addon-wrapping"
            id="floatingInputGrid"
          />
          <label for="floatingInputGrid">Bairro</label>
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            placeholder="Cidade"
            autocomplete="off"
            v-model="cidade"
            aria-describedby="addon-wrapping"
            id="floatingInputGrid"
          />
          <label for="floatingInputGrid">Cidade</label>
        </div>
      </div>

      <div class="col-sm-6 form-floating">
        <Multiselect
          :columns="{ container: 12, label: 4, wrapper: 6 }"
          v-model="estado"
          placeholder="Selecione"
          class="multiselect-custom form-select"
          :options="states"
          id="floatingInputGrid"
        />
        <label for="floatingInputGrid" style="margin-left: 15px;font-size:12px">Estado</label>
      </div>

      <div class="col-md-6">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            placeholder="Contato"
            autocomplete="off"
            v-model="contato"
            aria-describedby="addon-wrapping"
            id="floatingInputGrid"
          />
          <label for="floatingInputGrid">Contato</label>
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            placeholder="Telefone"
            v-model="telefone"
            aria-describedby="addon-wrapping"
            autocomplete="off"
            id="floatingInputGrid"
          />
          <label for="floatingInputGrid">Telefone</label>
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            placeholder="Complemento"
            autocomplete="off"
            v-model="complemento"
            aria-describedby="addon-wrapping"
            id="floatingInputGrid"
          />
          <label for="floatingInputGrid">Complemento</label>
        </div>
      </div>
      <div class="col-sm-12">
      <div class="form-floating">
        <input
          type="text"
          class="form-control"
          placeholder="Observações"
          autocomplete="off"
          v-model="observacoes"
          aria-describedby="addon-wrapping"
          id="floatingInputGrid"
        />
        <label for="floatingInputGrid">Observações</label>
      </div>
    </div>
    </div>

    <div class="row" v-else>
      <p style="font-size:1rem">* Campos Obrigatórios</p>
      <div class="col-md-6">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            autocomplete="off"
            placeholder="Nome Fantasia"
            required
            v-model="nome_fantasia"
            aria-describedby="addon-wrapping"
            id="floatingInputGrid"
          />
          <label for="floatingInputGrid">Nome Fantasia *</label>
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            autocomplete="off"
            placeholder="Razão Social"
            v-model="razao_social"
            aria-describedby="addon-wrapping"
            id="floatingInputGrid"
          />
          <label for="floatingInputGrid">Razão Social *</label>
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-floating">
          <!-- <input
            type="text"
            class="form-control"
            autocomplete="off"
            placeholder="CNPJ"
            v-model="cnpj"
            aria-describedby="addon-wrapping"
            id="floatingInputGrid"
          /> -->
          <InputMask
              mask="99.999.999/9999-99"
              v-model="cnpj"
              placeholder="* CNPJ"
              class="form-control size"
              aria-describedby="addon-wrapping"
              autocomplete="off"
              required
              id="floatingInputGrid"
            />
          <label for="floatingInputGrid">CNPJ *</label>
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            pattern="\d{5}-?\d{3}"
            placeholder="Código Postal"
            autocomplete="off"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
            v-model="cep"
            aria-describedby="addon-wrapping"
            id="floatingInputGrid"
          />
          <label for="floatingInputGrid">Código Postal</label>
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            placeholder="Morada"
            autocomplete="off"
            v-model="endereco"
            aria-describedby="addon-wrapping"
            id="floatingInputGrid"
          />
          <label for="floatingInputGrid">Morada</label>
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-floating">
          <input
            type="text"
            autocomplete="off"
            class="form-control"
            placeholder="Número"
            v-model="numero"
            aria-describedby="addon-wrapping"
            id="floatingInputGrid"
          />
          <label for="floatingInputGrid">Número</label>
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            autocomplete="off"
            placeholder="Concelho"
            v-model="bairro"
            aria-describedby="addon-wrapping"
            id="floatingInputGrid"
          />
          <label for="floatingInputGrid">Concelho</label>
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            placeholder="Distrito"
            v-model="cidade"
            aria-describedby="addon-wrapping"
            id="floatingInputGrid"
            autocomplete="off"
          />
          <label for="floatingInputGrid">Distrito</label>
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-floating">
          <input
            type="text"
            autocomplete="off"
            class="form-control"
            placeholder="Contato"
            v-model="contato"
            aria-describedby="addon-wrapping"
            id="floatingInputGrid"
          />
          <label for="floatingInputGrid">Contato</label>
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-floating">
          <InputMask
            mask="(99) 99999999?9"
            type="text"
            class="form-control"
            autocomplete="off"
            placeholder="Telefone"
            v-model="telefone"
            aria-describedby="addon-wrapping"
            id="floatingInputGrid"
          />
          <label for="floatingInputGrid">Telefone</label>
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            placeholder="Complemento"
            v-model="complemento"
            autocomplete="off"
            aria-describedby="addon-wrapping"
            id="floatingInputGrid"
          />
          <label for="floatingInputGrid">Complemento</label>
        </div>
      </div>
      <div class="col-sm-6">
      <div class="form-floating">
        <input
          type="text"
          class="form-control"
          autocomplete="off"
          placeholder="Observações"
          v-model="observacoes"
          aria-describedby="addon-wrapping"
          id="floatingInputGrid"
        />
        <label for="floatingInputGrid">Observações</label>
      </div>
    </div>
    </div>

    <div class="form-check" style="display: flex; justify-content: center">
      <input
        class="form-check-input"
        v-model="ativo"
        type="checkbox"
        value=""
        id="flexCheckDefault"
      />
      <label
        class="form-check-label"
        for="flexCheckDefault"
        style="margin-left: 10px"
      >
        Ativo
      </label>
    </div>

    <template #footer>
      <button @click="closeBasic" class="btn btn-danger">Cancelar</button>
      <button @click="saveEdit()" class="btn btn-filtrar">Salvar</button>
    </template>
  </Dialog>
</template>

<script>
import DataTable from "primevue/datatable";
import GroupbtnAdmin from "../components/groupbtnAdmin.vue";
import Column from "primevue/column";
import { ref, onMounted } from "vue";
import { useLoading } from "vue-loading-overlay";
import Dialog from "primevue/dialog";
import Multiselect from "@vueform/multiselect";
import { store } from "../services/store"
import InputMask from "primevue/inputmask";
import { filterCep } from "../services/cep.js";
import { useToast } from "primevue/usetoast";
import Toast from "primevue/toast";
import { listClients, upDateClient } from "../services/crud.js";
const $loading = useLoading();

export default {
  components: {
    Column,
    DataTable,
    Dialog,
    Toast,
    Multiselect,
    GroupbtnAdmin,
    InputMask
  },
  data() {
    return {
      isSuper: (store.type_user == 1) ? true : false,
      dataForm: "",
      local_country: "",
      states: [
        { value: "AC", label: "Acre" },
        { value: "AL", label: "Alagoas" },
        { value: "AP", label: "Amapá" },
        { value: "AM", label: "Amazonas" },
        { value: "BA", label: "Bahia" },
        { value: "CE", label: "Ceará" },
        { value: "DF", label: "Distrito Federal" },
        { value: "ES", label: "Espirito Santo" },
        { value: "GO", label: "Goiás" },
        { value: "MA", label: "Maranhão" },
        { value: "MT", label: "Mato Grosso" },
        { value: "MS", label: "Mato Grosso do Sul" },
        { value: "MG", label: "Minas Gerais" },
        { value: "PA", label: "Pará" },
        { value: "PB", label: "Paraíba" },
        { value: "PR", label: "Paraná" },
        { value: "PE", label: "Pernambuco" },
        { value: "PI", label: "Piauí" },
        { value: "RJ", label: "Rio de Janeiro" },
        { value: "RN", label: "Rio Grande do Norte" },
        { value: "RS", label: "Rio Grande do Sul" },
        { value: "RO", label: "Rondônia" },
        { value: "RR", label: "Roraima" },
        { value: "SC", label: "Santa Catarina" },
        { value: "SP", label: "São Paulo" },
        { value: "SE", label: "Sergipe" },
        { value: "TO", label: "Tocantins" },
      ],
    };
  },
  setup() {
    const products = ref();
    const displayBasic = ref(false);

    const closeBasic = () => {
      displayBasic.value = false;
      getClients();
    };

    const getClients = async () => {
      await listClients()
        .then((response) => {
          products.value = response.data;
        })
        .catch((err) =>
          this.showErrorServer(err)
        );
    }

    const selectedProduct1 = ref();
    const endereco = ref(); //address
    const cidade = ref(); //city
    const cnpj = ref();
    const contato = ref(); //contact
    const numero = ref(); //number
    const razao_social = ref(); //trading_name
    const nome_fantasia = ref();
    const cep = ref(); //zip_code
    const telefone = ref(); //telephone
    const estado = ref(); //state
    const complemento = ref(); //complement
    const observacoes = ref(); //comments
    const bairro = ref(); //comments
    const ativo = ref(); //comments

    onMounted(async () => {
      const loader = $loading.show({});

      getClients();
      
      loader.hide();
    });
    const onRowSelect = (event) => {
      endereco.value = event.data.address;
      cidade.value = event.data.city;
      cnpj.value = event.data.cnpj;
      contato.value = event.data.contact;
      numero.value = event.data.number;
      razao_social.value = event.data.corporate_name;
      nome_fantasia.value = event.data.trading_name;
      cep.value = event.data.zip_code;
      telefone.value = event.data.telephone;
      estado.value = event.data.state;
      complemento.value = event.data.complement;
      observacoes.value = event.data.comments;
      bairro.value = event.data.neighborhood;
      ativo.value = event.data.active;
      displayBasic.value = true;
    };

    const toast = useToast();
    const showError = () => {
      toast.add({severity:'error', summary: 'Error', detail:'Campos Obrigatórios não foram preenchidos!', life: 4000});
    }
    const showErrorServer = () => {
      toast.add({severity:'error', summary: 'Error', detail:" Erro ao acessar o servidor!", life: 4000});
    }
    const showsuccess = () => {
      toast.add({severity:'success', detail:" Dados do cliente alterados com sucesso! ", life: 4000});
    }
    
    return {
      products,
      showError,
      showErrorServer,
      showsuccess,
      selectedProduct1,
      onRowSelect,
      closeBasic,
      displayBasic,
      endereco,
      cidade,
      cnpj,
      contato,
      numero,
      razao_social,
      cep,
      telefone,
      estado,
      complemento,
      observacoes,
      bairro,
      ativo,
      nome_fantasia,
    };
  },
  methods: {
    saveEdit: async function () {
      const dataUpdate = {
        active: this.ativo,
        address: this.endereco,
        city: this.cidade,
        client_id_master: null,
        cnpj: this.cnpj,
        comments: this.observacoes,
        complement: this.complemento,
        contact: this.contato,
        corporate_name: this.razao_social,
        created_at: this.dataForm.created_at,
        id: this.dataForm.id,
        lat: this.dataForm.lat,
        long: this.dataForm.long,
        number: this.numero,
        state: this.estado,
        telephone: this.telefone,
        trading_name: this.nome_fantasia,
        updated_at: this.dataForm.updated_at,
        zip_code: this.cep,
      };
      if (!this.nome_fantasia || !this.nome_fantasia.trim() || !this.razao_social || !this.razao_social.trim() || !this.cnpj || !this.cnpj.trim()){
        this.showError()
      }else{
        await upDateClient(dataUpdate)
          .then((response) => {
            if (response.status >= 200 && response.status <= 299) {
              this.showsuccess()
              this.closeBasic();
            }
          })
          .catch((err) =>
            this.showErrorServer(err)
          );
      }
    },
  },
  watch: {
    selectedProduct1: function (val) {
      const dataF = {
        active: val.active,
        address: val.address,
        city: val.city,
        client_id_master: null,
        cnpj: val.cnpj,
        comments: val.comments,
        complement: val.complement,
        contact: val.contact,
        corporate_name: val.corporate_name,
        created_at: val.created_at,
        id: val.id,
        lat: val.lat,
        long: val.long,
        number: val.number,
        state: val.state,
        telephone: val.telephone,
        trading_name: val.trading_name,
        updated_at: val.updated_at,
        zip_code: val.zip_code,
      };
      this.dataForm = dataF;
    },
    cep: function (val) {
      if(val){
        if (val.length === 8) {
        filterCep(val).then((resp) => {
          if (resp.status >= 200 && resp.status <= 299) {
            this.endereco = resp.data.logradouro;
            this.cidade = resp.data.localidade;
            this.estado = resp.data.uf;
            this.bairro = resp.data.bairro;
            val = val.replace(/^(\d{5})(\d)/,"$1-$2")
            this.cep = val;
          } else {
            val = val.replace(/^(\d{5})(\d)/,"$1-$2")
            this.cep = val;
          }
        });
      }
      }
    },
  },
  mounted () {
    this.local_country = store.local_country;
  }
};
</script>

<style scoped>
/* .head-btn {
  display: flex;
  justify-content: space-between;
  align-items: center
} */
.btn-login {
  border-color: #6EFF94;
  background-color: #6EFF94;
  color: black;
  line-height: 2rem;
  font-size: 1.1rem;
  margin: 1rem;
  width: 8rem;
  font-weight: 450;
}
h2 {
  margin: 1rem;
}
.btn-outline-success {
  border-color: #6EFF94;
  color: black;
  background-color: white;
}
.btn-outline-success:hover {
  border-color: #6EFF94;
  background-color: #6EFF94;
  color: black;
}
.btn-check:checked + .btn-outline-success {
  background-color: #6EFF94;
  border-color: #6EFF94;
  color: black;
}
.groupCadastros {
  text-align-last: center;
}
.dropdown-item {
  padding: 0rem 0rem;
}
.card {
  padding: 0rem;
  box-shadow: 0px 4px 19px 4px rgb(0 0 0 / 20%);
  margin: 1rem;
}
.btn-filtrar {
  border-color: #6EFF94;
  background-color: #6EFF94;
  color: black;
  margin: 1rem 0rem;
  width: 95%;
}
.form-floating {
  margin: 1rem 0rem;
}

.form-floating > .form-control,
.form-floating> .form-select {
  background-color: #e8f0fe;
  color: black;
  line-height: 3rem;
  margin: 0rem 0.2rem;
}
.input-group > .form-control,
.input-group > .form-select {
  background-color: #e8f0fe;
  color: black;
  line-height: 3rem;
  margin: 0rem 0.2rem;
}
.input-group {
  margin: 1rem 0rem;
}
input::placeholder {
  color: black;
}
.input-group-text {
  background-color: #37393c;
  padding: 0.375rem 1.2rem;
}
.multiselect {
  height: 3.63rem;
  margin: 1rem 0rem;
  background: #e8f0fe;
}
.multiselect-placeholder {
  color: black !important;
}
.btn-router {
  line-height: 2rem;
  width: 100%;
}
@media screen and (max-width: 500px) {
  .btn-group {
    display: block;
  }
  .head-btn {
    display: block;
  }
}
@media screen and (min-width: 501px) {
  .head-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
<style lang="scss" scoped>
::v-deep(.paginator-custom) {
  .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: #6EFF94;
    border-color: #e3f2fd;
    color: #495057;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    color: #495057;
    background: #6EFF94;
  }
  .p-datatable .p-datatable-tbody > tr.p-highlight {
    background: #6EFF94;
    color: #495057;
  }
}
::v-deep(.multiselect-custom) {
  .multiselect-placeholder {
    color: black;
  }
}
</style>
