import axios from 'axios';

const BASE_URL_CEP = 'https://viacep.com.br/ws/';

const BASE_URL_CEP_LAT_LNG = 'https://maps.googleapis.com/maps/api/geocode/json?address=';
const KEY_GOOLGE = 'AIzaSyBdFL0H_1G2YvqihYbtRte7eNz22icTW30';

export const filterCep = (id) => {
    const url = `${BASE_URL_CEP}${id}/json/`

    let json = axios.get(url)
      return json
}

export const latlng = (cep) => {
    const url = `${BASE_URL_CEP_LAT_LNG}${cep}&key=${KEY_GOOLGE}`

    let json = axios.get(url)
      return json
}

export const isSuper = true;

// https://maps.googleapis.com/maps/api/geocode/json?address=SEU_CEP&key=SUA_API_KEY

export default{    
    filterCep,
    latlng,
    isSuper
}