<template>
<body>  
  <main>
    <div class="card col-sm-4" id="main_box">
      <div class="card-body">
        <img class="img_persona" src='../assets/lock.png'>
        <p> Redefinir Senha </p>
        
        <div class="input-group flex-nowrap">
          <span class="input-group-text" id="addon-wrapping"><div class="gg-lock"></div></span>
          <div class="form-floating input-size"> 
            <input type="password" class="form-control" placeholder="Digite a senha atual *" aria-label="password" v-model="oldPassword" autocomplete="off" aria-describedby="addon-wrapping" id="floatingInputGridA">
            <label for="floatingInputGridA">Digite a senha atual *</label>
          </div>
        </div>

        <div class="input-group flex-nowrap">
          <span class="input-group-text" id="addon-wrapping"><div class="gg-lock"></div></span>
          <div class="form-floating input-size"> 
            <input type="password" class="form-control" placeholder="Digite a senha atual *" aria-label="password" v-model="password" autocomplete="off" aria-describedby="addon-wrapping" id="floatingInputGridB">
            <label for="floatingInputGridB">Nova Senha *</label>
          </div>
        </div>

        <div class="input-group flex-nowrap">
          <span class="input-group-text" id="addon-wrapping"><div class="gg-lock"></div></span>
          <div class="form-floating input-size"> 
            <input type="password" class="form-control" placeholder="Repetir nova senha *" aria-label="password" v-model="compair" autocomplete="off" aria-describedby="addon-wrapping" id="floatingInputGridC">
            <label for="floatingInputGridC">Repetir nova senha *</label>
          </div>
        </div>

        <div class="d-grid gap-2 col-8 mx-auto">
            <button @click="submit" class="btn btn-login" type="button"><b>Redefinir</b></button>
        </div>
        <div class="alert alert-success" v-show="successAlert" role="alert">
          Senha alterada com sucesso!
          <router-link to="/dashboard">
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </router-link>
        </div>
        <div id="alertErrorLogin" v-show="errorLogin" class="alert alert-warning alert-dismissible fade show" role="alert">
          <strong>Todos os campos são obrigatórios!</strong>
          <button type="button" class="btn-close" @click="submit" aria-label="Close"></button>
        </div>
        <div id="alertErrorType" v-show="errorLoginTypePass" class="alert alert-warning alert-dismissible fade show" role="alert">
          <strong>As novas senhas não conferem!</strong>
          <button type="button" class="btn-close" @click="submit" aria-label="Close"></button>
        </div>
      </div>
    </div>
  </main>
</body>
</template>

<script>
import { store } from '../services/store.js'
import {useLoading} from 'vue-loading-overlay'
import { newPassword } from "../services/login.js";
import moment from 'moment'
const $loading = useLoading()
moment.locale('pt-BR')

export default {
  name: 'Login',
  props: {
    msg: String
  },
  data() {
    return {
      successAlert: false,
      password: '',
      oldPassword: '',
      compair: '',
      errorLogin: false,
      errorLoginTypePass: false,
    }
  },
  mounted(){
    setInterval(() => {
            this.now = moment()
        }, 1000);
  },
  methods: {
    async submit () {
      const loader = $loading.show({});

      if ( this.oldPassword.length > 0 && this.password.length > 0 && this.compair.length > 0 ) {
        if (this.password === this.compair) {
          let _data = {};
          _data.id = store.user_id;
          _data.password = this.password;
          _data.oldPassword = this.oldPassword;
          await newPassword(_data)
            .then(() => this.success())
            .catch(() => this.errorLogin = !this.errorLogin)
         
        } else {
          this.errorPassword();
        }
      } else {
        this.error();
      }
      this.password = '';
      this.oldPassword = '';
      this.compair = '';
      loader.hide()
    },
    success () {
      this.successAlert = !this.successAlert
    },
    error () {
      this.errorLogin = !this.errorLogin
    },
    errorPassword () {
      this.errorLoginTypePass = !this.errorLoginTypePass
    },
  }
}
</script>

<style scoped>
body{
  background-image: url('../assets/inteligentes.jpg');
  background-repeat: no-repeat;
  background-size: cover;  
  height: 110vh;
  margin-top: -1rem
}
.img_persona{
  height: 9rem;
  position: relative;
  margin-top: -6rem;
}
.input-size {
  width: -webkit-fill-available
}
main{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.card-body {
  display: flex;
  flex: 1 1 auto;
  padding: 1rem 1rem;
  flex-direction: column;
  align-items: center;
}
@import url('https://unpkg.com/css.gg@2.0.0/icons/css/lock.css');
.gg-lock {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs,1.5));
  width: 12px;
  height: 11px;
  border: 2px solid;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom: transparent;
  margin-top: -12px;
  color: white;
}
.btn-login{
  border-color: #6EFF94;
  background-color: #6EFF94;
  color: black;
  line-height: 2rem;
  font-size: 1.5rem;
  margin:1rem
}
.input-group>.form-control, .input-group>.form-select{
  color: black;
  line-height: 3rem
}
.form-control{
  background: #e8f0fe;
}
.input-group{
  margin: 1rem 1rem
}
input::placeholder {
  color: black;
}
.input-group-text{
  background-color: #37393c;
  padding: 0.375rem 1.2rem;
}
.card{
  border-radius: 2.25rem;
  padding: 1rem;
  margin-bottom: 3rem;
  margin-top: 10rem;
}
p{
  font-size: 1.5rem;
}
@media only screen and (min-width: 575px) and (max-width: 875px) { 
  #main_box{
    width: 52%;
  }
}
@media only screen and (max-height: 730px) {
  .card{
    margin-top: 6rem;
  }
}
</style>
