<template>
  <nav v-show="$route.path !== '/' && $route.path !== '/redefinirSenha'" class="navbar navbar-light bg-light">
    <div class="container">
      <a class="navbar-brand">
        <router-link v-if="isOperation != 3 && isOperation != ''"
          to="/dashboard">
          <img src='./assets/LogoStattus4PositivoVerde.png' alt="Logo Stattus4" width="100" height="25">
        </router-link>
        <router-link v-if="isOperation == 3 && isOperation != ''"
          to="/listagem/ativacao">
          <img src='./assets/LogoStattus4PositivoVerde.png' alt="Logo Stattus4" width="100" height="25">
        </router-link>
        <router-link v-if="isOperation != 3 && isOperation != ''"
          :class="{'btn btn-outline-success me-2 btn-navbar-active': $route.path === '/dashboard'}"
          class="btn-navbar btn btn-outline-success me-2"
          to="/dashboard">
          <div class="gg-home marginIcon"></div>Home
        </router-link>
        <router-link v-if="isOperation != 3 && isOperation != ''"
          :class="{'btn btnSensor btn-outline-success me-2 btn-navbar-active': $route.path === '/sensores'}"
          class="btn-navbar btn btn-outline-success me-2 btnSensor"
          to="/sensores">
          <div class="gg-data marginIcon"></div>Pontos de Instalação
        </router-link>
      </a>
      <div
        class="col-sm-3 VerComoMap"
        @click="listaClientes(listClients)"
        v-if="isSuper && $route.path === '/dashboard' || isSuper && $route.path === '/sensores' || isSuper"
        style="margin-left: -4.5rem;"
      >
        <Multiselect
          :columns="{ container: 12, label: 4, wrapper: 6 }"
          v-model="empresa"
          class="multiselect-custom"
          :placeholder="placeholder"
          :options="listaDeClientes"
          :hide="searchClientMulti(empresa)"
        />
      </div>
      <span class="navbar-text rightNavbar">
        <div class="_name"> {{this.name}} </div>
        <div class="tradding_name"> - {{this.trading_name}}</div>
        <div class="dropdown">
          <button class="btn btn-outline-success me-2"><div class="gg-chevron-down-o"></div></button>
          <div class="dropdown-content">
            <router-link v-if="isSuper"
              :class="{'dropdown-item btn-navbar btn btn-outline-success me-2': $route.path === '/'}"
              class="dropdown-item btn-navbar btn btn-outline-success me-2"
              to="/listagem/clientes">
              <div class="marginDropdownOptions"></div>Admin
            </router-link>
            <router-link v-if="isOperation != 3 && isOperation != '' && isSuper == false"
              :class="{'dropdown-item btn-navbar btn btn-outline-success me-2': $route.path === '/'}"
              class="dropdown-item btn-navbar btn btn-outline-success me-2"
              to="/listagem/comissionamento">
              <div class="marginDropdownOptions"></div>Admin
            </router-link>
            <router-link
              :class="{'dropdown-item btn-navbar btn btn-outline-success me-2': $route.path === '/'}"
              class="dropdown-item btn-navbar btn btn-outline-success me-2"
              to="/alterarSenha">
              <div class="marginDropdownOptions"></div>Alterar Senha
            </router-link>
            <router-link
              :class="{'dropdown-item btn-navbar btn btn-outline-success me-2': $route.path === '/'}"
              class="dropdown-item btn-navbar btn btn-outline-success me-2"
              to="/parametrosConfig">
              <div class="marginDropdownOptions"></div>Parâmetros de Configuração
            </router-link >
            <a v-if="isOperation != 3 && isOperation != ''"
              class="dropdown-item btn-navbar btn btn-outline-success me-2" 
              @click="displayModal()">Criar TAG</a>
            <router-link
              @click="zerarToken"
              :class="{'dropdown-item btn-navbar btn btn-outline-success me-2': $route.path === '/'}"
              class="dropdown-item btn-navbar btn btn-outline-success me-2"
              to="/">
              <div class="marginDropdownOptions"></div>Sair
            </router-link>
          </div>
        </div>
      </span>
    </div>
  </nav>
  <router-view/>
  <Toast />
  <Dialog :closable=false v-model:visible="display" @hide="zerarTag()" :modal=true>
    <template #header>
      <h3>Tags Criadas</h3>
    </template>      
      <!-- <Chip style="margin:0.5rem" :key="info.id_name" v-for="info in this.listTags" :label="info.label" :class="info.color" removable @remove="remover(info.id_name)" /> -->
      <Chip style="margin:0.5rem" :key="info.id_name" v-for="info in this.listTags" :label="info.label" :class="info.color"  @remove="remover(info.id_name)" 
      :style="info.color != 'yellow' && info.color != 'aqua' && info.color != 'pink' ? `color: white;background-color: ${info.color}; border: 1px solid;font-size: 14px;margin-bottom: 5px;`: `color: black;background-color: ${info.color}`"
      >
        <div style="padding:6px">{{info.label}}
          <div @click="remover(info.id_name)" style="display: contents;cursor:pointer">
            <i class="pi pi-times-circle" style="margin-left:2px"></i>
          </div>
        </div>
        
      </Chip>
      
      <h3 style="margin-top:2rem">Criar Nova Tag</h3>
      <div class="col-sm-12">
        <div class="form-floating col-sm-12">
          <input type="text" class="form-control" placeholder="Nome" autocomplete="off" v-model="newTagName" maxlength="15" aria-describedby="addon-wrapping" id="floatingInputGrid" />
          <label for="floatingInputGrid">Nome: (máx: 15 caracteres)</label>
        </div>
        <SelectButton v-model="color" class="col-sm-12" :options="listaCores" dataKey="value" style="margin-top:1rem">
            <template #option="slotProps">
                <div :class="'square_'+slotProps.option.value"> </div>                
            </template>
        </SelectButton>
      </div>
    <template #footer>      
      <Button label="Cancelar" @click="fecharModal()" autofocus class="btn btn-cancelar"/>
      <Button label="Criar" @click="salvarNovaTag()" autofocus class="btn btn-filtrar"/>
    </template>
  </Dialog>
  <div>
    <Dialog v-model:visible="displayMessage" :closable=false :modal=true>
    <template #header>
      <div style="margin:auto"> 
        <h2 style="color:red">ATENÇÃO !</h2>
      </div>
    </template> 
    <div style="text-align:center; width:400px"> 
      <h3>Deseja excluir a tag ?</h3> 
    </div>
    <template #footer>
      <div style="display: flex;justify-content: space-around;">
        <Button label="Cancelar" @click="closeBasic" icon="pi pi-times" autofocus class="btn cancel" style="background-color: red; color: white"/>     
        <Button label="Excluir" @click="removeTag" icon="pi pi-check" autofocus class="btn delete" style="background-color:  #6eff94; color:white"/>    
      </div>  
    </template>
  </Dialog>
  </div>
</template>

<script>
import { ref } from 'vue';
import { store } from '../src/services/store.js'
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import Chip from 'primevue/chip';
import { listTag, deleteTag, createTag} from "../src/services/crud.js";
import { useToast } from "primevue/usetoast";
import Toast from "primevue/toast";
import SelectButton from 'primevue/selectbutton';
import Multiselect from "@vueform/multiselect";
import { VerComoClients } from "../src/services/dashboard.js";


export default{
  name: 'App',
  components: {
    Chip,
    Toast,
    Dialog,
    Button,
    SelectButton,
    Multiselect
  },
  data() {
    return {
      name: '',
      trading_name: '',
      isSuper: false,
      isOperation:  '',
      display: false,
      displayMessage: false,
      listTags: [],
      newTagName: '',
      id_nameTag: '',
      color: '',
      tempIdTag:null,
      listaDeClientes: [],
      clients: null,
      listClients: [],
      empresa: null,
      placeholder: "Ver Como: ",
    }
  },
  watch: {
    '$route.path': function (){ 
      this.nameNavBar(store.name, store.trading_name)
      this.isOperation = store.type_user
      this.makeArrClient()
      if(!this.isSuper) {
        this.getClient();
      }
    },
    empresa: function (){
      this.emitter.emit('my-event-select-multi', this.empresa)
    }
  },
  methods: {
    closeBasic() {
      this.displayMessage = false;
    },
    zerarTag(){
      this.listTags= []
    },
    async salvarNovaTag() {
      let temp = null;
      this.id_nameTag = this.newTagName.toLocaleLowerCase().replace(/\s/g, '_').normalize('NFD').replace(/[\u0300-\u036f]/g, "")

      if(!this.newTagName || !this.newTagName.trim() || !this.id_nameTag || !this.id_nameTag.trim || !this.color.value ){
        this.showError()
      }else{
        const dataForm = {
          name: this.newTagName,
          id_name: this.id_nameTag,
          color: this.color.value,
          client_id: store.client_id
        }
        await createTag(dataForm)
        .then((response) => {
          if (response.status >= 200 && response.status <= 299) {
            this.newTagName = "";
            temp = {
              label: dataForm.name,
              color: dataForm.color,
              id_name: dataForm.id_name
            }
            this.listTags.push(temp)
          }
          this.emitter.emit('my-event-add-tag')
        })
        .catch((response) => this.showErrorServer(response))
      }
    },
    fecharModal () {
      this.display = !this.display
      this.zerarTag()
    },
    async remover(id_tag){
      const dataDelete = {
        client_id: store.client_id,
        id_name: id_tag
      }
      this.tempIdTag = dataDelete;
      this.displayMessage = !this.displayMessage;
    },
    async removeTag() {
      let temp = [];

      this.listTags.filter(item =>{
        if(item.id_name != this.tempIdTag.id_name)
        temp.push(item)
      })
      this.listTags = temp
      this.displayMessage = false;

      await deleteTag(this.tempIdTag)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          this.showSuccessDelete()
          this.tempIdTag = ''
        }
        this.emitter.emit('my-event-remove-tag')
        this.listTags.length >= 10 ? this.deztags = false : this.deztags = true
      })
      .catch((err) => this.showErrorServer(err))
    },
    displayModal(){
      this.display = !this.display

      let aux = []
      let listaTags = []
      const client_id = {"client_id":store.id}
      listTag(client_id)
      .then((response) => {
        aux = response.data      
        for (let i = 0; i < aux.length; i++) {
          listaTags = {
            label: aux[i].name,
            color: aux[i].color,
            id_name: aux[i].id_name
          }
          this.listTags.push(listaTags)
        }
      })
    },
    nameNavBar(name, trading_name){
      this.name = name 
      this.trading_name = trading_name
    },
    zerarToken() {
      localStorage.removeItem('userData')
      location.reload();
    },
    listaClientes(listClients) {
      this.listaDeClientes = listClients;
    },
    getNameClient() {
      if (this.listaDeClientes.length > 0) {
        let temp = this.listaDeClientes.filter((i) => {
          return i.value === this.empresa;
        });
        store.name_client = temp[0];
      }
    },
    getClient() {
      this.empresa = store.client_id;
      this.placeholder = store.trading_name;
    },
    async makeArrClient() {
      this.listClients = [];
      if (this.$route.path === '/dashboard' || this.$route.path === '/sensores') {
        this.isSuper= (store.type_user == 1) ? true : false,
        await VerComoClients()
        .then((response) => {
            this.clients = response.data;
            for (let i = 0; i < this.clients.length; i++) {
              this.listClients.push({
                label: this.clients[i].trading_name,
                value: this.clients[i].id,
              });
            }
        })
        .catch(() => console.log("erro"));
      }
      this.getNameClient();
      },
      searchClientMulti(data) {
        this.emitter.emit('my-event-select-client', data)
      }
  },
  setup(){
    const listaCores = ref( [
        { label: "Vermelho", value: 'red' },
        { label: "Verde", value: 'green' },
        { label: "Azul", value: 'blue' },
        { label: "Amarelo", value: 'yellow' },
        { label: "Cinza", value: 'gray' },
        { label: "Rosa", value: 'pink' },
        { label: "Roxo", value: 'purple' },
        { label: "Marrom", value: 'brown' },
        { label: "Aqua", value: 'aqua' },
        { label: "Laranja", value: 'orange' },
      ])

    const toast = useToast();
    const showError = () => {
      toast.add({severity:'error', summary: 'Error', detail:'Campos Obrigatórios não foram preenchidos!', life: 4000});
    }
    const showErrorServer = () => {
      toast.add({severity:'error', summary: 'Error', detail:" Erro ao acessar o servidor!", life: 4000});
    }
    const showsuccess = () => {
      toast.add({severity:'success', detail:"Tag adicionada com sucesso! ", life: 4000});
    }
    const showSuccessDelete = () => {
      toast.add({severity:'success', detail:"Tag removida com sucesso! ", life: 4000});
    }
    const showErrorAlreadyExisted = () => {
      toast.add({severity:'error', detail:"Tag já existente! ", life: 4000});
    }
    return{showError,showErrorServer,showsuccess, listaCores,showSuccessDelete, showErrorAlreadyExisted}
  }
}
</script>

<style lang="scss" scoped>
::v-deep(.selectColor){
  .p-colorpicker-preview {
    width: 3.5rem !important;
    height: 3.5rem !important;
    margin-left: 2rem !important
  }
}
@import url('https://unpkg.com/css.gg@2.0.0/icons/css/home.css');
.p-dialog-footer {
    display: flex;
    justify-content: space-around;
  }
.gg-home {
  background:
      linear-gradient(to left,
          currentColor 5px,transparent 0)
          no-repeat 0 bottom/4px 2px,
      linear-gradient(to left,
          currentColor 5px,transparent 0)
          no-repeat right bottom/4px 2px;
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs,1));
  width: 18px;
  height: 15px;
  border: 2px solid;
  border-top: 0;
  border-bottom: 0;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  margin-bottom: -4px
}
.btn-filtrar {
  border-color: #6eff94;
  background-color: #6eff94;
  color: black;
  margin: 1rem 0rem !important;
  width: 10% !important;
  font-weight: 400;
  height: 42px;
}
.btn-cancelar, .btn-cancelar:hover  {
  border-color: red;
  background-color: red;
  color: black;
  margin: 1rem 1rem !important;
  width: 10% !important;
  font-weight: 400;
  height: 42px;
}
.btn-filtrar:hover {
  border-color: #6eff94;
  background-color: #6eff94;
  color: black;
  width: 10% !important;
  margin: 1rem 0rem !important;
  font-weight: 400;
  height: 42px;
}
.navbar{
  padding: 0%;
  box-shadow: 1px 4px 17px 0px rgb(0 0 0 / 20%);
  margin-bottom: 0.5rem
}
.btn-navbar{
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-left: 5rem
}
.btn-outline-success:hover {
  color: black;
  background-color: #6eff94;
  border-color: #6eff94;
}
.btn-outline-success {
  color:  black;
  border-color: #F8F9FA
}
.btn-navbar-active{
  color: black;
  background-color: #6eff94;
  border-color: #6eff94;
}
.btnSensor{
  margin: 0rem 1rem;
}
@import url('https://unpkg.com/css.gg@2.0.0/icons/css/file-document.css');
@import url('https://unpkg.com/css.gg@2.0.0/icons/css/data.css');
@import url('https://unpkg.com/css.gg@2.0.0/icons/css/log-out.css');
@import url('https://unpkg.com/css.gg@2.0.0/icons/css/chevron-down-o.css');

/* Dropdown Button */
.dropbtn {
  background-color: #04AA6D;
  color: white;
  padding: 6px 12px;
  font-size: 16px;
  border: none;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 2;
  right: 0;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  display: block;
  margin: 0%;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #ddd;}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {display: block;}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {background-color: #6eff94}

.rightNavbar{
  display: flex; 
  align-items: center;
}

.marginDropdownOptions{
  margin-right: 0.8rem
}

.marginIcon{
  margin-right: 0.4rem
}
.container{
  max-width: 95%
}
.dropdown{
  margin-left: 0.5rem;
}
@media screen and (max-width: 500px) {
  .dropdown-content {
    right: auto;
    left: 0;
  }
}
@media screen and (min-width: 501px) {
  .dropdown-content {
    right: 0;
    left: auto;
  }
}
@media only screen and (max-width: 850px) { 
  .tradding_name{
    display: none;
  }  
}
@media only screen and (max-width: 715px){ 
  ._name{
    display: none;
  }  
}
@media only screen and (max-width: 525px){ 
  .container{
    max-width: 100%
  }
  .btn-navbar{
    margin-left: 0.5rem
  }
  .btnSensor{
    margin: 0rem;
  }
  .dropdown{
    margin-left: 0rem;
  }
}
.square_gray{
  height: 2rem;
  width: 3.5rem;
  background: gray;
  color: white;
  margin-right: 0.5rem !important;
}
.square_red{
  height: 2rem;
  width: 3.5rem;
  background: red;
  color: white;
  margin-right: 0.5rem !important;
}
.square_green{
  height: 2rem;
  width: 3.5rem;
  background: green;
  color: white;
  margin-right: 0.5rem !important;
}
.square_blue{
  height: 2rem;
  width: 3.5rem;
  background: blue;
  color: white;
  margin-right: 0.5rem !important;
}
.square_yellow{
  height: 2rem;
  width: 3.5rem;
  background: yellow;
  color: white;
  margin-right: 0.5rem !important;
}
.square_pink{
  height: 2rem;
  width: 3.5rem;
  background: pink;
  color: white;
  margin-right: 0.5rem !important;
}
.square_purple{
  height: 2rem;
  width: 3.5rem;
  background: purple;
  color: white;
  margin-right: 0.5rem !important;
}
.square_brown{
  height: 2rem;
  width: 3.5rem;
  background: brown;
  color: white;
  margin-right: 0.5rem !important;
}
.brown{
  background: brown;
  color: white;
  margin-right: 0.5rem !important;
}
.aqua{
  background: aqua ;
  color: white;
  margin-right: 0.5rem !important;
}
.square_aqua{
  height: 2rem;
  width: 3.5rem;
  background: aqua ;
  color: white;
  margin-right: 0.5rem !important;
}
.square_orange{
  height: 2rem;
  width: 3.5rem;
  background: orange;
  color: white;
  margin-right: 0.5rem !important;
}
.orange{
  background: orange;
  color: white;
  margin-right: 0.5rem !important;
}
.gray{  
  background: gray;
  color: white;
  margin-right: 0.5rem !important;
}
.purple{
  background: purple;
  color: white;
  margin-right: 0.5rem !important;
}
.red{
  background: red;
  color: white;
  margin-right: 0.5rem !important;
}.green{
  background: green;
  color: white;
  margin-right: 0.5rem !important;
}.blue{
  background: blue;
  color: white;
  margin-right: 0.5rem !important;
}.yellow{
  background: yellow;
  color: white;
  margin-right: 0.5rem !important;
}
.pink{
  background: pink;
  color: white;
  margin-right: 0.5rem !important;
}
.cancel{
  border: red 1px solid;
  background-color: red;
  color: white;
}
.cancel:hover{
  border: red 1px solid;
  background-color: red;
  color: white;
}
.delete{
  border: #6eff94 1px solid;
  background-color: #6eff94;
  color: white;
}
.delete:hover{
  border: #6eff94 1px solid;
  background-color: #6eff94;
  color: white;
}
</style>