<template>
  <body>
    <Toast />
    <div class="groupCadastros">
      <GroupbtnAdmin></GroupbtnAdmin>
    </div>
    <main>
      <div class="card col-sm-6" id="main_box">
        <div class="card-body">
          <p>Cadastrar Clientes</p>
          <form @submit.prevent="submit()">
            <div class="row" v-if="local_country == 'pt-BR'">
              <p style="font-size:1rem">* Campos Obrigatórios</p>

              <div class="col-md-6">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="* Nome Fantasia"
                    v-model="nome_fantasia"
                    aria-describedby="addon-wrapping"
                    autocomplete="off"
                    required
                    id="floatingInputGrid"
                  />
                  <label for="floatingInputGrid">* Nome Fantasia</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="* Razão Social"
                    v-model="razao_social"
                    aria-describedby="addon-wrapping"
                    autocomplete="off"
                    required
                    id="floatingInputGrid"
                  />
                  <label for="floatingInputGrid">* Razão Social</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <InputMask
                    mask="99.999.999/9999-99"
                    v-model="cnpj"
                    placeholder="* CNPJ"
                    class="form-control size"
                    aria-describedby="addon-wrapping"
                    autocomplete="off"
                    required
                    id="floatingInputGrid"
                  />
                  <label for="floatingInputGrid">* CNPJ</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="CEP"
                    v-model="cep"
                    autocomplete="off"
                    aria-describedby="addon-wrapping"
                    id="floatingInputGrid"
                  />
                  <label for="floatingInputGrid">CEP</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Endereço"
                    v-model="endereco"
                    autocomplete="off"
                    aria-describedby="addon-wrapping"
                    id="floatingInputGrid"
                  />
                  <label for="floatingInputGrid">Endereço</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <InputMask
                    mask="9?9999999"
                    v-model="numero"
                    placeholder="Número"
                    autocomplete="off"
                    class="form-control size"
                    id="floatingInputGrid"
                  />
                  <label for="floatingInputGrid">Número</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Bairro"
                    v-model="bairro"
                    aria-describedby="addon-wrapping"
                    id="floatingInputGrid"
                    autocomplete="off"
                  />
                  <label for="floatingInputGrid">Bairro</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Cidade"
                    autocomplete="off"
                    v-model="cidade"
                    aria-describedby="addon-wrapping"
                    id="floatingInputGrid"
                  />
                  <label for="floatingInputGrid">Cidade</label>
                </div>
              </div>
              <div class="col-sm-6 form-floating">
                <Multiselect
                  :columns="{ container: 12, label: 4, wrapper: 6 }"
                  v-model="estado"
                  placeholder="Selecione"
                  class="multiselect-custom form-control"
                  :options="states"
                  id="floatingInputGrid"
                />
                <label for="floatingInputGrid" style="margin-left: 15px;font-size:12px">Estado</label>
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Contato"
                    v-model="contato"
                    autocomplete="off"
                    aria-describedby="addon-wrapping"
                    id="floatingInputGrid"
                  />
                  <label for="floatingInputGrid">Contato</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <InputMask
                    mask="(99) 99999999?9"
                    v-model="telefone"
                    placeholder="Telefone"
                    autocomplete="off"
                    class="form-control size"
                    id="floatingInputGrid"
                  />
                  <label for="floatingInputGrid">Telefone</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Complemento"
                    v-model="complemento"
                    autocomplete="off"
                    aria-describedby="addon-wrapping"
                    id="floatingInputGrid"
                  />
                  <label for="floatingInputGrid">Complemento</label>
                </div>
              </div>
              <div class="col-sm-12">
              <div class="form-floating">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Observações"
                  v-model="observacoes"
                  autocomplete="off"
                  aria-describedby="addon-wrapping"
                  id="floatingInputGrid"
                />
                <label for="floatingInputGrid">Observações</label>
              </div>
            </div>
            </div>

            <div class="row" v-else>
              <p style="font-size:1rem">* Campos Obrigatórios</p>

              <div class="col-md-6">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="* Nome Fantasia"
                    v-model="nome_fantasia"
                    autocomplete="off"
                    aria-describedby="addon-wrapping"
                    required
                    id="floatingInputGrid"
                  />
                  <label for="floatingInputGrid">Nome Fantasia *</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    autocomplete="off"
                    placeholder="* Razão Social"
                    v-model="razao_social"
                    aria-describedby="addon-wrapping"
                    required
                    id="floatingInputGrid"
                  />
                  <label for="floatingInputGrid"> Razão Social *</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <InputMask
                    mask="99.999.999/9999-99"
                    v-model="cnpj"
                    placeholder="* CNPJ"
                    class="form-control size"
                    aria-describedby="addon-wrapping"
                    autocomplete="off"
                    required
                    id="floatingInputGrid"
                  />
                  <label for="floatingInputGrid">CNPJ *</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Código Postal"
                    v-model="cep"
                    autocomplete="off"
                    aria-describedby="addon-wrapping"
                    id="floatingInputGrid"
                  />
                  <label for="floatingInputGrid">Código Postal</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Morada"
                    v-model="endereco"
                    autocomplete="off"
                    aria-describedby="addon-wrapping"
                    id="floatingInputGrid"
                  />
                  <label for="floatingInputGrid">Morada</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <InputMask
                    mask="9?9999999"
                    v-model="numero"
                    autocomplete="off"
                    placeholder="Número"
                    class="form-control size"
                    id="floatingInputGrid"
                  />
                  <label for="floatingInputGrid">Número</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Concelho"
                    v-model="bairro"
                    aria-describedby="addon-wrapping"
                    autocomplete="off"
                    id="floatingInputGrid"
                  />
                  <label for="floatingInputGrid">Concelho</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Distrito"
                    autocomplete="off"
                    v-model="cidade"
                    aria-describedby="addon-wrapping"
                    id="floatingInputGrid"
                  />
                  <label for="floatingInputGrid">Distrito</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Contato"
                    v-model="contato"
                    aria-describedby="addon-wrapping"
                    id="floatingInputGrid"
                    autocomplete="off"
                  />
                  <label for="floatingInputGrid">Contato</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <InputMask
                    mask="(99) 99999999?9"
                    v-model="telefone"
                    placeholder="Telefone"
                    autocomplete="off"
                    class="form-control size"
                    id="floatingInputGrid"
                  />
                  <label for="floatingInputGrid">Telefone</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Complemento"
                    v-model="complemento"
                    aria-describedby="addon-wrapping"
                    autocomplete="off"
                    id="floatingInputGrid"
                  />
                  <label for="floatingInputGrid">Complemento</label>
                </div>
              </div>
              <div class="col-sm-6">
              <div class="form-floating">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Observações"
                  v-model="observacoes"
                  autocomplete="off"
                  aria-describedby="addon-wrapping"
                  id="floatingInputGrid"
                />
                <label for="floatingInputGrid">Observações</label>
              </div>
            </div>
            </div>

            <div
              class="form-check"
              style="display: flex; justify-content: center"
            >
              <input
                class="form-check-input"
                v-model="ativo"
                type="checkbox"
                value=""
                id="flexCheckDefault"
              />
              <label
                class="form-check-label"
                for="flexCheckDefault"
                style="margin-left: 10px"
              >
                Ativo
              </label>
            </div>
            <div class="d-grid gap-2 col-8 mx-auto">
              <button class="btn btn-login" type="submit">Cadastrar</button>
            </div>
          </form>
        </div>
      </div>
    </main>
  </body>
</template>

<script>
import { useLoading } from "vue-loading-overlay";
import Multiselect from "@vueform/multiselect";
import { filterCep, latlng } from "../services/cep.js";
import InputMask from "primevue/inputmask";
import { createClient } from "../services/crud";
import { store } from "../services/store"
import { useToast } from "primevue/usetoast";
import Toast from "primevue/toast";
import GroupbtnAdmin from "../components/groupbtnAdmin.vue";

const $loading = useLoading();
export default {
  name: "Login",
  components: {
    Multiselect,
    Toast,
    InputMask,
    GroupbtnAdmin
  },
  props: {
    msg: String,
  },
  data() {
    return {
      local_country: "",
      errorLogin: null,
      errors: [],
      data: {},
      razao_social: "",
      nome_fantasia: "",
      cnpj: "",
      cep: "",
      endereco: "",
      bairro: "",
      cidade: "",
      estado: "",
      numero: "",
      complemento: "",
      telefone: "",
      observacoes: "",
      contato: "",
      lat: "",
      long: "",
      ativo: false,
      successAlert: false,
      states: [
        { value: "AC", label: "Acre" },
        { value: "AL", label: "Alagoas" },
        { value: "AP", label: "Amapá" },
        { value: "AM", label: "Amazonas" },
        { value: "BA", label: "Bahia" },
        { value: "CE", label: "Ceará" },
        { value: "DF", label: "Distrito Federal" },
        { value: "ES", label: "Espirito Santo" },
        { value: "GO", label: "Goiás" },
        { value: "MA", label: "Maranhão" },
        { value: "MT", label: "Mato Grosso" },
        { value: "MS", label: "Mato Grosso do Sul" },
        { value: "MG", label: "Minas Gerais" },
        { value: "PA", label: "Pará" },
        { value: "PB", label: "Paraíba" },
        { value: "PR", label: "Paraná" },
        { value: "PE", label: "Pernambuco" },
        { value: "PI", label: "Piauí" },
        { value: "RJ", label: "Rio de Janeiro" },
        { value: "RN", label: "Rio Grande do Norte" },
        { value: "RS", label: "Rio Grande do Sul" },
        { value: "RO", label: "Rondônia" },
        { value: "RR", label: "Roraima" },
        { value: "SC", label: "Santa Catarina" },
        { value: "SP", label: "São Paulo" },
        { value: "SE", label: "Sergipe" },
        { value: "TO", label: "Tocantins" },
      ],
    };
  },
  methods: {
    submit: async function () {
      const loader = $loading.show({});
      let dataForm = {
        corporate_name: this.razao_social,
        cnpj: this.cnpj,
        address: this.endereco,
        number: this.numero,
        complement: this.complemento,
        zip_code: this.cep,
        neighborhood: this.bairro,
        city: this.cidade,
        state: this.estado,
        contact: this.contato,
        telephone: this.telefone,
        comments: this.observacoes,
        client_id_master: null,
        active: this.ativo,
        trading_name: this.nome_fantasia,
      };

      if (this.lat && this.long) {
        dataForm.lat= this.lat;
        dataForm.long= this.long;
      }

      if(!this.nome_fantasia || !this.nome_fantasia.trim() || !this.razao_social || !this.razao_social.trim() || !this.cnpj){
        this.showError()
      } else {
        await createClient(dataForm)
          .then((response) => {
            if (response.status >= 200 && response.status <= 299) {
              this.showsuccess()
              this.nome_fantasia = "";
              this.razao_social = "";
              this.cnpj = "";
              this.endereco = "";
              this.numero = "";
              this.complemento = "";
              this.cep = "";
              this.bairro = "";
              this.cidade = "";
              this.estado = "";
              this.contato = "";
              this.telefone = "";
              this.observacoes = "";
              this.ativo = false, 
              this.lat= "", 
              this.long= ""
            }
          })
          .catch((err) =>           
            this.showErrorServer(err)
          );
      }
      loader.hide();

      return true;
    },
    success() {
      this.successAlert = !this.successAlert;
    },
    getLatLng() {
      latlng(this.cep).then((resp) => {
        this.lat = resp.data.results[0].geometry.location.lat;
        this.long = resp.data.results[0].geometry.location.lng;
      });
    },
  },
  watch: {
    cep: function (val) {
      if (val.length > 7) {
        filterCep(val).then((resp) => {
          this.endereco = resp.data.logradouro;
          this.cidade = resp.data.localidade;
          this.estado = resp.data.uf;
          this.bairro = resp.data.bairro;
          this.cep = resp.data.cep;
        });
        this.getLatLng();
      }
    },
  },
  mounted () {
    this.local_country = store.local_country;
  },
  setup(){
    const toast = useToast();
    const showError = () => {
      toast.add({severity:'error', summary: 'Error', detail:'Campos Obrigatórios não foram preenchidos!', life: 4000});
    }
    const showErrorServer = () => {
      toast.add({severity:'error', summary: 'Error', detail:" Erro ao acessar o servidor!", life: 4000});
    }
    const showsuccess = () => {
      toast.add({severity:'success', detail:" Cliente Cadastrado com sucesso! ", life: 4000});
    }
    
    return {showError,showErrorServer,showsuccess}
  }
};
</script>
<style scoped>
p{
  font-size: 0.7rem;
}
body {
  background-size: cover;
  height: 100vh;
  margin-top: -1rem;
}
.logo_stattus {
  margin: 3rem;
  width: 25%;
  height: 12vh;
}
.img_persona {
  height: 9rem;
  position: relative;
  margin-top: -6rem;
}
main {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.card-body {
  display: flex;
  flex: 1 1 auto;
  padding: 1rem 1rem;
  flex-direction: column;
  align-items: center;
}
.btn-login {
  border-color: #6EFF94;
  background-color: #6EFF94;
  color: black;
  line-height: 2rem;
  font-size: 1.1rem;
  margin: 1rem;
  width: 8rem;
  font-weight: 450;
  place-self: center;
}
.form-floating {
  margin: 1rem 0rem;
}

.form-floating > .form-control,
.form-floating> .form-select {
  background-color: #e8f0fe;
  color: black;
  line-height: 3rem;
  margin: 0rem 0.2rem;
}
.input-group > .form-control,
.input-group > .form-select {
  background-color: #e8f0fe;
  color: black;
  line-height: 3rem;
  margin: 0rem 0.2rem;
}
.input-group {
  margin: 1rem 0rem;
}
input::placeholder {
  color: black;
}
.input-group-text {
  background-color: #37393c;
  padding: 0.375rem 1.2rem;
}
.card {
  border-radius: 2.25rem;
  padding: 1rem;
  margin-bottom: 3rem;
  margin-top: 2rem;
}
.groupCadastros {
  text-align-last: center;
  padding-top: 3rem;
}
.dropdown-item {
  padding: 0rem 0rem;
}
p {
  font-size: 1.5rem;
}
.btn-outline-success {
  border-color: #6EFF94;
  color: black;
  background-color: white;
}
.btn-outline-success:hover {
  border-color: #6EFF94;
  background-color: #6EFF94;
  color: black;
}
.btn-check:checked + .btn-outline-success {
  background-color: #6EFF94;
  border-color: #6EFF94;
  color: black;
}
.multiselect {
  height: 3.63rem;
  margin: 1rem 0rem;
  background: #e8f0fe;
}
.multiselect-placeholder {
  color: black !important;
}
.size {
  max-height: 62px;
  border-radius: 0.35rem;
}
.btn-router {
  line-height: 2rem;
  width: 100%;
}
@media screen and (max-width: 500px) {
  .btn-group {
    display: block;
  }
  .head-btn {
    display: block;
  }
}
@media screen and (min-width: 501px) {
  .head-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
@media only screen and (min-width: 575px) and (max-width: 875px) {
  #main_box {
    width: 52%;
  }
}
</style>
<style lang="scss" scoped>
::v-deep(.multiselect-custom) {
  .multiselect-placeholder {
    color: black;
  }
}
</style>