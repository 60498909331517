<template>
<body>
  <img class="logo_stattus" src='../assets/LogoStattus4Verde.png'>
  <form @submit.prevent="submit">
    <div class="card col-sm-4" id="main_box">
      <div class="card-body">
        <img class="img_persona" src='../assets/personaLogin.png'>

        <div class="input-group flex-nowrap">
          <span class="input-group-text" id="addon-wrapping"><div class="gg-user"></div></span>
          <input type="text" class="form-control" placeholder="Email" required v-model="this.email" aria-label="Email" aria-describedby="addon-wrapping">
        </div>
        <div class="input-group flex-nowrap">
          <span class="input-group-text" id="addon-wrapping"><div class="gg-lock"></div></span>
          <input type="password" class="form-control" placeholder="Password" v-model="this.password" aria-label="Password" required aria-describedby="addon-wrapping">
        </div>
        <div class="bottom-options">
          <!-- <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
            <label class="form-check-label" for="flexCheckDefault">
              Manter Conectado
            </label>
          </div> -->
          <router-link to="/redefinirSenha"><u>Esqueci minha senha</u></router-link>
        </div>
        <div class="d-grid gap-2 col-8 mx-auto">
          <input class="btn btn-login submit" type="submit" value="Login">
        </div>
        <div id="alertErrorLogin" v-show="errorLogin" class="alert alert-warning alert-dismissible fade show" role="alert">
          <strong>Acesso Negado</strong>
          <button @click="close" type="button" class="btn-close" aria-label="Close"></button>
        </div>
      </div>
    </div>
  </form>
</body>
</template>

<script>
import { store } from '../services/store.js'
import {useLoading} from 'vue-loading-overlay'
import { login } from "../services/login.js";
const $loading = useLoading()
export default {
  name: 'Login',
  data() {
    return {
      email: '',
      password: '',
      errorLogin: false,
    }
  },
  props: {
    msg: String
  },
  methods: {
    close(){
      this.errorLogin = !this.errorLogin
    },
    async submit () {
      const loader = $loading.show({});

      if (!this.email || !this.password){
        return
      } else {
        let _data = {};
        _data.email= this.email
        _data.password = this.password

        await login(_data)
          .then((response) => {
            this.save(response);
            this.success(response);
          })
          .catch(() => this.errorLogin = !this.errorLogin)
          loader.hide()
      }
    },
    save(data) {
      const userData = {
        client_id: data.data.client_id,
        token: data.data.token,
        user_id: data.data.user_id,
        name: data.data.name,
        type_user: data.data.type_user,
        trading_name: data.data.client.trading_name
      }
      localStorage.setItem('userData', JSON.stringify(userData));
    },
    success (response) {
      store.client_id = response.data.client_id
      store.token = response.data.token
      store.user_id = response.data.user_id
      store.name = response.data.name
      store.type_user = response.data.type_user
      store.trading_name = response.data.client.trading_name
      if(store.type_user == 3) {
        this.$router.replace('/listagem/ativacao')
      } else {
        this.$router.replace('/dashboard')
      }
    },
    error () {
      this.errorLogin = !this.errorLogin
      $loading.hide({});

    },
  },
}
</script>

<style scoped>
body{
  height: 110vh;
  background-image: url('../assets/inteligentes.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}
.logo_stattus{
  margin: 2rem 2rem 3.5rem 2rem;
  width: 27rem;
  height: 6.5rem;
}
.img_persona{
  height: 9rem;
  position: relative;
  margin-top: -6rem;
}
form{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.card-body {
  display: flex;
  flex: 1 1 auto;
  padding: 1rem 1rem;
  flex-direction: column;
  align-items: center;
}
@import url('https://unpkg.com/css.gg@2.0.0/icons/css/user.css');
.gg-user {
  display: block;
  transform: scale(var(--ggs,2));
  box-sizing: border-box;
  width: 13px;
  height: 20px;
  color: white;
}
@import url('https://unpkg.com/css.gg@2.0.0/icons/css/lock.css');
.gg-lock {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs,1.5));
  width: 12px;
  height: 11px;
  border: 2px solid;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom: transparent;
  margin-top: -12px;
  color: white;
}
.bottom-options{
  display: flex;
  margin: 0rem;
  font-size: 66.5%;
}
.btn-login{
  border-color: #6eff94;
  background-color: #6eff94;
  color: black;
  line-height: 2rem;
  font-size: 1.5rem;
  margin:1rem
}
.input-group>.form-control, .input-group>.form-select{
  background-color: #41464b96;
  color: white;
  line-height: 3rem
}
.input-group{
  margin: .5rem 1rem
}
input::placeholder {
  color: #fff;
}
.input-group-text{
  background-color: #37393c;
  padding: 0.375rem 1.2rem;
}
.card{
  border-radius: 2.25rem;
  padding: 1rem;
  margin-bottom: 3rem;
}
.form-check-input{
  background-color: #41464b96;
}
.form-check-input:checked {
  background-color: #6EFF94;
  border-color: #6EFF94;
}
.form-check{
  margin-right: 9.5rem
}
a {
  color: #000000;
  text-decoration: underline;
}
@media only screen and (max-width: 599px) {
  body{
    height: 110vh;
    text-align: center;
    background-image: url(/img/inteligentes.bb8c352c.jpg);
    background-repeat: no-repeat;
    background-size: cover;
  }

  .logo_stattus{
    margin: 2rem 0.5rem 3.5rem 0.5rem;
    width: 95%;
    height: 6.5rem;
  }
}
@media only screen and (min-width: 575px) and (max-width: 875px) { 
  #main_box{
    width: 52%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 865px) { 
  .logo_stattus{
    margin: 2rem 2rem 4.5rem 1rem;
    width: 20rem;
    height: 4.5rem;
  }
}
@media only screen and (min-width: 480px) and (max-width: 599px) { 
  .logo_stattus{
    margin: 2rem 0.5rem 3.5rem 0.5rem;
    width: 80%;
    height: 6.5rem;
  }
}
@media only screen and (min-height: 730px) {
  .logo_stattus{
    margin: 2rem 2rem 8.5rem 2rem;
  }
}
</style>
