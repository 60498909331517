<template>
<Toast />
  <div class="alternative">
    <div class="btnSel" v-if="isSuper">
      <router-link
        :class="{
          'btn me-2 btn-filtrar': $route.path === '/',
        }"
        class="btn me-2 btn-filtrar"
        to="/listagem/clientes"
      >
        <div class="marginDropdownOptions"></div>
        Admin
      </router-link>
    </div>
    <div class="btnSel" v-else>
      <router-link
        :class="{
          'btn btn-filtrar me-2 ': $route.path === '/',
        }"
        class="btn btn-filtrar me-2"
        to="/listagem/comissionamento"
      >
        <div class="marginDropdownOptions"></div>
        Admin
      </router-link>
    </div>
    <div class="btnSel">
      <router-link
        :class="{
          'btn btn-filtrar me-2 ': $route.path === '/',
        }"
        class="btn btn-filtrar me-2"
        to="/alterarSenha"
      >
        <div class="marginDropdownOptions"></div>
        Alterar Senha
      </router-link>
      <router-link
        :class="{
          'btn btn-filtrar me-2 ': $route.path === '/',
        }"
        class="btn btn-filtrar me-2"
        to="/parametrosConfig"
      >
        <div class="marginDropdownOptions"></div>
        Parâmetros de Configuração
      </router-link>
    </div>
    <div class="btnSel">
      <router-link
        @click="zerarToken"
        :class="{
          'btn btn-filtrar me-2 ': $route.path === '/',
        }"
        class="btn btn-filtrar me-2"
        to="/"
      >
        <div class="marginDropdownOptions"></div>
        Sair
      </router-link>
    </div>
  </div>
  <div class="allScreen">
    <div class="col-sm-2 pontosInstall">
      <div class="flex justify-center items-center">
        <div class="textDate2">Pontos de Instalação</div>
      </div>
      <v-date-picker
        v-model="range"
        :model-config="modelConfig"
        mode="date"
        is24hr
        is-range
        color="green"
      >
        <template v-slot="{ inputValue, inputEvents }">
          <div class="flex justify-center items-center">
            <div class="textDate">Data:</div>
            <input
              :value="inputValue.start + ' - ' + inputValue.end"
              v-on="inputEvents.start"
              class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300 input-data"/>
            <div style="display: none">
              <div class="textDate">Até:</div>
              <input
                :value="inputValue.end"
                v-on="inputEvents.end"
                class="
                  border
                  px-2
                  py-1
                  w-32
                  rounded
                  focus:outline-none focus:border-indigo-300
                "
              />
            </div>
          </div>
        </template>
      </v-date-picker>
      <div>
        <button
          class="btn btn-filtrar submit"
          type="button"
          @click="filterMap()"
        >
          Filtrar
        </button>
      </div>
      <div>
        <div class="col-sm-12">
          <div>
            <input type="radio" name="aaa" value="name" v-model="radioFilter" @click="cleanFilter()">
            <label for="" style="margin-right: 20px;">Nome</label>
            <input type="radio" name="aaa" value="tags"  v-model="radioFilter">
            <label for="">Tag</label>
            <div v-if="radioFilter === 'tags'" style="display: flex;flex-wrap: wrap;justify-content:start;padding: 15px">
              <div v-for="f_tag in listTags" :key="f_tag.id_name">
                <span v-if="f_tag.selected" 
                      class="badge rounded-pill" 
                      :style="f_tag.color != 'yellow' && f_tag.color != 'aqua' && f_tag.color != 'pink'? `color: white;background-color: ${f_tag.color}; border: 1px solid;font-size: 14px;margin-bottom: 5px;`: `color: black;background-color: ${f_tag.color};font-size: 14px;margin-bottom: 5px; cursor:pointer;`"
                      @click="selectMonomer(f_tag)"
                      > {{f_tag.name}}</span> &nbsp;

                <span v-if="!f_tag.selected"
                      class="badge rounded-pill" 
                      :style="`color: ${f_tag.color};border-color: ${f_tag.color}; border: 1px solid;font-size: 14px;margin-bottom: 5px; cursor:pointer;`"
                      @click="selectMonomer(f_tag)"
                      > {{f_tag.name}}</span> &nbsp;
              </div>
            </div>
          </div>
          <Listbox
            v-model="this.selectedCountries"
            class="listBox"
            :options="products"
            :optionLabel="radioFilter"
            emptyMessage="Sem Dados"
            emptyFilterMessage="Nenhum dado encontrado"
            style="width: 100%"
            :filter="radioFilter == 'tags' ? false : true"
            ref="filter"
            :optionDisabled="
              (option) => option.name === this.selectedCountries.name
            "
          >
            <template #option="slotProps" >
                 <div class="country-item">
                <div class="numPontoInstal">
                  <div class="fontListPontoInstall" id="my-listBox">
                    {{ slotProps.option.name }}
                  </div>
                </div>
                <div class="sensoresPI">
                  <div class="col-sm-12" style="display: flex">
                    <div class="col-sm-4">
                      <img
                        class="imgSize"
                        v-if="
                          slotProps.option.devices2[0].type == 'flow' &&
                          slotProps.option.devices2[0].status == 'ATIVADO' &&
                          slotProps.option.devices2[0].alarms != false
                        "
                        src="../assets/flowOff.png"
                        width="50"
                        height="55"
                      />
                      <img
                        class="imgSize"
                        v-else-if="
                          slotProps.option.devices2[0].type == 'flow' &&
                          slotProps.option.devices2[0].status == 'ATIVADO' &&
                          slotProps.option.devices2[0].alarms == false
                        "
                        src="../assets/flowOff.png"
                        width="50"
                        height="55"
                      />
                      <img
                        v-else-if="
                          slotProps.option.devices2[0].type == 'flow' &&
                          slotProps.option.devices2[0].status == 'DESATIVADO'
                        "
                        src="../assets/void.png"
                        width="50"
                        height="55"
                        class="imgSize"
                      />
                    </div>
                    <div class="col-sm-4">
                      <img
                        class="imgSize"
                        v-if="
                          slotProps.option.devices2[1].type == 'noise' &&
                          slotProps.option.devices2[1].status == 'ATIVADO' &&
                          slotProps.option.devices2[1].alarms != false
                        "
                        src="../assets/noiseOff.png"
                        width="50"
                        height="55"
                      />
                      <img
                        v-else-if="
                          slotProps.option.devices2[1].type == 'noise' &&
                          slotProps.option.devices2[1].status == 'ATIVADO'&&
                          slotProps.option.devices2[1].alarms == false
                        "
                        src="../assets/noiseOff.png"
                        width="50"
                        height="55"
                        class="imgSize"
                      />
                      <img
                        v-else-if="
                          slotProps.option.devices2[1].type == 'noise' &&
                          slotProps.option.devices2[1].status == 'DESATIVADO'
                        "
                        src="../assets/void.png"
                        width="50"
                        height="55"
                        class="imgSize"
                      />
                    </div>
                    <div class="col-sm-4">
                      <img
                        class="imgSize"
                        v-if="
                          slotProps.option.devices2[2].type == 'pressure' &&
                          slotProps.option.devices2[2].status == 'ATIVADO' &&
                          slotProps.option.devices2[2].alarms != false
                        "
                        src="../assets/pressureOff.png"
                        width="50"
                        height="55"
                      />
                      <img
                        v-else-if="
                          slotProps.option.devices2[2].type == 'pressure' &&
                          slotProps.option.devices2[2].status == 'ATIVADO' &&
                          slotProps.option.devices2[2].alarms == false
                        "
                        src="../assets/pressureOff.png"
                        width="50"
                        height="55"
                        class="imgSize"
                      />
                      <img
                        v-else-if="
                          slotProps.option.devices2[2].type == 'pressure' &&
                          slotProps.option.devices2[2].status == 'DESATIVADO'
                        "
                        src="../assets/void.png"
                        width="50"
                        height="55"
                        class="imgSize"
                      />
                    </div>
                  </div>
                </div>
                    <div v-if="slotProps.option.tags_dados">
                      <!-- <span v-if="slotProps.option.tags_dados.length > 0" > </span> <br> -->
                      <div style="display: flex;flex-wrap: wrap;">
                        <div v-for="tag in slotProps.option.tags_dados" :key="tag.id">
                          <!-- <span class="badge rounded-pill" data-bs-toggle="tooltip" data-bs-placement="bottom" :title="tag.name" :style="tag.color != 'yellow' && tag.color != 'aqua' && tag.color != 'pink' ? `width: 45px;background-color: ${tag.color}; color: white; `:`width: 45px;background-color: ${tag.color}; color: black; `">{{tag.name.slice(0,4)}}.</span> &nbsp; -->
                          <span class="badge rounded-pill" data-bs-toggle="tooltip" data-bs-placement="bottom" :title="tag.name" :style="tag.color != 'yellow' && tag.color != 'aqua' && tag.color != 'pink' ? `background-color: ${tag.color}; color: white; `:`background-color: ${tag.color}; color: black; `">{{tag.name}}</span> &nbsp;
                        </div>
                      </div>
                    </div>
              </div>
            </template>
          </Listbox>
        </div>
      </div>
    </div>
    <div
      v-if="this.selectedCountries.name === ''"
      class="col-sm-10 main_header"
    >
      <div class="col-sm-9" style="padding-top: 1rem"></div>
      <div class="col-sm-12">
        <GMapMap
          class="mmm"
          ref="myMapRef"
          :center="center"
          :zoom="15"
          map-type-id="terrain"
          :options="options"
        >
        <!-- :icon="marker.devices[0].alarm_boundaries.upper_limit > 5 ? markerOptionsR : markerOptionsBk" -->
          <GMapMarker
            :key="marker.id"
            v-for="marker in places"
            :position="marker"
            @click="filterMap(marker)"
            :icon="marker.flagAlarm == true ? markerOptionsR : markerOptionsBk"
            :clickable="true"
          />
        </GMapMap>
      </div>
    </div>
    <!-- PONTO DE INSTALAÇÃO SELECIONADO -->
    <div v-else class="col-sm-10 main_header">
      <div class="col-sm-6" id="info-pi">
        <div v-if="this.selectedCountries.address != null || this.selectedCountries.address != ''">
          <b>Ponto {{ this.selectedCountries.id }}</b> &nbsp;
          <b>Endereço: </b>{{ this.selectedCountries.address }}
          <b>Nº </b>{{ this.selectedCountries.number }}
          <b>Cidade: </b> {{ this.selectedCountries.city }}
          {{ this.selectedCountries.state !== null ? '- '+this.selectedCountries.state : ""}}
        </div>
        <div v-else >
          <b>Ponto {{ this.selectedCountries.id }}</b> &nbsp;
          <b>Endereço: </b>Sem internet no comissionamento.
        </div>
        <div>
          <b>Código de Instalação:</b>
          {{ selectedCountries.name }}
          <button
            class="btn btn-detalhamento"
            label="Show"
            @click="openBasicNew()"
            type="button"
          >
            <u>Detalhamento</u>
          </button>
        </div>
      </div>
      <div class="col-sm-4 periodicidade">
        <div
          class="btn-group"
          role="group"
          aria-label="Basic radio toggle button group"
        >
          <input
            type="radio"
            class="btn-check"
            name="btnradioP"
            id="btnradio1"
            autocomplete="off"
            value="1"
            v-model="periodo"
            checked
          />
          <label
            v-if="graficoHora == 1"
            class="btn btn-outline-success"
            for="btnradio1"
            >Hora-Hora</label
          >

          <input
            type="radio"
            class="btn-check"
            name="btnradioP"
            id="btnradio2"
            autocomplete="off"
            value="2"
            v-model="periodo"
          />
          <label class="btn btn-outline-success" for="btnradio2">Dia</label>

          <input
            type="radio"
            class="btn-check"
            name="btnradioP"
            id="btnradio3"
            autocomplete="off"
            value="3"
            v-model="periodo"
          />
          <label class="btn btn-outline-success" for="btnradio3">Semana</label>
        </div>
      </div>
      <div class="col-sm-12 mapaGrafico">
        <div class="col-sm-6 mapaDisplay">
          <GMapMap
            class="mmm2"
            ref="myMapRef"
            :center="{
              lat: this.selectedCountries.lat,
              lng: this.selectedCountries.long,
            }"
            :zoom="16"
            map-type-id="terrain"
            :options="options"
          >
          <!-- :icon="markerOptionsBk" -->
          <!-- :icon="marker.devices[0].type == 'flow' && flagAlarm == true ? markerOptionsR : markerOptionsBk" -->
            <GMapMarker
              v-for="marker in places"
              :key="marker.id"
              :position="marker"
              @click="filterMap(marker)"
              :icon="marker.flagAlarm == true ? markerOptionsR : markerOptionsBk"
              :animation="marker.lat == this.selectedCountries.lat? 1 : 0"
              :clickable="marker.lat !== this.selectedCountries.lat? true: false"
            />
          </GMapMap>
          <!-- Div com o Heatmap -->
          <div class="tabs">
            <TabView  :lazy="true" v-model:activeIndex="tabIndex">
              <TabPanel header="Conectividade">
                <div>
                  <CalHeatMaps
                  :data-inicial="this.range.start"
                  :dataF="this.dataF"
                  :dataN="this.dataN"
                  :dataP="this.dataP"
                  :devices="this.selectedCountries.devices"
                  :tabX="tabName"
                />
                </div>
              </TabPanel>
              <TabPanel  header="Log de Telemetria" >
                <LogTel 
                  :devices="this.selectedCountries.devices"
                />
              </TabPanel>
              <TabPanel header="Aviso" v-if="tabIndex == 3">
                <div style="font-size: 20px; text-align: center;">
                  Não há aviso de alarme disparado.
                </div>
              </TabPanel>
            </TabView>
          </div>
        </div>
        <div class="col-sm-6 graficoDisplay">
          <ScrollPanel class="custom">
            <charts
              :data-inicial="this.range.start"
              :data-final="this.range.end"
              :periodo="this.periodo"
              :devices="this.selectedCountries.devices"
              :upGrafico="this.graficEvent"
            ></charts>
          </ScrollPanel>
        </div>
      </div>

      <Toast />
      <Toast position="top-left" group="tl" />
      <CompDetalhamento
        :show="displayBasic"
        :devices="selectedCountries"
        :arrTags="listTags"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import "v-calendar/dist/style.css";
import Listbox from "primevue/listbox";
import { store } from "../services/store.js";
import {
  filtroPontosInstall,
  pins,
  commmunication,  
} from "../services/dashboard.js";
import ScrollPanel from "primevue/scrollpanel";
import moment from "moment-timezone";
import Charts from "../components/Charts.vue";
import CalHeatMaps from "../components/CalHeatMaps.vue";
import LogTel from "../components/LogTel.vue";
moment.locale("en-us");
import { useLoading } from "vue-loading-overlay";
const $loading = useLoading();
import { useToast } from "primevue/usetoast";
import Toast from "primevue/toast";
import CompDetalhamento from "../components/CompDetalhamento.vue";

import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import { listTag } from "../services/crud.js";

import _ from 'lodash';

const mapMarkerBk = require("../assets/pinBk.png");
const mapMarkerR = require("../assets/pinR.png");

export default defineComponent({
  name: "Dashboard",
  components: {
    Listbox,
    ScrollPanel,
    Toast,
    Charts,
    TabView,
    TabPanel,
    CalHeatMaps,
    LogTel,
    CompDetalhamento
  },
  data() {
    return {
      countTagCall: 0,
      checkMulti: false,
      setMapVoid:[],
      auxTags:[],
      tempMeter:null,
      slider_flow: {
        orientation: "vertical",
        direction: "rtl",
        tooltips: false,
        step: 0.5,
        min: -1,
        max: 20,
        lazy: false,
      },
      listTags: [],
      radioFilter: "name",
      slider_noise: {
        orientation: "vertical",
        tooltips: false,
        direction: "rtl",
        min: 0,
        max: 100,
        lazy: false,
      },
      slider_pressure: {
        orientation: "vertical",
        tooltips: false,
        direction: "rtl",
        lazy: false,
        min: 0,
        max: 150,
      },
      isSuper: store.type_user == 1 ? true : false,
      displayBasic: false,
      markerOptionsBk: {
        url: mapMarkerBk,
        //size: {width: 60, height: 90, f: 'px', b: 'px',},
        scaledSize: { width: 30, height: 45, f: "px", b: "px" },
      },
      markerOptionsR: {
        url: mapMarkerR,
        //size: {width: 60, height: 90, f: 'px', b: 'px',},
        scaledSize: { width: 30, height: 45, f: "px", b: "px" },
      },
      center: { lat: -23.4775, lng: -47.4241 },
      range: {
        start: this.aMonthAgo(),
        end: new Date(),
      },
      periodo: "1",
      graficoHora: 1,
      products: null,
      productsB: null,
      listaDeClientes: [],
      empresa: null,
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD",
        timeAdjust: "12:00:00",
      },
      places: [],
      coordSelect: "",
      selectedCountries: {
        name: "",
        code: null,
        id: null,
        flow: null,
        noise: null,
        pressure: null,
      },
      rangeStart:{start:this.aMonthAgo(),end: new Date()},
      temp: null,
      placeholder: "Ver Como: ",
      flag: null,
      graficEvent: 0,
      dataF: null,
      dataN: null,
      dataP: null,
      tabIndex: 0,
      tabName: null,
      tabF: null,
      tabN: null,
      tabP: null,
      arrTemp: [],
      cloneArr: [],
      tagsPointInstall: null,
      newTagsUp:{},
      options: {
        styles: [
          {
            featureType: "poi.attraction",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi.business",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi.government",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi.medical",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi.park",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi.place_of_worship",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi.school",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi.sports_complex",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "transit.station",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
        ],
      },
    };
  },
  methods: {
    geolocate() {
      if (!navigator.geolocation) return;
      navigator.geolocation.getCurrentPosition((position) => {
        this.center.lat = position.coords.latitude;
        this.center.lng = position.coords.longitude;
      });
    },
    openBasicNew() {
      this.displayBasic = !this.displayBasic;
    },
    listaClientes(listClients) {
      this.listaDeClientes = listClients;
    },
    getNameClient() {
      if (this.listaDeClientes.length > 0) {
        let temp = this.listaDeClientes.filter((i) => {
          return i.value === this.empresa;
        });
        store.name_client = temp[0];
      }
    },
    getClient() {
      this.empresa = store.client_id;
      this.placeholder = store.trading_name;
    },
    getPins() {
      let res = null;
      if (this.isSuper && store.last_search.length > 0) {
        res = store.last_search;
      }else if (store.id === "") {
        res = store.client_id;
      } else {
        res = store.id;
      }
      const payload ={
        'client_id': res,
        'from': this.range.start,
        'to': this.range.end
      }
      const loader = $loading.show({});
      pins(payload)
        .then((response) => {
          let k = [];
          this.temp = response.data;
          for (let n of this.temp) {
            if(n.devices){
              for(let i of n.devices) {
                k.push({
                  lat: parseFloat(n.lat),
                  lng: parseFloat(n.long),
                  id: n.id,
                  client_id: n.client_id,
                  name: n.name,
                  address: n.address,
                  number: n.number,
                  city: n.city,
                  state: n.state,
                  devices: n.devices,
                  flagAlarm: i.alarms,
                });
              }
            }
          }
          this.places = k;
          this.setMapVoid = k;
          loader.hide();
         this.filterMap();
        })
        .catch((err) =>
          this.showErrorServer(err)
        );
    },
    centralize() {
      setTimeout(() => {
        if (this.$refs.myMapRef) {
          this.$refs.myMapRef.$mapPromise.then(() => {
            let bounds = new window.google.maps.LatLngBounds();
            Object.values(this.places).forEach((sample) =>
              bounds.extend({ lat: sample.lat, lng: sample.lng })
            );
            this.$refs.myMapRef.$mapPromise
              .then((map) => {
                map.panTo(bounds.getCenter());
                map.fitBounds(bounds);
              })
              .catch(function (e) {
                console.log("message", e);
              });
          });
        }
      }, 900);
    },
    verifyDay() {
      let antes = moment(this.range.start);
      let depois = moment(this.range.end);
      let dias = depois.diff(antes, "days");
      if (dias > 30) {
        this.graficoHora = 1;
        this.periodo = "1";
        alert('Atenção! Data inicial e final superior a 30 dias. Só é permitido intervalo igual ou menor a 30 dias.');
        this.range= {
        start: this.aMonthAgo(),
        end: new Date()
        }
      } else {
        this.graficoHora = 1;
        this.periodo = "1";
      }
    },
    async filterMap(data) {
      this.listTags.forEach((tag)=>{
        this.auxTags = [];
        tag.selected = false;
        return
      })
      this.products = [];
      this.selectedCountries.name = "";

      if(this.$refs.filter){
        this.$refs.filter.filterValue = "";
      }

      this.verifyDay();
      this.coordSelect = "";

      // Entrando direto pelo email Inicio
      if (this.isSuper && store.last_search.length > 0) {
        this.empresa = store.last_search;
      }else if (this.isSuper != 1) {
        this.empresa = store.client_id;
      }
      // Fim
      if (data === undefined) {
        const client_id = this.empresa;
        store.id = this.empresa;
        const payload ={
        'client_id': client_id,
        'from': this.range.start,
        'to': this.range.end
      }
        const loader = $loading.show({});
        this.getNameClient();
        await filtroPontosInstall(payload)
          .then((response) => {
            let list_points = [...response.data];
            this.arrTemp = [...list_points];
            this.cloneArr = _.cloneDeep(response.data);
            for (let a = 0; a < list_points.length; a++) {
              list_points[a].devices2 = [];
              list_points[a].devices2[0] = {};
              list_points[a].devices2[1] = {};
              list_points[a].devices2[2] = {};
              list_points[a].devices2[0].type = null;
              list_points[a].devices2[1].type = null;
              list_points[a].devices2[2].type = null;

              for (let i = 0; i < list_points[a].devices.length; i++) {
                if (list_points[a].devices[i].type === "flow") {
                  list_points[a].devices2[0] = list_points[a].devices[i];
                }

                if (list_points[a].devices[i].type === "noise") {
                  list_points[a].devices2[1] = list_points[a].devices[i];
                }

                if (list_points[a].devices[i].type === "pressure") {
                  list_points[a].devices2[2] = list_points[a].devices[i];
                }
              }
            }
            this.products = list_points;
            let k = [];
            this.places = [];
            if (this.products.length > 0) {
              for (let n of this.products) {
                if(n.devices){
                  for(let i of n.devices) {
                    k.push({
                    lat: parseFloat(n.lat),
                    lng: parseFloat(n.long),
                    id: n.id,
                    client_id: n.client_id,
                    name: n.name,
                    address: n.address,
                    number: n.number,
                    city: n.city,
                    state: n.state,
                    devices: n.devices,
                    tags: n.tags,
                    flagAlarm: i.alarms,
                    });
                  }
                }
                const loader = $loading.show({});
                setTimeout(() => {
                  loader.hide();
                }, 150);
                this.places = k;
                this.centralize();
              }
            } else {
              this.places = k;
            }
          })
          .catch(() => {
            if (this.empresa === null) {
              this.showErrorFiltro()
            } else {
              this.showErrorSearch()
            }
          });
          loader.hide();
          if(this.tempMeter) {
            this.$refs.filter.filterValue = this.tempMeter;
          }
          this.productsB = this.products;
        return this.products;
      }
      if (data.client_id) {
        try {
            this.selectedCountries.id = data.id;
            this.selectedCountries.lat = data.lat;
            this.selectedCountries.long = data.lng;
            this.selectedCountries.address = data.address;
            this.selectedCountries.number = data.number;
            this.selectedCountries.city = data.city;
            this.selectedCountries.state = data.state;
            this.selectedCountries.name = data.name;
            this.selectedCountries.devices = data.devices;
            this.selectedCountries.tags = data.tags;
        } catch (e) {
          this.showErrorServer(e)
        }
        this.getConfirm();
      }
    },
    getConfirm (){
      let list_points = this.cloneArr;
      for (let a = 0; a < list_points.length; a++) {
              list_points[a].devices2 = [];
              list_points[a].devices2[0] = {};
              list_points[a].devices2[1] = {};
              list_points[a].devices2[2] = {};
              list_points[a].devices2[0].type = null;
              list_points[a].devices2[1].type = null;
              list_points[a].devices2[2].type = null;

              for (let i = 0; i < list_points[a].devices.length; i++) {
                if (list_points[a].devices[i].type === "flow") {
                  list_points[a].devices2[0] = list_points[a].devices[i];
                }
                if (list_points[a].devices[i].type === "noise") {
                  list_points[a].devices2[1] = list_points[a].devices[i];
                }
                if (list_points[a].devices[i].type === "pressure") {
                  list_points[a].devices2[2] = list_points[a].devices[i];
                }
              }
            }
      this.getGeral();
      this.products = _.cloneDeep(this.cloneArr);
      this.productsB = this.products;
        return this.products;
    },
    aMonthAgo() {
      const dateStart = new Date();
      return dateStart.getTime() - 30 * 24 * 3600 * 1000;
    },
    zerarToken() {
      store.token = "";
    },
    async getGeral(){      
      let xx = {};
      this.dataF = null;
      this.dataN = null;
      this.dataP = null;
      this.tabF = true;
      this.tabN = true;
      this.tabP = true;
      this.rangeStart = null;
      
      if(this.selectedCountries.devices) {
        for(let i = 0; i < this.selectedCountries.devices.length; i++) {
        let dateRange = moment(this.range.start);
        let dateCommissioning = moment(this.selectedCountries.devices[i].date_commissioning);
        xx = {};
        
        let data ={
        "start_date": dateCommissioning.diff(dateRange, 'days') >= 0 ? this.selectedCountries.devices[i].date_commissioning : this.range.start,
        "final_date": this.range.end,
        "device_id": this.selectedCountries.devices[i].id
        };
        
      const allEvents = await commmunication(data);
      const aux = allEvents.data.data;
        
        if (aux) {
          Object.entries(aux).forEach(([k, v]) => {
          let aa = (Date.parse(k) / 1000)  + 10800;
          xx[aa] = v;
          });
        }
     
        if(this.selectedCountries.devices[i].type == "flow" && Object.keys(xx).length > 0) {
          this.dataF = xx;
          this.tabF = false;
          this.tabIndex = 0;      
        }
        else if(this.selectedCountries.devices[i].type == "noise" && Object.keys(xx).length > 0) {
          this.dataN = xx;
          this.tabN = false;
          this.tabIndex = 0;
        }
        else if(this.selectedCountries.devices[i].type == "pressure" && Object.keys(xx).length > 0) {
          this.dataP = xx;
          this.tabP = false;
          this.tabIndex = 0;
        }
      }   
      }  
    },
    getIndexTab(){
      if(this.tabIndex == 0){
        this.tabName = "almD";
      }
    },
    getTag(data) {
      this.$refs.filter.filterValue = data;      
    },
    newTags(data) {
      this.newTagsUp = data;
    },
    cleanFilter() {
      this.$refs.filter.filterValue = '';
    },
    upMap(data) {
      this.places = this.setMapVoid;
      setTimeout(() => {
        if (this.$refs.myMapRef) {
          this.$refs.myMapRef.$mapPromise.then(() => {
            let bounds = new window.google.maps.LatLngBounds();
            if(data.length > 0) {
              Object.values(data).forEach((sample) =>
                bounds.extend({ lat: sample.lat, lng: sample.long })
              );
            }else{
              this.places = []
              Object.values(this.setMapVoid).forEach((sample) =>
                bounds.extend({ lat: sample.lat, lng: sample.lng })
              );
            }
            this.$refs.myMapRef.$mapPromise
              .then((map) => {
                map.panTo(bounds.getCenter());
                map.fitBounds(bounds);
              })
              .catch(function (e) {
                console.log("message", e);
              });
          });
        }
      }, 900);
    },
    selectMonomer: async function(monomer) {
      let arrProd = []
      monomer.selected = !monomer.selected;
      if(monomer.selected == true) {
        this.auxTags.push(monomer.id_name)

        let res = this.auxTags
        
        this.products.map(function(a){
          if(a.tags_dados){
            let cont = 0;
            res.forEach(e => {
              if(a.tags.includes(e)){
               cont++;
              }
            });
            return (res.length == cont)? arrProd.push(a) : '';
          } 
        })
        this.products = arrProd;
        this.upMap(arrProd);
      }

      if(monomer.selected == false) {
        let temp = []
        this.auxTags.filter(item => {
          if(item != monomer.id_name){
            if(item.length > 0) {
              temp.push(item)
            }
          }          
        });
        
        this.auxTags = temp
        let resOut = temp

        this.productsB.map(function(a){
          if(a.tags_dados){
            let contOut = 0;
            resOut.forEach(e => {
              if(a.tags.includes(e)){
               contOut++;
              }
            });
           return (resOut.length == contOut)? arrProd.push(a) : '';
          } 
        })
        this.products = arrProd
        this.upMap(arrProd);

        if(this.auxTags.length == 0) {
          this.products = this.productsB;
          this.upMap(this.productsB);
          this.places = this.setMapVoid;
        }
      }
     
    },
    async listAllTags() {
      //Listagem Tag
      if(this.countTagCall == 0){
        this.countTagCall++;
        let aux = []
        let listaTags = []
        this.listTags = []
        const client_id = {"client_id": this.isSuper && this.empresa ? this.empresa : this.isSuper && !this.empresa ? store.last_search : store.client_id}
        await listTag(client_id)
        .then((response) => {
          aux = response.data    
          for (let i = 0; i < aux.length; i++) {
            listaTags = {
              name: aux[i].name,
              color: aux[i].color,
              id_name: aux[i].id_name,
              selected: false,
            }
            this.listTags.push(listaTags)
          }
        })
        if(!this.isSuper){
          this.countTagCall--;
        }
      }else{
        this.countTagCall--;
      }
    },
  },
  async mounted() {
    this.tabName = "almD";
    let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let timeZoneValue = moment().tz(timeZone).format('z')
    store.local = parseInt(timeZoneValue) *(-1);
    store.local_country = Intl.DateTimeFormat().resolvedOptions().locale;
    if(this.isSuper && store.last_search.length > 0) {
      this.getPins();
      this.listAllTags(); 
    }else if(this.isSuper == false) {
      this.getPins();
      this.listAllTags(); 
    }
  },

  created() {
    this.emitter.on('my-event-select-client', (data) => {
      if(data){
        store.last_search = data;
        this.checkMulti = true;
        setTimeout(() => this.checkMulti = false, 10); 
      }
    })
    this.emitter.on('my-event-add-tag', () => {
      this.getPins();
      this.listAllTags();
    }),
    this.emitter.on('my-event-remove-tag', () => {
      this.getPins();
      this.listAllTags();
    }),
    this.emitter.on('my-event-select-multi', (data) => {
      this.empresa = data;
    }),
    this.emitter.on('my-event-get-pins-func', () => {
      this.getPins();
    })
  },

  watch: {
    tabIndex: function () {
      this.getIndexTab();
    }, 
    selectedCountries: async function () {
      this.getGeral();
    },
    checkMulti: function () {
      this.getPins();
      this.listAllTags();
    },
  },

  setup() {

    const myMapRef = ref(null);

    const toast = useToast();
    const showSuccess = () => {
      toast.add({
        severity: "success",
        summary: "Informações do Ponto de Instalação salvas com sucesso!",
        life: 3000,
      });
    };
    const showErrorServer = () => {
      toast.add({severity:'error', summary: 'Error', detail:" Erro ao acessar o servidor!", life: 4000});
    }
    const showErrorAlarm = () => {
      toast.add({severity:'error', detail:" Erro ao acessar o servidor!", life: 4000});
    }
    const showErrorFiltro = () => {
      toast.add({severity:'error', summary: 'Error', detail:" Por favor, selecione um cliente e tente novamente.", life: 4000});
    }
    const showErrorSearch = () => {
      toast.add({severity:'error', summary: 'Error', detail:"Houve um erro na pesquisa. Por favor, tente novamente.", life: 4000});
    }
    const showErrorDevice = () => {
      toast.add({severity:'error', summary: 'Error', detail:"Nenhum tipo de dispositivo selecionado.", life: 4000});
    }
    const showErrorDeviceAlarm = () => {
       toast.add({severity:'error', detail:"Não há nenhum dispositivo que tenha disparado o alarme selecionado.", life: 4000});
    }
    const showErrorDeviceChoose = () => {
      toast.add({severity:'error', summary: 'Atenção !', detail:"Essa função suporta somente dispositivo do tipo Vazão.", life: 4000});
    }

    return {
      showSuccess,
      showErrorSearch,
      showErrorFiltro,
      showErrorServer,
      showErrorDevice,
      showErrorDeviceChoose,
      showErrorAlarm,
      showErrorDeviceAlarm,
      myMapRef,
    };
  },
});
</script>

<style src="@vueform/multiselect/themes/default.css">
.multiselect {
  width: 25% !important;
}
</style>
<style src="primevue/resources/primevue.min.css"></style>
<style src="primevue/resources/themes/saga-blue/theme.css"></style>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
* {
  font-family: "Roboto", sans-serif;
}
.gray{
  background: gray;
  color: white;
  margin-right: 0.5rem !important;
}
.brown{
  background: brown;
  color: white;
  margin-right: 0.5rem !important;
}
.pink{
  background: pink;
  color: white;
  margin-right: 0.5rem !important;
}
.purple{
  background: purple;
  color: white;
  margin-right: 0.5rem !important;
}
.orange{
  background: orange;
  color: white;
  margin-right: 0.5rem !important;
}
.aqua{
  background: aqua ;
  color: black;
  margin-right: 0.5rem !important;
}
.red{
  background: red;
  color: white;
  margin-right: 0.5rem !important;
}.green{
  background: green;
  color: white;
  margin-right: 0.5rem !important;
}.blue{
  background: blue;
  color: white;
  margin-right: 0.5rem !important;
}.yellow{
  background: yellow;
  color: black;
  margin-right: 0.5rem !important;
}
.hideInput{
  pointer-events: none;
}
.btn-filtrar {
  border-color: #6eff94;
  background-color: #6eff94;
  color: black;
  margin: 1rem 0rem;
  width: 89%;
  font-weight: 400;
  height: 42px;
}
#btnAssociarTag:hover{
  border-color: #6eff94;
  background-color: #6eff94;
  color: black;
  margin: 1rem 0rem;
  width: 89%;
  font-weight: 400;
  height: 42px;
}
.btn-outline-success {
  border-color: #6eff94;
  color: black;
}
.btn-outline-success:hover {
  border-color: #6eff94;
  background-color: #6eff94;
  color: black;
}
.btn-check:checked + .btn-outline-success {
  background-color: #6eff94;
  border-color: #6eff94;
  color: black;
}
.px-2 {
  width: 95%;
}
.textDate {
  margin: 0.7rem 0rem 0.5rem 0.5rem;
  text-align: start;
  margin-left: 17px;
  margin-bottom: 0px;
}
.textDate2 {
  margin: -1.2rem 0rem 0.5rem 0rem;
  text-align: center;
  color: #4d7ec5e6;
  font-weight: 500;
  font-size: 20px;
}
.input-data {
  width: 89%;
  font-size: 16px;
}
.btn-detalhamento {
  border-color: #6EFF94;
  background-color: #6EFF94;
  color: black;
  margin: 0rem 0rem 0rem 1rem;
  line-height: 1;
  font-size: 14px;
}
.main_header {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  height: -webkit-fit-content;
  height: fit-content;
  margin: -1rem 0rem 0rem 0rem;
  background-color: #dee2e6;
  padding-top: 1rem;
  padding-left: 1rem;
}
.detalhamento {
  display: flex;
  margin-top: 1rem;
  justify-content: space-around;
}
p,
h3 {
  margin-bottom: 0px;
  color: black;
}
.sliders {
  align-self: center;
  width: 35%;
  display: inline-flex;
}
.imgSensor {
  display: flex;
  margin-bottom: 0.5rem;
  justify-content: center;
  align-items: center;
}
.mmm {
  width: 98%;
  height: 82vh;
  border-radius: 3%;
  overflow: hidden;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.mmm2 {
  width: 95%;
  height: 47vh;
  /* height: 85vh;  */
  border-radius: 3%;
  overflow: hidden;
  margin-left: 2.5%;
  border: 4px solid white;
}
.mapGrafico {
  display: flex;
  margin-top: 2.5rem;
}
label {
  margin-left: 0.3rem;
}
.p-listbox {
  background: #ffffff;
  color: #495057;
  border: 0px solid #ced4da;
  border-radius: 3px;
}
.pontosInstall {
  margin-top: -1rem;
  height: 93vh;
  padding-top: 2rem;
  background-color: white;
  text-align: center;
}
.sensoresPI {
  display: flex;
  justify-content: space-evenly;
}
.slider-vertical {
  height: 8rem;
  --slider-bg: RED;
}
.numPontoInstal {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
.fontListPontoInstall {
  color: #4d7ec5e6;
  font-weight: bold;
}
.periodicidade {
  padding-top: 1rem;
  padding-left: 2rem;
  text-align: end;
  width: 27%;
  padding: 1rem 1.5rem 0rem 2rem;
}
.mapaGrafico {
  display: flex;
  margin-top: 0.5rem;
}
.custom {
  width: 100%;
  height: 79vh;
}
.infoDevices {
  display: flex;
  margin-top: 1rem;
  justify-content: space-around;
}
.tabs {
  width: 95%;
  margin-left: 2.5%;
  margin-top: 2.5%;
}
.imgSize {
  width: 60%;
  height: 100%;
}
.allScreen {
  display: flex;
  height: 91vh;
  flex-wrap: nowrap;
}
.VerComoList {
  display: none;
}
.listBox {
  width: 100%;
  height: 50vh;
}
.alternative {
  margin-top: 50px;
}
.btnSel {
  text-align: center;
  background: #fff;
}
.btnSel:active {
  background: #6EFF94;
}
.style-btnSel {
  width: 100%;
}
.btn-group {
  background-color: white;
}
.tabs{
  margin-top: 10px;
}
@media only screen and (min-height: 800px) {
  /* .mmm2{
    height: 57vh;
  } */
  .mmm{
    height: 88vh;
  }
  .pontosInstall {
    height: 95vh;
  }
  .custom{
    height: 86vh;
  }
}
@media only screen and (min-width: 581px) {
  .alternative {
    display: none;
  }
}
@media only screen and (max-width: 1370px) {
  .input-data {
    font-size: 14px;
  }
}
@media only screen and (max-width: 580px) {
  .main_header {
    width: 100%;
  }
  .pontosInstall {
    height: 104vh;
    width: 100%;
    text-align: -webkit-center;
  }
  .allScreen {
    display: none;
  }
  /* .allScreen{
    display: flex;
    flex-wrap: wrap;
  } */
  .VerComoMap {
    display: none;
  }
  .VerComoList {
    padding-top: 1rem;
    display: revert;
    width: 95%;
  }
  .imgSize {
    width: 100%;
    height: 100%;
    max-height: 125px;
  }
  .mapaDisplay {
    display: none;
  }
  .graficoDisplay {
    width: 100%;
  }
}
</style>
<style lang="scss" scoped>
::v-deep(.p-tabview) {
  .p-tabview-panels {
    border-bottom-right-radius: 3% 7%;
    border-bottom-left-radius: 3% 7%;
    padding: .5rem;
    height: 30vh;
    overflow-y: scroll;
  }
  ::-webkit-scrollbar {
    display: none;
  }
  @media only screen and (min-height: 800px) {
    .p-tabview-panels {
      border-bottom-right-radius: 3% 7%;
      border-bottom-left-radius: 3% 7%;
      padding: 1rem;
    }
  }
}
::v-deep(.p-tabview) {
  .p-tabview-nav {
    .p-highlight {
      .p-tabview-nav-link {
        background: #6EFF94;
        border-color: #6EFF94;
        color: #000;
        font-family: "Roboto", sans-serif;
        font-weight: 400;
      }
    }
  }
}

::v-deep(.p-tabview) {
  .p-tabview-nav {
    height: 38px;
    .p-tabview-nav-link {
      height: 38px;
      padding: 0.5rem;
      .p-tabview-title {
        font-weight: 400;
        font-size: 15px;
        color: black;
      }
    }
  }
  @media only screen and (min-height: 800px) {
    .p-tabview-nav {
    height: 65px;
    .p-tabview-nav-link {
      height: 65px;
      padding: 1rem;
      .p-tabview-title {
        font-weight: 400;
        font-size: 20px;
      }
    }
  }
  }
}
::v-deep(.custom) {
  .p-scrollpanel-bar {
    background-color: #6EFF94;
    border: 0 none;
  }
}
::v-deep(.multiselect-custom) {
  .multiselect-caret {
    margin-top: 0rem;
    z-index: 1;
  }
  .multiselect-clear {
    z-index: 1;
  }
}
::v-deep(.p-listbox) {
  .p-listbox-item {
    border-top: 1px solid black;
  }
  .p-disabled, .p-disabled * {
      pointer-events: auto;
  }
  .p-listbox-list-wrapper{
    max-height: 53vh;
  }
  .p-listbox-list{
    max-height: 53vh;
  }
  .p-listbox.p-focus{
    box-shadow: 0 0 0 0.2rem #fff;
  }
    
  @media only screen and (min-height: 800px) {
    .p-listbox-list-wrapper{
      max-height: 68vh;
    }
    .p-listbox-list{
      max-height: 68vh;
    }
    .p-listbox.p-focus{
    box-shadow: 0 0 0 0.2rem #fff;
    }
  }
}
@media only screen and (min-width: 1360px) {
  #info-pi{
    padding: 1rem 0rem 0rem 1.5rem;
    width:55%
  }
}
@media only screen and (max-width: 1360px) {
  #info-pi{
    padding: 1rem 0rem 0rem 0.9rem;
    width: 585px;
  }
  .periodicidade{
    width: 315px;
  }
}
@media only screen and (max-width: 1316px) {
  #info-pi{
    padding: 1rem 0rem 0rem 0.7rem;
    width: 565px;
  }
  .periodicidade{
    width: 272px;
    padding: 1rem 1rem 0rem 1rem
  }
}
::v-deep(.p-multiselect) {
 .p-multiselect-label-container{
  .p-multiselect-label{
    display: flex;
    flex-wrap: wrap;
  }
  }
}
</style>
<style src="@vueform/slider/themes/default.css"></style>