<template>
  <body>
    <Toast />
    <div class="groupCadastros">
      <GroupbtnAdmin></GroupbtnAdmin>
    </div>
    <main>
      <div class="card col-sm-7" id="main_box">
        <div class="card-body">
          <div>
            <p>Comissionamento</p>
          </div>
          <form @submit.prevent="submit()">
            <div class="row" v-if="local_country == 'pt-BR'">
          <p style="font-size:1rem">* Campos Obrigatórios</p>

              <div class="col-md-3">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="* Hidrômetro"
                    v-model="hidrometro"
                    autocomplete="off"
                    aria-describedby="addon-wrapping"
                    minlength="5"
                    required
                    id="floatingInputGrid"
                  />
                  <label for="floatingInputGrid">Hidrômetro *</label>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="* Display Inicial"
                    v-model="initial_display"
                    autocomplete="off"
                    aria-describedby="addon-wrapping"
                    minlength="1"
                    required
                    id="floatingInputGrid"
                  />
                  <label for="floatingInputGrid">Display Inicial *</label>
                </div>
              </div>
              <div class="col-sm-3 form-floating" style="flex: auto;">
                <Multiselect
                  :columns="{ container: 12, label: 4, wrapper: 6 }"
                  v-model="resolucao"
                  placeholder="Selecione"
                  class="multiselect-custom form-select"
                  v-tooltip.top="{value:'Equivalência de litros por pulso. Ex: 1 pulso equivale a (1 litro, 10 litros...)'}"
                  :options="resolution"
                  id="floatingInputGrid"
                />
                <label
                  for="floatingSelect"
                  style="margin-left: 15px; padding: 12px"
                  >Resolução</label
                >
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    autocomplete="off"
                    placeholder="Cep"
                    v-model="cep"
                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                    aria-describedby="addon-wrapping"
                    id="floatingInputGrid"
                  />
                  <label for="floatingInputGrid">Cep</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Endereço"
                    v-model="endereco"
                    autocomplete="off"
                    aria-describedby="addon-wrapping"
                    id="floatingInputGrid"
                  />
                  <label for="floatingInputGrid">Endereço</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    autocomplete="off"
                    placeholder="Bairro"
                    v-model="bairro"
                    aria-describedby="addon-wrapping"
                    id="floatingInputGrid"
                  />
                  <label for="floatingInputGrid">Bairro</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Número"
                    v-model="numero"
                    autocomplete="off"
                    aria-describedby="addon-wrapping"
                    id="floatingInputGrid"
                  />
                  <label for="floatingInputGrid">Numero</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    autocomplete="off"
                    placeholder="Cidade"
                    v-model="cidade"
                    aria-describedby="addon-wrapping"
                    id="floatingInputGrid"
                  />
                  <label for="floatingInputGrid">Cidade</label>
                </div>
              </div>
              <div class="col-sm-6 form-floating" style="flex: auto;">
                <Multiselect
                  :columns="{ container: 12, label: 4, wrapper: 6 }"
                  v-model="estado"
                  placeholder="Selecione"
                  class="multiselect-custom form-control"
                  :options="states"
                  id="floatingInputGrid"
                />
                <label
                  for="floatingInputGrid"
                  style="margin-left: 15px; padding: 12px"
                  >Estado</label
                >
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Latitude"
                    autocomplete="off"
                    required
                    v-model="latitude"
                    aria-describedby="addon-wrapping"
                    id="floatingInputGrid"
                  />
                  <label for="floatingInputGrid">Latitude *</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Longitude"
                    v-model="longitude"
                    autocomplete="off"
                    required
                    aria-describedby="addon-wrapping"
                    id="floatingInputGrid"
                  />
                  <label for="floatingInputGrid">Longitude *</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    autocomplete="off"
                    placeholder="País"
                    v-model="pais"
                    aria-describedby="addon-wrapping"
                    id="floatingInputGrid"
                  />
                  <label for="floatingInputGrid">País</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Complemento"
                    v-model="complemento"
                    aria-describedby="addon-wrapping"
                    id="floatingInputGrid"
                    autocomplete="off"
                  />
                  <label for="floatingInputGrid">Complemento</label>
                </div>
              </div>
              <div class="col-md-12">
                <h6>Pelo menos um dos IDs do Dispositivo precisa ser preenchido </h6>
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="ID do Dispositivo (Vazão)"
                    autocomplete="off"
                    v-model="serial_number_flow"
                    aria-describedby="addon-wrapping"
                    id="floatingInputGrid"
                  />
                  <label for="floatingInputGrid">ID do Dispositivo (Vazão)</label>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="ID do Dispositivo (Ruído)"
                    v-model="serial_number_noise"
                    autocomplete="off"
                    aria-describedby="addon-wrapping"
                    id="floatingInputGrid"
                  />
                  <label for="floatingInputGrid">ID do Dispositivo (Ruído)</label>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="ID do Dispositivo (Pressão)"
                    autocomplete="off"
                    v-model="serial_number_pressure"
                    aria-describedby="addon-wrapping"
                    id="floatingInputGrid"
                  />
                  <label for="floatingInputGrid">ID do Dispositivo (Pressão)</label>
                </div>
              </div>
            </div>
            <!-- Verify Country -->
            <div class="row" v-else>
              <p style="font-size:1rem">* Campos Obrigatórios</p>
              <div class="col-md-3">

                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="* Hidrômetro"
                    v-model="hidrometro"
                    autocomplete="off"
                    aria-describedby="addon-wrapping"
                    minlength="5"
                    required
                    id="floatingInputGrid"
                  />
                  <label for="floatingInputGrid">Hidrômetro *</label>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    autocomplete="off"
                    placeholder="* Display Inicial"
                    v-model="initial_display"
                    aria-describedby="addon-wrapping"
                    minlength="1"
                    required
                    id="floatingInputGrid"
                  />
                  <label for="floatingInputGrid">Display Inicial *</label>
                </div>
              </div>
              <div class="col-sm-6 form-floating">
                <Multiselect
                  :columns="{ container: 12, label: 4, wrapper: 6 }"
                  v-model="resolucao"
                  placeholder="Selecione"
                  class="multiselect-custom form-select"
                  :options="resolution"
                  v-tooltip.top="{value:'Equivalência de litros por pulso. Ex: 1 pulso equivale a (1 litro, 10 litros...)'}"
                  id="floatingInputGrid"
                />
                <label
                  for="floatingSelect"
                  style="margin-left: 15px; padding: 12px"
                  >Resolução</label
                >
              </div>
              
              <div class="col-md-6">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Código Postal"
                    autocomplete="off"
                    pattern="\d{5}-?\d{3}"
                    v-model="cep"
                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                    aria-describedby="addon-wrapping"
                    id="floatingInputGrid"
                  />
                  <label for="floatingInputGrid">Código Postal</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    autocomplete="off"
                    placeholder="Morada"
                    v-model="endereco"
                    aria-describedby="addon-wrapping"
                    id="floatingInputGrid"
                  />
                  <label for="floatingInputGrid">Morada</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    autocomplete="off"
                    placeholder="Concelho"
                    v-model="bairro"
                    aria-describedby="addon-wrapping"
                    id="floatingInputGrid"
                  />
                  <label for="floatingInputGrid">Concelho</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Número"
                    v-model="numero"
                    aria-describedby="addon-wrapping"
                    autocomplete="off"
                    id="floatingInputGrid"
                  />
                  <label for="floatingInputGrid">Número</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <input
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Distrito"
                    v-model="cidade"
                    aria-describedby="addon-wrapping"
                    id="floatingInputGrid"
                  />
                  <label for="floatingInputGrid">Distrito</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="País"
                    v-model="pais"
                    aria-describedby="addon-wrapping"
                    autocomplete="off"
                    id="floatingInputGrid"
                  />
                  <label for="floatingInputGrid">País</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    autocomplete="off"
                    placeholder="Latitude"
                    required
                    v-model="latitude"
                    aria-describedby="addon-wrapping"
                    id="floatingInputGrid"
                  />
                  <label for="floatingInputGrid">Latitude *</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <input
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Longitude"
                    v-model="longitude"
                    aria-describedby="addon-wrapping"
                    id="floatingInputGrid"
                    required
                  />
                  <label for="floatingInputGrid">Longitude *</label>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Complemento"
                    v-model="complemento"
                    aria-describedby="addon-wrapping"
                    id="floatingInputGrid"
                    autocomplete="off"
                  />
                  <label for="floatingInputGrid">Complemento</label>
                </div>
              </div>
              <div class="col-md-12">
                <h6> Pelo menos um dos IDs do Dispositivo precisa ser preenchido </h6>

                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    autocomplete="off"
                    placeholder="ID do Dispositivo (Vazão)"
                    v-model="serial_number_flow"
                    aria-describedby="addon-wrapping"
                    id="floatingInputGrid"
                  />
                  <label for="floatingInputGrid">ID do Dispositivo (Vazão)</label>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="ID do Dispositivo (Ruído)"
                    v-model="serial_number_noise"
                    autocomplete="off"
                    aria-describedby="addon-wrapping"
                    id="floatingInputGrid"
                  />
                  <label for="floatingInputGrid">ID do Dispositivo (Ruído)</label>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="ID do Dispositivo (Pressão)"
                    v-model="serial_number_pressure"
                    autocomplete="off"
                    aria-describedby="addon-wrapping"
                    id="floatingInputGrid"
                  />
                  <label for="floatingInputGrid">ID do Dispositivo (Pressão)</label>
                </div>
              </div>
            </div>
            <div class="d-grid gap-2 col-8 mx-auto">
              <button class="btn btn-login" type="submit">Salvar</button>
            </div>
          </form>
        </div>
      </div>
    </main>
  </body>
</template>

<script>
import { useLoading } from "vue-loading-overlay";
import Multiselect from "@vueform/multiselect";
// import InputMask from "primevue/inputmask";
import { createComissioning } from "../services/crud.js";
import { filterCep } from "../services/cep.js";
import { store } from "../services/store.js";
import { useToast } from "primevue/usetoast";
import Toast from "primevue/toast";
import GroupbtnAdmin from "../components/groupbtnAdmin.vue";

const $loading = useLoading();
export default {
  name: "Login",
  components: {
    Multiselect,
    GroupbtnAdmin,
    Toast
    // InputMask
  },
  props: {
    msg: String,
  },
  data() {
    return {
      local_country: "",
      isSuper: store.type_user == 1 ? true : false,
      cep: "",
      numero: "",
      cidade: "",
      estado: "",
      bairro: "",
      endereco: "",
      hidrometro: "",
      initial_display: "",
      latitude: "",
      longitude: "",
      pais: "",
      complemento: "",
      serial_number_flow: "",
      serial_number_noise: "",
      serial_number_pressure: "",
      resolucao: "",
      resolution: [
        { value: 0.001, label: "1 litro" },
        { value: 0.01, label: "10 litros" },
        { value: 0.1, label: "100 litros" },
        { value: 1, label: "1000 litros" },
      ],
      states: [
        { value: "AC", label: "Acre" },
        { value: "AL", label: "Alagoas" },
        { value: "AP", label: "Amapá" },
        { value: "AM", label: "Amazonas" },
        { value: "BA", label: "Bahia" },
        { value: "CE", label: "Ceará" },
        { value: "DF", label: "Distrito Federal" },
        { value: "ES", label: "Espirito Santo" },
        { value: "GO", label: "Goiás" },
        { value: "MA", label: "Maranhão" },
        { value: "MT", label: "Mato Grosso" },
        { value: "MS", label: "Mato Grosso do Sul" },
        { value: "MG", label: "Minas Gerais" },
        { value: "PA", label: "Pará" },
        { value: "PB", label: "Paraíba" },
        { value: "PR", label: "Paraná" },
        { value: "PE", label: "Pernambuco" },
        { value: "PI", label: "Piauí" },
        { value: "RJ", label: "Rio de Janeiro" },
        { value: "RN", label: "Rio Grande do Norte" },
        { value: "RS", label: "Rio Grande do Sul" },
        { value: "RO", label: "Rondônia" },
        { value: "RR", label: "Roraima" },
        { value: "SC", label: "Santa Catarina" },
        { value: "SP", label: "São Paulo" },
        { value: "SE", label: "Sergipe" },
        { value: "TO", label: "Tocantins" },
      ],
    };
  },
  methods: {
    submit: async function () {
      let aux = null;
      if (this.initial_display.includes(".") || this.initial_display.includes(",")) {
        aux = this.initial_display.replace(/[^0-9]/g, ".");
      } else {
        this.initial_display = Number(this.initial_display);
        aux = this.initial_display.toLocaleString();
      }
      const loader = $loading.show({});
      const dataForm = {
        number: this.numero,
        city: this.cidade,
        state: this.estado,
        neighborhood: this.bairro,
        zipcode: this.cep,
        address: this.endereco,
        meter: this.hidrometro,
        initial_display: aux,
        lat: this.latitude,
        long: this.longitude,
        country: this.pais,
        complement: this.complemento,
        serial_number_flow: this.serial_number_flow,
        serial_number_noise: this.serial_number_noise,
        serial_number_pressure: this.serial_number_pressure,
        client_id: store.client_id,
        user_id: store.user_id,
        resolution: this.resolucao,
        display_digits: 5,
        display_res: 2,
        images:[{
          'image':''
        },
        {
          'image':''
        }]
      };
      if (!this.hidrometro || !this.hidrometro.trim() || !aux || !aux.trim() || !this.latitude || !this.latitude.trim() || !this.longitude || !this.longitude.trim()){
        this.showError()
      } else {
        await createComissioning(dataForm)
          .then((response) => {            
            if(response.data.erro == false) {
              this.showsuccess();
              this.numero = "";
              this.cidade = "";
              this.estado = "";
              this.bairro = "";
              this.cep = "";
              this.endereco = "";
              this.hidrometro = "";
              this.initial_display = "";
              this.latitude = "";
              this.longitude = "";
              this.pais = "";
              this.complemento = "";
              this.serial_number_flow = "";
              this.serial_number_noise = "";
              this.serial_number_pressure = "";
              this.resolucao = "";
            } else {
              this.showWarn(response.data.message)
            }
          })
          .catch((err) =>
            this.showErrorServer(err)
          );
      }

      loader.hide();
    }
  },
  watch: {
    cep: function (val) {
      if (val.length === 8) {
        filterCep(val).then((resp) => {
          if (resp.status >= 200 && resp.status <= 299) {
            this.endereco = resp.data.logradouro;
            this.cidade = resp.data.localidade;
            this.estado = resp.data.uf;
            this.bairro = resp.data.bairro;
            val = val.replace(/^(\d{5})(\d)/,"$1-$2")
            this.cep = val;
          } else {
            val = val.replace(/^(\d{5})(\d)/,"$1-$2")
            this.cep = val;
          }
        });
      }
    },
  },
  mounted() {
    this.local_country = store.local_country;
  },
  setup(){
    const toast = useToast();
    const showError = () => {
      toast.add({severity:'error', summary: 'Error', detail:'Campos Obrigatórios não foram preenchidos!', life: 4000});
    }
    const showErrorServer = () => {
      toast.add({severity:'error', summary: 'Error', detail:" Erro ao acessar o servidor!", life: 4000});
    }
    const showsuccess = () => {
      toast.add({severity:'success', detail:" Comissionamento cadastrado com sucesso! ", life: 4000});
    }
    const showWarn = (message) => {
      toast.add({severity:'warn', detail:"Atenção!\n\n "+message+".", life: 4000});
    }
    return {showError,showErrorServer,showsuccess,showWarn}
  }
};
</script>

<style scoped>
body {
  background-size: cover;
  height: 100vh;
  margin-top: -1rem;
}
.logo_stattus {
  margin: 3rem;
  width: 25%;
  height: 12vh;
}
.img_persona {
  height: 9rem;
  position: relative;
  margin-top: -6rem;
}
main {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.card-body {
  display: flex;
  flex: 1 1 auto;
  padding: 1rem 1rem;
  flex-direction: column;
  align-items: center;
}
.btn-login {
  border-color: #6EFF94;
  background-color: #6EFF94;
  color: black;
  line-height: 2rem;
  font-size: 1.1rem;
  margin: 1rem;
  width: 8rem;
  font-weight: 450;
  place-self: center;
}

.form-floating {
  margin: 1rem 0rem;
}

.form-floating > .form-control,
.form-floating > .form-select {
  background-color: #e8f0fe;
  color: black;
  line-height: 3rem;
  margin: 0rem 0.2rem;
}
.input-group > .form-control,
.input-group > .form-select {
  background-color: #e8f0fe;
  color: black;
  line-height: 3rem;
  margin: 0rem 0.2rem;
}
.input-group {
  margin: 1rem 0rem;
}
input::placeholder {
  color: black;
}
.input-group-text {
  background-color: #37393c;
  padding: 0.375rem 1.2rem;
}
.card {
  border-radius: 2.25rem;
  padding: 1rem;
  margin-bottom: 3rem;
  margin-top: 2rem;
}
.groupCadastros {
  text-align-last: center;
  padding-top: 3rem;
}
.dropdown-item {
  padding: 0rem 0rem;
}
p {
  font-size: 1.5rem;
}
.btn-outline-success {
  border-color: #6EFF94;
  color: black;
  background-color: white;
}
.btn-outline-success:hover {
  border-color: #6EFF94;
  background-color: #6EFF94;
  color: black;
}
.btn-check:checked + .btn-outline-success {
  background-color: #6EFF94;
  border-color: #6EFF94;
  color: black;
}
.multiselect {
  height: 3.6rem;
  padding-right: 0.2rem;
  margin: 1rem 0rem;
  background: #e8f0fe;
}
.multiselect-placeholder {
  color: black !important;
}
.btn-router {
  line-height: 2rem;
  width: 100%;
}
@media screen and (max-width: 500px) {
  .btn-group {
    display: block;
  }
  .head-btn {
    display: block;
  }
}
@media screen and (min-width: 501px) {
  .head-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
@media only screen and (min-width: 575px) and (max-width: 875px) {
  #main_box {
    width: 52%;
  }
}
</style>
<style lang="scss" scoped>
::v-deep(.multiselect-custom) {
  .multiselect-placeholder {
    color: black;
    margin-top: 0.3rem
  }
  .multiselect-caret{
    margin-top: -0.5rem;
  }
  .multiselect-single-label{
    margin-top: 0.3rem;
  }
  .multiselect-clear{
    margin-top: -0.3rem;
  }
}
</style>