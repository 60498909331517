<template>
<body>  
  <main>
      <div class="card col-sm-4" id="main_box">
      <div class="card-body">
        <img class="img_persona" src='../assets/personaLogin.png'>
       <p style="text-align: center;">Meus Dados</p>
      <span>Usuario: {{name}}</span>
      <span style="margin-bottom: 30px;">Cliente: {{client}}</span>
      <div v-if="summer == true">
        <p style="text-align: center;">Meu Horário e data</p> {{now.add(1,'hour').format('LLLL:ss')}}.
      </div>
      <div v-else>
        <p style="text-align: center;">Meu Horário e Data</p> {{now.format('LLLL:ss')}}.
      </div>
      <span>Horário de verão <input type="checkbox" name="verao" id="verao" v-model="summer" value="true" @click="summerHour"> </span>       
      </div>
    </div>
  </main>
</body>
</template>

<script>
import { store } from '../services/store.js'
import moment from 'moment'

moment.locale('pt-BR')

export default {
  name: 'ParametrosConfig',
  data() {
    return {
      now: moment(),
      summer: store.summer,
      name: store.name,
      client: store.trading_name
    }
  },
  mounted(){
    setInterval(() => {
            this.now = moment()
        }, 1000);
  },
  methods: {
    summerHour(){
      store.summer = !this.summer
    }
  }
}
</script>

<style scoped>
body{
  background-image: url('../assets/inteligentes.jpg');
  background-repeat: no-repeat;
  background-size: cover;  
  height: 110vh;
  margin-top: -1rem
}
.logo_stattus{
  margin: 3rem;
  width: 25%;
  height: 12vh;
}
.img_persona{
  height: 9rem;
  position: relative;
  margin-top: -6rem;
}
main{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.card-body {
  display: flex;
  flex: 1 1 auto;
  padding: 1rem 1rem;
  flex-direction: column;
  align-items: center;
}
@import url('https://unpkg.com/css.gg@2.0.0/icons/css/lock.css');
.gg-lock {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs,1.5));
  width: 12px;
  height: 11px;
  border: 2px solid;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom: transparent;
  margin-top: -12px;
  color: white;
}
.btn-login{
  border-color: #6EFF94;
  background-color: #6EFF94;
  color: black;
  line-height: 2rem;
  font-size: 1.5rem;
  margin:1rem
}
.input-group>.form-control, .input-group>.form-select{
  background-color: #E8F0FE;
  color: black;
  line-height: 3rem
}
.input-group{
  margin: 1rem 1rem
}
input::placeholder {
  color: black;
}
.input-group-text{
  background-color: #37393c;
  padding: 0.375rem 1.2rem;
}
.card{
  border-radius: 2.25rem;
  padding: 1rem;
  margin-bottom: 3rem;
  margin-top: 10rem;
}
p{
  font-size: 1.5rem;
}
@media only screen and (min-width: 575px) and (max-width: 875px) { 
  #main_box{
    width: 52%;
  }
}
</style>
