 <template>
    <div v-show="aa" id="cal-heatmapF" style="text-align: -webkit-center;" v-bind="getDataF()">
      <div class="guide-button">
            <div @click="getDataF" id="cal-heatmapF-previous" class="button">&#60;</div>
            <div style="align-self: end;">Sensor de Vazão</div>
            <div @click="getDataF" id="cal-heatmapF-next" class="button">&#62;</div>
      </div>
    </div>
    <div v-show="bb" id="cal-heatmapN" style="text-align: -webkit-center;" v-bind="getDataN()">
      <div class="guide-button">
            <div @click="getDataN" id="cal-heatmapN-previous" class="button">&#60;</div>
            <div style="align-self: end">Sensor de Ruído</div>
            <div @click="getDataN" id="cal-heatmapN-next" class="button">&#62;</div>
      </div>
    </div>
    <div v-show="cc" id="cal-heatmapP" style="text-align: -webkit-center;" v-bind="getDataP()">
      <div class="guide-button">
            <div @click="getDataP" id="cal-heatmapP-previous" class="button">&#60;</div>
            <div style="align-self: end">Sensor de Pressão</div>
            <div @click="getDataP" id="cal-heatmapP-next" class="button">&#62;</div>
      </div>
    </div>
</template> 

<script>
import CalHeatMap from "cal-heatmap";
import moment from "moment";


export default {
    name: "CalHeatMaps",
    props: {
        dataInicial: String,
        dataF: Object,
        dataN: Object,
        dataP: Object,
        devices: Object,
        tabX: String
    },
    data() {
        return {
            calF: null,
            calN: null,
            calP: null,
            // calF: new CalHeatMap(),
            // calN: new CalHeatMap(),
            // calP: new CalHeatMap(),
            dataHeatF: {},
            dataHeatN: {},
            dataHeatP: {},
            aa: false,
            bb: false,
            cc: false,
            a: null,
            dateDevices: null,
        }
    },
    watch: {
        dataF() {
            this.getDataF();
        },
        dataN() {
            this.getDataN();
        },
        dataP() {
            this.getDataP();
        },
        devices() {
            this.getDataF();
            this.getDataN();
            this.getDataP();
        },    
    },
    methods: {
        async getDataF() {
            this.dataHeatF = await this.dataF;
            this.calF.update(this.dataHeatF)
            if(this.dataHeatF != null && this.dataHeatN == null && this.dataHeatP == null) {
                this.aa = true;
                this.bb = false;
                this.cc = false;
            }
            // if(this.dataHeatF != null && this.dataHeatN != null && this.dataHeatP == null) {
            //     this.aa = true;
            //     this.bb = true;
            //     this.cc = false;
            // }
            // if(this.dataHeatF == null && this.dataHeatN != null && this.dataHeatP != null) {
            //     this.aa = true;
            //     this.bb = false;
            //     this.cc = true;
            // }
            if(this.dataHeatF != null && this.dataHeatN != null && this.dataHeatP != null) {
                this.aa = true;
                this.bb = true;
                this.cc = true;
            }
        },
        async getDataN() {
            this.dataHeatN = await this.dataN;
            this.calN.update(this.dataHeatN)
            if(this.dataHeatF == null && this.dataHeatN != null && this.dataHeatP == null) {
                this.bb = true;
                this.aa = false;
                this.cc = false;
            }
            if(this.dataHeatF != null && this.dataHeatN != null && this.dataHeatP == null) {
                this.bb = true;
                this.aa = true;
                this.cc = false;
            }
            if(this.dataHeatF == null && this.dataHeatN != null && this.dataHeatP != null) {
                this.bb = true;
                this.aa = false;
                this.cc = true;
            }
            if(this.dataHeatF != null && this.dataHeatN != null && this.dataHeatP != null) {
                this.bb = true;
                this.aa = true;
                this.cc = true;
            }
        },
        async getDataP() {
            this.dataHeatP = await this.dataP;
            this.calP.update(this.dataHeatP)
            if(this.dataHeatF == null && this.dataHeatN == null && this.dataHeatP != null) {
                this.cc = true;
                this.bb = false;
                this.aa = false;
            }
            // if(this.dataHeatF != null && this.dataHeatN == null && this.dataHeatP != null) {
            //     this.cc = true;
            //     this.bb = false;
            //     this.aa = true;
            // }
            // if(this.dataHeatF == null && this.dataHeatN != null && this.dataHeatP != null) {
            //     this.cc = true;
            //     this.bb = true;
            //     this.aa = false;
            // }
            if(this.dataHeatF != null && this.dataHeatN != null && this.dataHeatP != null) {
                this.cc = true;
                this.bb = true;
                this.aa = true;
            }
            
        },
    },
    async mounted() {
                                
                this.calF= new CalHeatMap(),
                this.calN= new CalHeatMap(),
                this.calP= new CalHeatMap(),
                this.calF.init({
                legendColors: {min:"#ff0000", max:"#6eff94", base:"#AEAEAD"},
                itemSelector: "#cal-heatmapF",
                domain: 'month',
                cellSize: 18,
                subDomain: "x_day",
                legendMargin: [0, 10, 0, 0],
                legendVerticalPosition: "center",
                domainGutter: 10,
                domainMargin: 0,
                rowLimit: 10,
                legendOrientation: "vertical",
                label: {
                    position: "top"
                },
                range: 3,
                start: new Date(this.dataInicial),
                previousSelector: "#cal-heatmapF-previous",
                nextSelector: "#cal-heatmapF-next",
                legend: [1],
                itemName: ["comunicação","comunicação"],
                subDomainTextFormat: "%d",
                subDomainTitleFormat: {
                    empty: "{date}.",
                    filled: "Há {count} comunicação(ões) em {date}."
                },
                legendTitleFormat: {
                    lower: "Quando há 0 comunicação no dia.",
                    upper: "Quando há mais que {max} comunicação no dia."
                },
                data: this.dataHeatF,
                domainLabelFormat: function(date) {
                    moment.locale("pt-br");
                    return moment(date).format("MMMM").toUpperCase();
                },
                subDomainDateFormat: function(date) {
                    return moment(date).format("LL"); 
                }
            });
                        
                this.calN.init({
                subDomain: "x_day",
                legendMargin: [0, 10, 0, 0],
                legendVerticalPosition: "center",
                domainGutter: 10,
                domainMargin: 0,
                rowLimit: 10,
                legendOrientation: "vertical",
                label: {
                    position: "top"
                },
                legendColors: {min:"#ff0000", max:"#6eff94", base:"#AEAEAD"},
                itemSelector: "#cal-heatmapN",
                domain: 'month',
                cellSize: 18,
                range: 3,
                start: new Date(this.dataInicial),
                previousSelector: "#cal-heatmapN-previous",
                nextSelector: "#cal-heatmapN-next",
                legend: [1],
                itemName: ["comunicação","comunicação"],
                subDomainTextFormat: "%d",
                subDomainTitleFormat: {
                    empty: "Não houve comunicação no dia {date}",
                    filled: "Houve {count} comunicação(ões) em {date}"
                },
                legendTitleFormat: {
                    lower: "Menor que {min} {name}",
                    upper: "Maior que {max} {name}"
                },
                data: this.dataHeatF,
                domainLabelFormat: function(date) {
                    moment.locale("pt-br");
                    return moment(date).format("MMMM").toUpperCase();
                },
                subDomainDateFormat: function(date) {
                    return moment(date).format("LL"); 
                }
            });
            
                this.calP.init({
                subDomain: "x_day",
                legendMargin: [0, 10, 0, 0],
                legendVerticalPosition: "center",
                domainGutter: 10,
                domainMargin: 0,
                rowLimit: 10,
                legendOrientation: "vertical",
                label: {
                    position: "top"
                },
                legendColors: {min:"#ff0000", max:"#6eff94", base:"#AEAEAD"},
                itemSelector: "#cal-heatmapP",
                domain: 'month',
                cellSize: 18,
                range: 3,
                start: new Date(this.dataInicial),
                previousSelector: "#cal-heatmapP-previous",
                nextSelector: "#cal-heatmapP-next",
                legend: [1],
                itemName: ["comunicação","comunicação"],
                subDomainTextFormat: "%d",
                subDomainTitleFormat: {
                    empty: "Não houve comunicação no dia {date}",
                    filled: "Houve {count} comunicação(ões) em {date}"
                },
                legendTitleFormat: {
                    lower: "Menor que {min} {name}",
                    upper: "Maior que {max} {name}"
                },
                data: this.dataHeatF,
                domainLabelFormat: function(date) {
                    moment.locale("pt-br");
                    return moment(date).format("MMMM").toUpperCase();
                },
                subDomainDateFormat: function(date) {
                    return moment(date).format("LL"); 
                }
            });
    },
}
</script>
<style>
.button {
    color: black;
    background-color: #6EFF94;
    border-color: #6EFF94;
    border-radius: 5px;
    padding: 2px;
    font-size: 20px;
    cursor: pointer;
    padding: 0.275rem 0.75rem;
    height: 38px;
    width: 8%;
}
.button:active {
    background: #6EFF94;;
}
.guide-button {
    display: flex;
    justify-content: space-between;
}
#cal-heatmapF-next {
    font-weight: 800;
    margin-left: 1%;
}
#cal-heatmapF-previous {
    font-weight: 800;
}
#cal-heatmapN-next {
    font-weight: 800;
    margin-left: 1%;
}
#cal-heatmapN-previous {
    font-weight: 800;
}
#cal-heatmapP-next {
    font-weight: 800;
    margin-left: 1%;
}
#cal-heatmapP-previous {
    font-weight: 800;
}
 @media only screen and (min-height: 800px) {
   .guide-button {
       margin-bottom: 1vh;
       margin-top: 2vh;
    }
    .button {
        height: 42px;
    }
  }
</style>