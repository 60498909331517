<template>
<Toast />
  <div    class="head-btn">
    <h2>Listagem de usuários</h2>
    <div class="groupCadastros">
      <GroupbtnAdmin></GroupbtnAdmin>
      </div>
    <div class="d-grid gap-2 col-2" style="justify-items: self-end">
      <router-link to="/cadastro/usuarios">
        <button class="btn btn-login" type="button">Cadastrar</button>
      </router-link>
    </div>
  </div>
  <div class="card">
    <DataTable
      :value="products"
      v-model:selection="selectedProduct1"
      selectionMode="single"
      dataKey="id"
      :paginator="true"
      :rows="10"
      class="paginator-custom"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 20, 50]"
      responsiveLayout="scroll"
      @rowSelect="onRowSelect"
      currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords}"
    >
      <Column field="name" header="Nome" :sortable="true"></Column>
      <Column field="email" header="E-mail"></Column>
      <Column field="clientsLabel" header="Cliente" v-if="isSuper"></Column>
      <Column field="active" header="Ativo">
        <template #body="{ data }">
          {{ data.active === true ? "Sim" : "Não" }}
        </template>
      </Column>
    </DataTable>
  </div>
  <Dialog
    header="Edição"
    v-model:visible="displayBasic"
    :style="{ width: '75vw' }"
  >
    <div class="row">
      <p style="font-size:1rem">* Campos Obrigatórios</p>
      <div class="col-sm-6">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            placeholder="Nome"
            v-model="name"
            autocomplete="off"
            aria-describedby="addon-wrapping"
            id="floatingInputGrid"
          />
          <label for="floatingInputGrid">Nome *</label>
        </div>
      </div>
      <div class="col-sm-6" v-if="isSuper">
        <div class="form-floating">
          <Multiselect
          :columns="{ container: 12, label: 4, wrapper: 6 }"
          v-model="type_user"
          placeholder="Selecione"
          class="multiselect-custom form-select"
          :options="tipoFuncao"
          id="floatingInputGrid"
        />
        <label for="floatingSelect" style="margin-left: 3px;padding: 10px;">Função *</label>
        </div>
      </div>
      <div class="col-sm-6 form-floating" v-else>
          <Multiselect
          :columns="{ container: 12, label: 4, wrapper: 6 }"
          v-model="type_user"
          placeholder="Selecione"
          class="multiselect-custom form-select"
          :options="tipoFuncao2"
          id="floatingInputGrid"
        />
        <label for="floatingSelect" style="margin-left: 15px;padding: 10px;">Função *</label>
      </div>
      <div class="col-sm-6">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            placeholder="E-mail"
            autocomplete="off"
            v-model="email"
            aria-describedby="addon-wrapping"
            id="floatingInputGrid"
          />
          <label for="floatingInputGrid">E-mail *</label>
        </div>
      </div>
      <div class="col-sm-6 form-floating" v-if="isSuper">
          <Multiselect
          :columns="{ container: 12, label: 4, wrapper: 6 }"
          v-model="clients"
          placeholder="Selecione"
          class="multiselect-custom form-select"
          :options="selClient"
          id="floatingInputGrid"
        />
        <label for="floatingSelect" style="margin-left: 15px;padding: 10px;">Cliente *</label>
      </div>
      <div class="col-sm-6 form-floating" v-else>
          <Multiselect
          :columns="{ container: 12, label: 4, wrapper: 6 }"
          v-model="clients"
          placeholder="Selecione"
          class="multiselect-custom form-select"
          :options="clientName"
          id="floatingInputGrid"
        />
        <label for="floatingSelect" style="margin-left: 15px;padding: 10px;">Cliente *</label>
      </div>
    </div>

    <div class="form-check">
      <input
        class="form-check-input"
        v-model="active"
        type="checkbox"
        value=""
        id="flexCheckDefault"
      />
      <label class="form-check-label" for="flexCheckDefault"> Ativo </label>
    </div>
    <template #footer>
      <button @click="closeBasic" class="btn btn-danger">Cancelar</button>
      <button @click="saveEdit()" class="btn btn-filtrar">Salvar</button>
    </template>
  </Dialog>
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { ref, onMounted } from "vue";
import { useLoading } from "vue-loading-overlay";
import Dialog from "primevue/dialog";
import Multiselect from "@vueform/multiselect";
import { listOneClient, upDateUser, listClients } from "../services/crud";
import { store } from "../services/store";
import { useToast } from "primevue/usetoast";
import Toast from "primevue/toast";
import { filterUser } from "../services/crud.js";
import GroupbtnAdmin from "../components/groupbtnAdmin.vue";

const $loading = useLoading();

export default {
  components: {
    Column,
    DataTable,
    Dialog,
    Toast,
    GroupbtnAdmin,
    Multiselect,
  },
  data() {
    return {
      clientName: [],
      listaClientes: [],
      isSuper: (store.type_user == 1) ? true : false,
      tipoFuncao: [
        {label: "Super Usuario", value:1},
        {label: "Administrador", value:2},
        {label: "Operacional", value:3}
        ],
        tipoFuncao2: [
        {label: "Administrador", value:2},
        {label: "Operacional", value:3}
        ],
      dataForm: {},
    };
  },
  setup() {
    const toast = useToast();
    const showError = () => {
      toast.add({severity:'error', summary: 'Error', detail:'Campos Obrigatórios não foram preenchidos!', life: 4000});
    }
    const showErrorServer = () => {
      toast.add({severity:'error', summary: 'Error', detail:" Erro ao acessar o servidor!", life: 4000});
    }
    const showsuccess = () => {
      toast.add({severity:'success', detail:" Dados de usuário alterados com sucesso! ", life: 4000});
    }
    let listClientsArr = [];
    let listaClientes= [];
    const products = ref();
    const displayBasic = ref(false);
    const verify = store.client_id;

    const closeBasic = () => {
      displayBasic.value = false;
      if (store.type_user != 1) {
        filterUser(verify)
        .then((response) => {
          products.value = response.data;
        })
        .catch((err) =>
          this.showErrorServer(err)
        );
      } else {
       getUsers(); 
      }
    };

    const getUsers = async () =>{
      let aux = [];
      let arrAux = [];
      let arrData = [];

      await listClients()
      .then((response) => {
        listClientsArr = [];
        clients.value = response.data;
        for (let i = 0; i < clients.value.length; i++) {
          listClientsArr.push({
            label: clients.value[i].trading_name,
            value: clients.value[i].id,
          });
        }
        listaClientes = listClientsArr;
      })
      .catch(() => console.log("erro"));

        
      await filterUser()
        .then((response) => {
          for (let i = 0; i < response.data.length; i++) {
             aux = listClientsArr.filter(res =>{
               return res.value == response.data[i].client_id;
              });
              if(aux.length == 0){
                arrData = {
                  active : response.data[i].active,
                  client_id: response.data[i].client_id,
                  created_at: response.data[i].created_at,
                  email: response.data[i].email,
                  id: response.data[i].id,
                  name: response.data[i].name,
                  type_user: response.data[i].type_user,
                  updated_at: response.data[i].updated_at,
                  clients: aux[0],
                  clientsLabel: aux[0]
                }
              }else{
                arrData = {
                  active : response.data[i].active,
                  client_id: response.data[i].client_id,
                  created_at: response.data[i].created_at,
                  email: response.data[i].email,
                  id: response.data[i].id,
                  name: response.data[i].name,
                  type_user: response.data[i].type_user,
                  updated_at: response.data[i].updated_at,
                  clients: aux[0].value,
                  clientsLabel: aux[0].label
                }
              }

             
              
              arrAux.push(arrData)
          }
          products.value = arrAux;
        })
    };


    const selectedProduct1 = ref();

    const email = ref();
    const name = ref();
    const type_user = ref();
    const active = ref();
    const clients =ref();
    const clientsLabel =ref();
    const selClient = ref();
  

    onMounted(async () => {
      
      const loader = $loading.show({});
      if (store.type_user != 1) {
        await filterUser(verify)
        .then((response) => {
          products.value = response.data;
        })
        .catch((err) =>
          this.showErrorServer(err)
        );
      } else {
        getUsers(); 
      }      
      loader.hide();
    });

    const onRowSelect = (event) => {

      name.value = event.data.name;
      email.value = event.data.email;
      type_user.value = event.data.type_user;
      active.value = event.data.active;
      clients.value = event.data.clients;
      selClient.value = listaClientes;
      displayBasic.value = true;
    };

    return {
      type_user,
      products,
      selectedProduct1,
      onRowSelect,
      closeBasic,
      displayBasic,
      name,
      email,
      active,
      clients,
      clientsLabel,
      selClient,
      showError,
      showErrorServer,
      showsuccess
    };
  },
  methods: {
    saveEdit: async function () {
      const dataUpdate = {
        active: this.active,
        client_id: this.clients,
        created_at: this.dataForm.created_at,
        email: this.email,
        id: this.dataForm.id,
        name: this.name,
        type_user: this.type_user,
      };
      if (!this.clients || !this.clients.trim() || !this.email || !this.email.trim() || !this.name || !this.name.trim() || !this.type_user){
        this.showError()
      } else {
        await upDateUser(dataUpdate)
          .then((response) => {
            if (response.status >= 200 && response.status <= 299) {
              this.showsuccess()
              this.closeBasic();
              filterUser();
            }
          })
          .catch((err) =>
            this.showErrorServer(err)
          );
      }
    },
  },
  watch: {
    selectedProduct1: function (val) {
      const dataF = {
        active: val.active,
        client_id: val.client_id,
        created_at: val.created_at,
        email: val.email,
        id: val.id,
        name: val.name,
        type_user: val.type_user,
        clients: val.clients,
      };
      this.dataForm = dataF;
    },
  },
  async mounted () {
    await listOneClient(store.client_id)
    .then((response) => {
            this.clientName = [{label: response.data[0].trading_name, value: response.data[0].id,}]     
          })
          .catch((err) =>
            alert(" Erro ao acessar o servidor! \n Código do erro: " + err)
          );
  }
};
</script>

<style scoped>
.form-check-input {
  margin-right: 0.5rem;
}
.btn-login {
  border-color: #6EFF94;
  background-color: #6EFF94;
  color: black;
  line-height: 2rem;
  font-size: 1.1rem;
  margin: 1rem;
  width: 8rem;
  font-weight: 450;
}
.form-check {
  display: flex;
  justify-content: center;
}
h2 {
  margin: 1rem;
}
.btn-outline-success {
  border-color: #6EFF94;
  background-color: white;
  color: black;
}
.btn-outline-success:hover {
  border-color: #6EFF94;
  background-color: #6EFF94;
  color: black;
}
.btn-check:checked + .btn-outline-success {
  background-color: #6EFF94;
  border-color: #6EFF94;
  color: black;
}
.groupCadastros {
  text-align-last: center;
}
.dropdown-item {
  padding: 0rem 0rem;
}
.card {
  padding: 0rem;
  box-shadow: 0px 4px 19px 4px rgb(0 0 0 / 20%);
  margin: 1rem;
}
.btn-filtrar {
  border-color: #6EFF94;
  background-color: #6EFF94;
  color: black;
  margin: 1rem 0rem;
  width: 95%;
}
.form-floating {
  margin: 1rem 0rem;
}

.form-floating > .form-control,
.form-floating> .form-select {
  background-color: #e8f0fe;
  color: black;
  line-height: 3rem;
  margin: 0rem 0.2rem;
}
.input-group > .form-control,
.input-group > .form-select {
  background-color: #e8f0fe;
  color: black;
  line-height: 3rem;
  margin: 0rem 0.2rem;
}
.input-group {
  margin: 1rem 0rem;
}
input::placeholder {
  color: black;
}
.input-group-text {
  background-color: #37393c;
  padding: 0.375rem 1.2rem;
}
.multiselect {
  height: 3.63rem;
  margin: 1rem 0rem;
  background: #e8f0fe;
}
.multiselect-placeholder {
  color: black !important;
}
.btn-router {
  line-height: 2rem;
  width: 100%;
}
@media screen and (max-width: 500px) {
  .btn-group {
    display: block;
  }
  .head-btn {
    display: block;
  }
}
@media screen and (min-width: 501px) {
  .head-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>

<style lang="scss" scoped>
::v-deep(.paginator-custom) {
  .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: #6EFF94;
    border-color: #e3f2fd;
    color: #495057;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    color: #495057;
    background: #6EFF94;
  }
  .p-datatable .p-datatable-tbody > tr.p-highlight {
    background: #6EFF94;
    color: #495057;
  }
}
::v-deep(.multiselect-custom) {
  .multiselect-placeholder {
    color: black;
    margin-top: 0.3rem
  }
  .multiselect-caret{
    margin-top: -0.5rem;
  }
  .multiselect-clear{
    margin-top: -0.5rem;
  }
  .multiselect-single-label{
    margin-top: 0.3rem
  }
}
</style>