<template>
<body>
  <router-link to="/"><img class="logo_stattus" src='../assets/LogoStattus4Verde.png'></router-link>
  <main>
    <div class="card col-sm-4" id="main_box">
      <div class="card-body">
        <img class="img_persona" src='../assets/personaLogin.png'>
        <p> Esqueci minha senha </p>
        <div class="input-group flex-nowrap">
          <span class="input-group-text" id="addon-wrapping"><div class="gg-user"></div></span>
          <input type="text" class="form-control" placeholder="Email" aria-label="Email" v-model="email" aria-describedby="addon-wrapping">
        </div>
        <div class="d-grid gap-2 col-8 mx-auto">
            <button @click="submit" class="btn btn-login" type="button"><b>Enviar</b></button>
        </div>
        <div id="alertErrorLogin" v-show="errorLogin" class="alert alert-warning alert-dismissible fade show" role="alert">
          <strong>Conta não encontrada!</strong>
          <button type="button" class="btn-close" @click="close" aria-label="Close"></button>
        </div>
        <div id="alertErrorLogin" v-show="successLogin" class="alert alert-success alert-dismissible fade show" role="alert">
          <strong>Verifique sua caixa de entrada</strong>
          <button type="button" class="btn-close" @click="closeSuccessLogin" aria-label="Close"></button>
        </div>
        <router-link class="voltar" to="/"><div class="gg-arrow-left"></div>Voltar</router-link>
      </div>
    </div>
  </main>
</body>
</template>

<script>
import {useLoading} from 'vue-loading-overlay'
import { forgotPassword } from "../services/login.js";

const $loading = useLoading()
export default {
  name: 'Login',
  props: {
    msg: String
  },
  data() {
    return {
      email: '',
      errorLogin: false,
      successLogin: false
    }
  },
  methods: {
    close(){
      this.errorLogin = !this.errorLogin
    },
    closeSuccessLogin(){
      this.successLogin = !this.successLogin
    },
    async submit () {
      let email = this.email
      const loader = $loading.show({});

      await forgotPassword({email})
      .then(() => this.successLogin = !this.successLogin,
                  this.errorLogin = false)
      .catch(() => this.errorLogin = !this.errorLogin,
                  this.successLogin = false)

      loader.hide()
    }
  }
}
</script>

<style scoped>
@import url('https://unpkg.com/css.gg@2.0.0/icons/css/check-o.css');
@import url('https://unpkg.com/css.gg@2.0.0/icons/css/arrow-left.css');
body{
  background-image: url('../assets/inteligentes.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 110vh;
}
.logo_stattus{
  margin: 2rem 2rem 3.5rem 2rem;
  width: 27rem;
  height: 6.5rem;
}
.img_persona{
  height: 9rem;
  position: relative;
  margin-top: -6rem;
}
main{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.card-body {
  display: flex;
  flex: 1 1 auto;
  padding: 1rem 1rem;
  flex-direction: column;
  align-items: center;
}
@import url('https://unpkg.com/css.gg@2.0.0/icons/css/user.css');
.gg-user {
  display: block;
  transform: scale(var(--ggs,2));
  box-sizing: border-box;
  height: 20px;
  color: white;
}
.btn-login{
  border-color: #6EFF94;
  background-color: #6eff94;
  color: black;
  line-height: 2rem;
  font-size: 1.5rem;
  margin:1rem
}
.input-group>.form-control, .input-group>.form-select{
  background-color: #41464b96;
  color: white;
  line-height: 3rem
}
.input-group{
  margin: .5rem 1rem
}
input::placeholder {
  color: #fff;
}
.input-group-text{
  background-color: #37393c;
  padding: 0.375rem 1.2rem;
}
.card{
  border-radius: 2.25rem;
  padding: 1rem;
  margin-bottom: 3rem;
}
p{
  font-size: 1.5rem;
}
.voltar{
  color: #6EFF94;
  inline-size: -webkit-fill-available;
  display: inline-flex;
  align-items: center;
}
@media only screen and (max-width: 599px) {
  body{
    height: 110vh;
    text-align: center;
    background-image: url(/img/inteligentes.bb8c352c.jpg);
    background-repeat: no-repeat;
    background-size: cover;
  }

  .logo_stattus{
    margin: 2rem 0.5rem 3.5rem 0.5rem;
    width: 95%;
    height: 6.5rem;
  }
}
@media only screen and (min-width: 575px) and (max-width: 875px) { 
  #main_box{
    width: 52%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 865px) { 
  .logo_stattus{
    margin: 2rem 2rem 4.5rem 1rem;
    width: 20rem;
    height: 4.5rem;
  }
}
@media only screen and (min-width: 480px) and (max-width: 599px) { 
  .logo_stattus{
    margin: 2rem 0.5rem 3.5rem 0.5rem;
    width: 80%;
    height: 6.5rem;
  }
}
@media only screen and (min-height: 730px) {
  .logo_stattus{
    margin: 2rem 2rem 8.5rem 2rem;
  }
}
</style>
