<template>
  <div class="btn-group" role="group" aria-label="Basic radio toggle button group">

    <router-link to="/listagem/clientes" v-if="isSuper">
      <input v-if="$route.path === '/listagem/clientes'|| $route.path === '/cadastro/clientes'" type="radio" class="btn-check" name="btnradio" checked />
      <input v-else type="radio" class="btn-check" name="btnradio" />
      <label class="btn btn-outline-success btn-router"> Cliente </label>
    </router-link>

    <router-link to="/listagem/comissionamento">
      <input v-if="$route.path === '/listagem/comissionamento' || $route.path === '/cadastro/comissionamento'" type="radio" class="btn-check" name="btnradio" checked />
      <input v-else type="radio" class="btn-check" name="btnradio" />
      <label class="btn btn-outline-success btn-router"> Comissionamento </label>
    </router-link>

    <router-link to="/listagem/dispositivos">
      <input v-if="$route.path === '/listagem/dispositivos'|| $route.path === '/cadastro/dispositivos'" type="radio" class="btn-check" name="btnradio" checked/>
      <input v-else type="radio" class="btn-check" name="btnradio" />
      <label class="btn btn-outline-success btn-router"> Dispositivo </label>
    </router-link>

    <router-link to="/listagem/usuarios">
      <input v-if="$route.path === '/listagem/usuarios'|| $route.path === '/cadastro/usuarios'" type="radio" class="btn-check" name="btnradio" checked/>
      <input v-else type="radio" class="btn-check" name="btnradio" />
      <label class="btn btn-outline-success btn-router"> Usuário </label>
    </router-link>

  </div>
</template>

<script>
import { store } from "../services/store"

export default {
    data(){
    return{
      isSuper: (store.type_user == 1 ? true : false)
    }
    
  }
}
</script>
<style scoped>
.btn-outline-success {
  border-color: #6EFF94;
  color: black;
  background-color: white;
}
.btn-outline-success:hover {
  border-color: #6EFF94;
  background-color: #6EFF94;
  color: black;
}
.btn-check:checked + .btn-outline-success {
  background-color: #6EFF94;
  border-color: #6EFF94;
  color: black;
}
@media screen and (max-width: 500px) {
  .btn-group {
    display: block;
  }
}
</style>
