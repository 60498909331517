<template>
<Toast />
  <body>
    <main>
      <div v-if="this.deztags" class="card col-sm-7" id="main_box">
        <div class="card-body">
          <p>Nova Tag</p>
          <div class="row">
          <p style="font-size:1rem">* Campos Obrigatórios</p>
            <div class="col-sm-12">
              <div class="form-floating">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Nome"
                  autocomplete="off"
                  v-model="name"
                  aria-describedby="addon-wrapping"
                  id="floatingInputGrid"
                />
                <label for="floatingInputGrid">Nome *</label>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="form-floating">
                <Multiselect
                :columns="{ container: 12, label: 4, wrapper: 6 }"
                v-model="selColor"
                required
                placeholder="Selecione"
                class="multiselect-custom form-select"
                :options="listaCores"
                id="floatingInputGrid"
              />
              <label for="floatingSelect" style="margin-left: 15px;padding: 12px;">Cor *</label>
              </div>
            </div>
          </div>
          <div class="d-grid gap-2 col-8 mx-auto">
            <button @click="submit" class="btn btn-login" type="button">
              Adicionar
            </button>
          </div>
        </div>
      </div>
      <div class="card col-sm-7" id="main_box">
        <div class="card-body">
          <p>Deletar Tag</p>
          <div class="row" style="width: 37%">
            <div class="col-sm-12">
              <div class="form-floating">
                <Multiselect
                :columns="{ container: 12, label: 4, wrapper: 6 }"
                v-model="id_name_delete"
                required
                placeholder="Selecione"
                class="multiselect-custom form-select"
                :options="listTags"
                id="floatingInputGrid"
              />
              <label for="floatingSelect" style="margin-left: 15px;padding: 12px;">Tags *</label>
              </div>
            </div>
          </div>
          <div class="d-grid gap-2 col-8 mx-auto">
            <button @click="submitDelete" class="btn btn-login" type="button">
              Deletar
            </button>
          </div>
        </div>
      </div>
    </main>
  </body>
</template>

<script>
import { store } from "../services/store.js";
import { listTag, createTag, deleteTag } from "../services/crud.js";
// import { useLoading } from "vue-loading-overlay";
import Multiselect from "@vueform/multiselect";
import { useToast } from "primevue/usetoast";
import Toast from "primevue/toast";
// const $loading = useLoading();
export default {
  components: {
    Multiselect,
    Toast
  },
  name: "Login",
  props: {
    msg: String,
  },
  data() {
    return {
      isSuper: store.type_user == 1 ? true : false,
      selColor: "",
      name: "",
      listTags: [],
      listaCores: [
        { label: "Vermelho", value: 'red' },
        { label: "Verde", value: 'green' },
        { label: "Azul", value: 'blue' },
        { label: "Amarelo", value: 'yellow' },
        { label: "Cinza", value: 'gray' },
      ],
      client_id: "",
      id_name: '',
      id_name_delete: "",
      deztags: true
    };
  },
  methods: {
    submit: async function () {      
      
      this.id_name = this.name.toLocaleLowerCase().replace(/\s/g, '_').normalize('NFD').replace(/[\u0300-\u036f]/g, "")
      if(!this.name || !this.name.trim() || !this.id_name || !this.id_name.trim || !this.selColor || !this.selColor.trim() ){
        this.showError()
      }else{
        const dataForm = {
          name: this.name,
          id_name: this.id_name,
          color: this.selColor,
          client_id: store.client_id
        }
        await createTag(dataForm)
        .then((response) => {
          if (response.status >= 200 && response.status <= 299) {
            this.showsuccess();
            this.name = "";
            this.selColor = ""
          }
          this.listTags.length >= 10 ? this.deztags = false : this.deztags = true
        })
        .catch((err) => this.showErrorServer(err))
      }
    },
    async submitDelete(){
      const dataDelete = {
        client_id: store.client_id,
        id_name: this.id_name_delete
      }
      await deleteTag(dataDelete)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          this.showSuccessDelete()
          this.id_name_delete = ''
        }
        this.listTags.length >= 10 ? this.deztags = false : this.deztags = true
      })
      .catch((err) => this.showErrorServer(err))
    },
    async submitList() {
    let aux = []
    let listaTags = []
    const client_id = {"client_id":store.client_id}
    await listTag(client_id)
    .then((response) => {
      aux = response.data
      this.aaa = aux.length;   
      for (let i = 0; i < aux.length; i++) {
        listaTags = {
          label: aux[i].name,
          value: aux[i].id_name
        }
        this.listTags.push(listaTags);
      }
    })
    }
  },
  mounted(){
    this.submitList();
  },
  watch: {
    aaa: function(a) {
      this.listTags = []
      this.submitList()
      if (a < 10) {
        this.deztags = true;
      }else{
        this.deztags = false;
      }
    }
  },
  setup(){
    const toast = useToast();
    const showError = () => {
      toast.add({severity:'error', summary: 'Error', detail:'Campos Obrigatórios não foram preenchidos!', life: 4000});
    }
    const showErrorServer = () => {
      toast.add({severity:'error', summary: 'Error', detail:" Erro ao acessar o servidor!", life: 4000});
    }
    const showsuccess = () => {
      toast.add({severity:'success', detail:"Tag adicionada com sucesso! ", life: 4000});
    }
    const showSuccessDelete = () => {
      toast.add({severity:'success', detail:"Tag removida com sucesso! ", life: 4000});
    }
    const showErrorAlreadyExisted = () => {
      toast.add({severity:'error', detail:"Tag já existente! ", life: 4000});
    }
    return{showError,showErrorServer,showsuccess, showSuccessDelete, showErrorAlreadyExisted}
  }
};
</script>

<style scoped>
p{
  font-size: 0.7rem;
}
body {
  background-size: cover;
  height: 100vh;
  margin-top: -1rem;
}
.groupCadastros {
  text-align-last: center;
  padding-top: 3rem;
}
.logo_stattus {
  margin: 3rem;
  width: 25%;
  height: 12vh;
}
.img_persona {
  height: 9rem;
  position: relative;
  margin-top: -6rem;
}
main {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.card-body {
  display: flex;
  flex: 1 1 auto;
  padding: 1rem 1rem;
  flex-direction: column;
  align-items: center;
}
.dropdown-item {
  padding: 0rem 0rem;
}
.btn-login {
  border-color: #6EFF94;
  background-color: #6EFF94;
  color: black;
  line-height: 2rem;
  font-size: 1.1rem;
  margin: 1rem;
  place-self: center;
  width: 8rem;
  font-weight: 450;
}
.form-floating {
  margin: 1rem 0rem;
}

.form-floating > .form-control,
.form-floating> .form-select {
  background-color: #e8f0fe;
  color: black;
  line-height: 3rem;
  margin: 0rem 0.2rem;
}
.input-group > .form-control,
.input-group > .form-select {
  background-color: #e8f0fe;
  color: black;
  line-height: 3rem;
  margin: 0rem 0.2rem;
}
.input-group {
  margin: 1rem 0rem;
}
input::placeholder {
  color: black;
}
.input-group-text {
  background-color: #37393c;
  padding: 0.375rem 1.2rem;
}
.card {
  border-radius: 2.25rem;
  padding: 1rem;
  margin-bottom: 3rem;
  margin-top: 2rem;
}
p {
  font-size: 1.5rem;
}
.btn-outline-success {
  border-color: #6EFF94;
  background-color: white;
  color: black;
}
.btn-outline-success:hover {
  border-color: #6EFF94;
  background-color: #6EFF94;
  color: black;
}
.btn-check:checked + .btn-outline-success {
  background-color: #6EFF94;
  border-color: #6EFF94;
  color: black;
}
.multiselect {
  height: 3.63rem;
  margin: 1rem 0rem;
  padding-right: 0.2rem;
  background: #e8f0fe;
}
.multiselect-placeholder {
  color: black !important;
}
.required,
.error {
  color: red;
}
.btn-router {
  line-height: 2rem;
  width: 100%;
}
@media screen and (max-width: 500px) {
  .btn-group {
    display: block;
  }
  .head-btn {
    display: block;
  }
}
@media screen and (min-width: 501px) {
  .head-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
@media only screen and (min-width: 575px) and (max-width: 875px) {
  #main_box {
    width: 52%;
  }
}
</style>
<style lang="scss" scoped>
::v-deep(.multiselect-custom) {
  .multiselect-placeholder {
    color: black;
    margin-top: 0.3rem;
  }
  .multiselect-caret{
    margin-top: -0.5rem;
  }
  .multiselect-single-label{
    margin-top: 0.3rem;
  }
  .multiselect-clear{
    margin-top: -0.3rem;
  }
}
</style>