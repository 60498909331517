<template>
          <div style="text-align: center;">
            <div class="btn-group" role="group"  aria-label="Basic radio toggle button group">
              <input
                type="radio"
                class="btn-check"
                name="btnradio"
                id="btnR1"
                autocomplete="off"
                value="1"
                v-model="choose"
                checked
              />
              <label
                class="btn btn-outline-success" for="btnR1">Alarme</label>
              <input
                type="radio"
                class="btn-check"
                name="btnradio"
                id="btnR2"
                autocomplete="off"
                value="2"
                v-model="choose"
              />
              <label class="btn btn-outline-success" for="btnR2">Controle</label>
              <input
                type="radio"
                class="btn-check"
                name="btnradio"
                id="btnR3"
                autocomplete="off"
                value="3"
                v-model="choose"
              />
              <label class="btn btn-outline-success" for="btnR3">Comunicações</label>
            </div>
          </div>
      
        <div class="col-sm-12" style="text-align:start;">
        <div class="items">
            <div v-if="this.choose == 1">
              <div class="my-scroll-container" style="width:auto">
                <table class="table table-borderless">
                  <tbody v-for="itemAlarm in alarms" :key="itemAlarm">
                    <tr>
                      <td><span style="font-weight:500;">Data:</span> {{new Date(itemAlarm.timestamp).toLocaleString("pt-br")}}</td>
                      <td><span style="font-weight:500;">Tipo de Alarme:</span> {{itemAlarm.name.pt}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
          </div>
          <div v-if="this.choose == 2">
              <div class="my-scroll-container" style="width:auto">
                <table class="table table-borderless">
                  <tbody v-for="items in metrics" :key="items">
                    <tr>
                      <td><span style="font-weight:500;">Data:</span> {{new Date(items.timestamp).toLocaleString("pt-br")}}</td>
                      <td><span style="font-weight:500;">Tipo:</span> {{items.name.pt}}</td>
                      <td><span style="font-weight:500;">Valor:</span> {{items.info.single_value}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
          </div>
          <div v-if="this.choose == 3">
              <div class="my-scroll-container" style="width:auto">
                <table class="table table-borderless">
                  <tbody v-for="items in readings" :key="items">
                    <tr>
                      <td><span style="font-weight:500;">Data:</span> {{new Date(items.timestamp).toLocaleString("pt-br")}}</td>
                      <td><span style="font-weight:500;">Comunicação:</span> OK</td>
                    </tr>
                  </tbody>
                </table>
              </div>
          </div>
        </div>
    </div>
   
</template>

<script>
import {listEvents} from "../services/dashboard.js";



export default {
    name: "LogTel",
    props: {
      devices: Object,
    },
    data() {
    return {
      choose: 1,
      metrics: null,
      alarms: null,
      readings: null
      }
    },
    async mounted() {
      let auxMetrics = [];
      let auxAlarms = [];
      let auxReading = [];
      if(this.devices) {
        for(let i in this.devices) {
          const data =  {
            "device_id": this.devices[i].id
          };
        await listEvents(data)
            .then((response) => {        
              if(!response.data.erro) {
                response.data.data.map(event => {
                if (event.info_type == 'metrics') {
                  auxMetrics.push(event)
                  this.metrics = auxMetrics;
                  this.metrics.type = this.devices[i].type;
                }

                if (event.info_type == 'alarms') {
                  auxAlarms.push(event)
                  this.alarms = auxAlarms;
                  this.alarms.type = this.devices[i].type;   
                  }

                if (event.info_type == 'READING' || event.info_type == 'reading') {
                  auxReading.push(event)
                  this.readings = auxReading;  
                  this.readings.type = this.devices[i].type; 
                  }
                })
              }
            })
            .catch((err) => alert(" Erro ao acessar o servidor! \n Código do erro: " + err),
          );
        }
      }
    },
    setup() {

    }
}
</script>
<style>
.btn-outline-success {
  border-color: #6eff94;
  color: black;
}
.btn-outline-success:hover {
  border-color: #6eff94;
  background-color: #6eff94;
  color: black;
}
.btn-check:checked + .btn-outline-success {
  background-color: #6eff94;
  border-color: #6eff94;
  color: black;
}
.items {
  margin-top: 20px;
  margin-left:30px
}
.my-scroll-container {
  display: block;
  width: 350px;
  height: 200px;
  overflow-y: scroll;
  scroll-behavior: smooth;
}
</style>