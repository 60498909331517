<template>
  <Dialog
    v-model:visible="displayBasic"
    :style="{ width: '85vw' }"
    :closable="false"
  >
    <template #header>
      <h3 class="col-sm-6">
        <b>Detalhamento do Ponto de Instalação Nº</b>
        {{ this.selectedCountries.id }}
      </h3>
      <div style="width: 48%">
        <Button
          style="width: 30%"
          id="btnAssociarTag"
          class="btn btn-filtrar"
          :label="'Associar Tag'"
          @click="toggle"
          aria:haspopup="true"
          aria-controls="overlay_panel"
        />
      </div>
    </template>

    <div style="display: flex; justify-content: space-between">
      <div>
        <p>
          <b>Endereço: </b> {{ this.selectedCountries.address }},
          {{ this.selectedCountries.number }} ,
          {{ this.selectedCountries.city }} -
          {{ this.selectedCountries.state }}
        </p>
        <p><b> Código de Instalação: </b> {{ this.selectedCountries.name }}</p>
        <p>
          <b> Data de Instalação: </b>
          {{
            new Date(this.selectedCountries.created_at).toLocaleString("pt-br")
          }}
        </p>
        <p style="margin-bottom: 0.5rem; align-self: flex-start">
                <b> Redefinir Alarme:</b>  <input type="checkbox" v-model="checkboxChecked" />
          </p>
      </div>
      <div class="col-sm-6">
        <OverlayPanel
          ref="op"
          appendTo="body"
          :showCloseIcon="true"
          id="overlay_panel"
          style="width: 450px"
          :breakpoints="{ '960px': '75vw' }"
        >
          <DataTable
            :value="listTags"
            v-model:selection="selectedProduct"
            dataKey="id_name"
            responsiveLayout="scroll"
          >
            <Column
              selectionMode="multiple"
              headerStyle="width: 3em"
              :row-select="newTags(selectedProduct)"
            >
            </Column>
            <Column header="Tag">
              <template #body="slotProps">
                <Chip
                  :key="slotProps.data.label"
                  :label="slotProps.data.name"
                  :class="slotProps.data.color"
                  :style="
                    slotProps.data.color != 'yellow' &&
                    slotProps.data.color != 'aqua' &&
                    slotProps.data.color != 'pink'
                      ? `color: white;background-color: ${slotProps.data.color};`
                      : `color: black;background-color: ${slotProps.data.color}`
                  "
                />
              </template>
            </Column>
          </DataTable>
        </OverlayPanel>
        <div>
          <Chip
            v-for="label in newTagsUp"
            :key="label.name"
            :label="label.name"
            :class="label.color"
            :style="
              label.color != 'yellow' &&
              label.color != 'aqua' &&
              label.color != 'pink'
                ? `margin:0.5rem; color: white;background-color: ${label.color};`
                : `margin:0.5rem; color: black;background-color: ${label.color}`
            "
          />
        </div>
      </div>
    </div>
    <div class="col-sm-12 infoDevices" style="margin: 3rem 0rem">
      <div class="col-sm-4">
        <div
          style="display: flex; flex-direction: column"
          v-if="this.selectedCountries.flow !== null"
        >
          <div style="align-self: center">
            <p>Sensor de Vazão</p>
          </div>
          <div style="display: flex; flex-direction: row">
            <div
              style="
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-right: 1rem;
              "
            >
              <img
                style="margin: 1rem"
                src="../assets/flowOff.png"
                width="100"
                height="100"
              />
              <p>
                <b>ID do Dispositivo: </b>
                {{ selectedCountries.flow.serial_number }}
              </p>
              <p style="margin-bottom: 0.5rem; align-self: flex-start">
                <b> Definição de Alarme:</b>
              </p>
            </div>
            <div class="sliders">
              <Slider
                style="margin: 0rem 1rem"
                v-model="selectedCountries.flow.slider"
                v-bind="slider_flow"
                class="slider-vertical slider-base"
              />
              <div style="align-self: center">
                <p style="margin-bottom: 0.5rem; width: 6rem">
                  <label style="width: 1.8rem">
                    {{ this.selectedCountries.flow.slider[1] }}</label
                  >
                  <label>m³/h</label>
                </p>
                <p>
                  <label style="width: 1.8rem">
                    {{ this.selectedCountries.flow.slider[0] }}</label
                  >
                  <label>m³/h</label>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div
          style="display: flex; flex-direction: column"
          v-if="this.selectedCountries.noise !== null"
        >
          <div style="align-self: center">
            <p>Sensor de Ruído</p>
          </div>
          <div style="display: flex; flex-direction: row">
            <div
              style="
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-right: 1rem;
              "
            >
              <img
                style="margin: 1rem"
                src="../assets/noiseOff.png"
                width="100"
                height="100"
              />
              <p>
                <b>ID do Dispositivo:</b
                >{{ this.selectedCountries.noise.serial_number }}
              </p>
              <p style="margin-bottom: 0.5rem; align-self: flex-start">
                <b> Definição de Alarme:</b>
              </p>
            </div>

            <div class="sliders">
              <Slider
                style="margin: 0rem 1rem"
                v-model="
                  this.selectedCountries.noise.alarm_boundaries.upper_limit
                "
                v-bind="slider_noise"
                class="slider-vertical slider-base"
              />
              <div style="align-self: center; width: 3.5rem">
                <label>{{
                  this.selectedCountries.noise.alarm_boundaries.upper_limit
                }}</label>
                <label>% </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div
          style="display: flex; flex-direction: column"
          v-if="this.selectedCountries.pressure !== null"
        >
          <div style="align-self: center">
            <p>Sensor de Pressão</p>
          </div>
          <div style="display: flex; flex-direction: row">
            <div
              style="
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-right: 1rem;
              "
            >
              <img
                style="margin: 1rem"
                src="../assets/pressureOff.png"
                width="100"
                height="100"
              />
              <p>
                <b>ID do Dispositivo:</b
                >{{ selectedCountries.pressure.serial_number }}
              </p>
              <p style="margin-bottom: 0.5rem; align-self: flex-start">
                <b> Definição de Alarme:</b>
              </p>
            </div>

            <div class="sliders">
              <Slider
                style="margin: 0rem 1rem"
                v-model="this.selectedCountries.pressure.slider"
                v-bind="slider_pressure"
                class="slider-vertical slider-base"
              />
              <div style="align-self: center">
                <p style="margin-bottom: 0.5rem; width: 6rem">
                  <label style="width: 1.6rem"
                    >{{ this.selectedCountries.pressure.slider[1] }}
                  </label>
                  <label>m.c.a.</label>
                </p>
                <p>
                  <label style="width: 1.6rem">
                    {{ this.selectedCountries.pressure.slider[0] }}
                  </label>
                  <label>m.c.a.</label>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <button @click="closeBasic" class="btn btn-danger">Cancelar</button>
      <button @click="saveBoundaries()" class="btn btn-filtrar">Salvar</button>
    </template>
  </Dialog>
</template>


<script>
import Chip from "primevue/chip";
import OverlayPanel from "primevue/overlaypanel";
import DataTable from "primevue/datatable";
import Button from "primevue/button";
import Column from "primevue/column";
import Slider from "@vueform/slider";
import Dialog from "primevue/dialog";
import { useToast } from "primevue/usetoast";
import { defineComponent, ref } from "vue";

import { 
  updateAlarmes, 
  getAlarmes,
  listInstallPoint, 
  updateTag,
  } from "../services/dashboard.js";

export default defineComponent({
  name: "CompDetalhamento",
  components: {
    Chip,
    OverlayPanel,
    DataTable,
    Button,
    Column,
    Slider,
    Dialog
  },
  props: {
    show: Boolean,
    devices: Object,
    arrTags: Array
  },
  data() {
    return {
        checkboxChecked: false,
        displayBasic: false,
        listTags: [],
        newTagsUp:{},
        selectedCountries: {
          name: "",
          code: null,
          id: null,
          flow: null,
          noise: null,
          pressure: null,
      },
      slider_flow: {
        orientation: "vertical",
        direction: "rtl",
        tooltips: false,
        step: 0.5,
        min: -1,
        max: 20,
        lazy: false,
      },
      slider_pressure: {
        orientation: "vertical",
        tooltips: false,
        direction: "rtl",
        lazy: false,
        min: 0,
        max: 150,
      },
    };
  },
  methods: {
    async openBasic() {
      this.listTags = this.arrTags;
      this.displayBasic = false;
      this.selectedCountries = await this.devices;
      if (this.selectedCountries.devices == null) {
        return;
      }
      let selId = { install_point_id: this.selectedCountries.id };
      let getValueAlarm;
      if(this.selectedCountries.id ) {
        await getAlarmes(selId)
        .then((response) => {
          getValueAlarm = response;
          return getValueAlarm;
        })
        .catch((err) =>
          this.showErrorServer(err)
        );
      }

      this.selectedCountries.flow = null;
      this.selectedCountries.pressure = null;
      this.selectedCountries.noise = null;
      for (let i = 0; i < this.selectedCountries.devices.length; i++) {
        if (this.selectedCountries.devices[i].type == "flow") {
          this.selectedCountries.flow = this.selectedCountries.devices[i];
          let temp1 = getValueAlarm.data.filter((j) => {
            return j.device_id === this.selectedCountries.devices[i].id;
          });

          if (temp1.length > 0) {
            if (temp1[0].alarm_boundaries) {
              let lower_limit =
                temp1[0].alarm_boundaries.lower_limit == "" ||
                temp1[0].alarm_boundaries.lower_limit == null
                  ? 0
                  : temp1[0].alarm_boundaries.lower_limit;

              let upper_limit =
                temp1[0].alarm_boundaries.upper_limit == "" ||
                temp1[0].alarm_boundaries.upper_limit == null
                  ? 0
                  : temp1[0].alarm_boundaries.upper_limit;

              this.selectedCountries.flow.slider = ref([
                Number(lower_limit),
                Number(upper_limit),
              ]);

              this.selectedCountries.flow.alarm_boundaries.lower_limit = ref([
                Number(lower_limit),
              ]);
              this.selectedCountries.flow.alarm_boundaries.upper_limit = ref([
                Number(upper_limit),
              ]);
            } else {
              let lower_limit = 0;
              let upper_limit = 0;
              this.selectedCountries.flow.slider = ref([
                Number(lower_limit),
                Number(upper_limit),
              ]);
              this.selectedCountries.flow.alarm_boundaries.lower_limit =
                Number(lower_limit);
              this.selectedCountries.flow.alarm_boundaries.upper_limit =
                Number(upper_limit);
            }
          } else {
            let lower_limit = 0;
            let upper_limit = 0;
            this.selectedCountries.flow.slider = ref([
              Number(lower_limit),
              Number(upper_limit),
            ]);
            this.selectedCountries.flow.alarm_boundaries.lower_limit =
              Number(lower_limit);
            this.selectedCountries.flow.alarm_boundaries.upper_limit =
              Number(upper_limit);
          }
        }
        if (this.selectedCountries.devices[i].type == "pressure") {
          this.selectedCountries.pressure = this.selectedCountries.devices[i];

          let temp2 = getValueAlarm.data.filter((k) => {
            return k.device_id === this.selectedCountries.devices[i].id;
          });

          if (temp2.length > 0) {
            if (temp2[0].alarm_boundaries) {
              let lower_limit =
                temp2[0].alarm_boundaries.lower_limit == "" ||
                temp2[0].alarm_boundaries.lower_limit == null
                  ? 0
                  : temp2[0].alarm_boundaries.lower_limit;

              let upper_limit =
                temp2[0].alarm_boundaries.upper_limit == "" ||
                temp2[0].alarm_boundaries.upper_limit == null
                  ? 0
                  : temp2[0].alarm_boundaries.upper_limit;

              this.selectedCountries.pressure.slider = ref([
                Number(lower_limit),
                Number(upper_limit),
              ]);
              this.selectedCountries.pressure.alarm_boundaries.lower_limit =
                Number(lower_limit);
              this.selectedCountries.pressure.alarm_boundaries.upper_limit =
                Number(upper_limit);
            } else {
              let lower_limit = 0;
              let upper_limit = 0;
              this.selectedCountries.pressure.slider = ref([
                Number(lower_limit),
                Number(upper_limit),
              ]);
              this.selectedCountries.pressure.alarm_boundaries.lower_limit =
                Number(lower_limit);
              this.selectedCountries.pressure.alarm_boundaries.upper_limit =
                Number(upper_limit);
            }
          } else {
            let lower_limit = 0;
            let upper_limit = 0;
            this.selectedCountries.pressure.slider = ref([
              Number(lower_limit),
              Number(upper_limit),
            ]);
            this.selectedCountries.pressure.alarm_boundaries.lower_limit =
              Number(lower_limit);
            this.selectedCountries.pressure.alarm_boundaries.upper_limit =
              Number(upper_limit);
          }
        }

        if (this.selectedCountries.devices[i].type == "noise") {
          this.selectedCountries.noise = this.selectedCountries.devices[i];
          if (this.selectedCountries.noise.alarm_boundaries == null) {
            this.selectedCountries.noise.alarm_boundaries = {};
          }
          this.selectedCountries.noise.alarm_boundaries.lower_limit = 0;
          this.selectedCountries.noise.alarm_boundaries.upper_limit =
            this.selectedCountries.noise.alarm_boundaries.upper_limit == "" ||
            this.selectedCountries.noise.alarm_boundaries.upper_limit == null
              ? 0
              : this.selectedCountries.noise.alarm_boundaries.upper_limit;
        }
      }
      this.displayBasic = true;
      this.detailPointInstall(selId);
    },
    async detailPointInstall(data) {
      const id = await data.install_point_id;
      listInstallPoint(id)
      .then((res) => {
        if(res.data) {
          if(res.data[0]) {
          this.tagsPointInstall = res.data[0].tags_dados;
          this.newTagsUp = this.tagsPointInstall ;
          this.updateList(res.data[0].tags_dados)
          }
        }
        
      })
    },
    async saveBoundaries() {
      try {
        for (let i = 0; i < this.selectedCountries.devices.length; i++) {
          let alarmId = {
            install_point_id: "",
            device_id: "",
            alarm_boundaries: {
              lower_limit: "",
              upper_limit: "",
            },
          };
          if(!this.checkboxChecked) {
            alarmId.install_point_id =
            this.selectedCountries.devices[i].install_point_id;
          if (this.selectedCountries.devices[i].type == "pressure") {
            alarmId.device_id = this.selectedCountries.devices[i].id;

            alarmId.alarm_boundaries.lower_limit =
              this.selectedCountries.pressure.slider[0];

            alarmId.alarm_boundaries.upper_limit =
              this.selectedCountries.pressure.slider[1];
          }
          if (this.selectedCountries.devices[i].type == "flow") {
            alarmId.device_id = this.selectedCountries.devices[i].id;

            alarmId.alarm_boundaries.lower_limit =
              this.selectedCountries.flow.slider[0];

            alarmId.alarm_boundaries.upper_limit =
              this.selectedCountries.flow.slider[1];
          }
          if (this.selectedCountries.devices[i].type == "noise") {
            alarmId.device_id = this.selectedCountries.devices[i].id;
            alarmId.alarm_boundaries.lower_limit = "";
            alarmId.alarm_boundaries.upper_limit =
              this.selectedCountries.noise.alarm_boundaries.upper_limit;
          }
          }else if(this.checkboxChecked){
            alarmId.install_point_id =
            this.selectedCountries.devices[i].install_point_id;
          if (this.selectedCountries.devices[i].type == "pressure") {
            alarmId.device_id = this.selectedCountries.devices[i].id;

            alarmId.alarm_boundaries.lower_limit =
              null;

            alarmId.alarm_boundaries.upper_limit =
              null;
          }
          if (this.selectedCountries.devices[i].type == "flow") {
            alarmId.device_id = this.selectedCountries.devices[i].id;

            alarmId.alarm_boundaries.lower_limit =
              null;

            alarmId.alarm_boundaries.upper_limit =
              null;
          }
          if (this.selectedCountries.devices[i].type == "noise") {
            alarmId.device_id = this.selectedCountries.devices[i].id;
            alarmId.alarm_boundaries.lower_limit = "";
            alarmId.alarm_boundaries.upper_limit =
              null;
          }
          }
          await updateAlarmes(alarmId);
        }
        this.updateTagSetup(this.selectedCountries.id);
        this.showSuccess();
      } catch (e) {
        this.showErrorServer(e);
        console.log(e);
      }
      setTimeout(() => {
        this.closeBasic();
      }, 250);
      this.emitter.emit('my-event-get-pins-func')
    },
    newTags(data) {
      this.newTagsUp = data;
    },
    closeBasic() {
      this.displayBasic = false;
      this.newTagsUp = {};
    },
  },
  watch: {
    show: function () {
      this.openBasic();
    },
  },
  setup() {
    const myMapRef = ref(null);
    const toast = useToast();
    //OverlayPanel
    const op = ref();
    const selectedProduct = ref();
    const toggle = (event) => {
      op.value.toggle(event);
    };
    let _tags = [];

    //Update Tag
    const updateTagSetup = (_id) => {
      if(selectedProduct.value){
        for (let n = 0; n < selectedProduct.value.length; n++) {
          _tags.push(selectedProduct.value[n].id_name)
        }
        let tagEdit = {
          id: _id,
          tags: JSON.stringify(_tags).replaceAll(',',';').replaceAll('[','').replaceAll(']','').replaceAll('"','')
        };
        updateTag(tagEdit)
        _tags = []
      }
    }
    const updateList = (listTags) => {
      selectedProduct.value = listTags
    }

    const showSuccess = () => {
      toast.add({
        severity: "success",
        summary: "Informações do Ponto de Instalação salvas com sucesso!",
        life: 3000,
      });
    };
    const showErrorServer = () => {
      toast.add({severity:'error', summary: 'Error', detail:" Erro ao acessar o servidor!", life: 4000});
    }

    return {
      op,
      selectedProduct,
      toggle,
      updateTagSetup,
      updateList,
      myMapRef,
      showSuccess,
      showErrorServer,
    }
  }
});
</script>

<style scoped>
.btn-filtrar {
  border-color: #6eff94;
  background-color: #6eff94;
  color: black;
  margin: .5rem 0rem;
  width: 89%;
  font-weight: 400;
  height: 42px;
}
.inputDetalhamento {
  width: 35%;
  font-size: 20px;
  height: 25%;
}
.sliders {
  align-self: center;
  width: 35%;
  display: inline-flex;
}
.imgSensor {
  display: flex;
  margin-bottom: 0.5rem;
  justify-content: center;
  align-items: center;
}
.sensoresPI {
  display: flex;
  justify-content: space-evenly;
}
.slider-vertical {
  height: 8rem;
  --slider-bg: RED;
}
.infoDevices {
  display: flex;
  margin-top: 1rem;
  justify-content: space-around;
}
input[type="checkbox"] {
    zoom: 1.5;
    vertical-align: sub;
    accent-color: #6eff94;
}
</style>
<style src="@vueform/slider/themes/default.css"></style>