import { store } from './store.js'
import api from './api.js'
import { routes } from "../services/routes";


// const routes = () => ({
//     public: () => ({
//         install_points: () => ({
//             all: (client_id) => `/public/install_points/all?client_id=${client_id}`,
//         })
//     })
// })

const getHeader = () => ({
    headers: {
        'Authorization': `Bearer ${store.token}`,
        'Content-Type': 'application/json'
    }
})

export const VerComoClients = () => {
    return api.get(routes.kdi.clientsAll(), getHeader())
}

export const ListUsers = () => {
    return api.get(routes.kdi.usersAll(), getHeader())
}

export const filtroPontosInstall = (data) => {
    return api.post(routes.kdi.public.installPointsAll(), data, getHeader())

}

export const pins = (data) => {
    return api.post(routes.kdi.public.installPointsAll(), data, getHeader())
}
export const readings = (data) => {
    return api.get(routes.kdi.clientsAll(), data, getHeader())
}

export const tabelaSensores = (data) => {
    return api.post(routes.kdi.public.installPointsFilter(), data, getHeader())
}

export const listClientComissioning = (client_id) => {
    return api.post(routes.kdi.public.devicesInstallPoints(), {client_id}, getHeader())
}

export const listInstallPoint = (id) => {
    return api.post(routes.kdi.public.devicesInstallPoints(), {id}, getHeader())
}

export const updateAlarmes = (data) => {
    return api.patch(routes.kdi.meterDeviceInstallPoint(), data, getHeader())
}
export const getAlarmes = (data) => {
    return api.post(routes.kdi.meterDeviceInstallPointFilter(), data, getHeader())
}
export const readingsCharts = (data) => {
    return api.post(routes.turing.public.readings(), data, getHeader())
}

export const alarms = (device_id) => {
    return api.post(routes.kdi.meterDeviceInstallPointFilter(), {device_id}, getHeader())
}

export const commmunication = (data) => {
    return api.post(routes.kdi.connectivityDataFilterCommunicationHistory(), data, getHeader())
}

export const updateTag = (tags) => {
    return api.patch(routes.kdi.installPointsTags(), tags, getHeader())
}

export const relatorio = (inputs) => {
    return api.post(routes.turing.public.readingsAll(), inputs, getHeader())
}

export const listEvents = (data) => {
    return api.post(routes.kdi.event_dataFilterEventLogs(), data, getHeader())
}
export const aggregateResult = (data) => {
    let json = api.post('/event_data/filter/average_flow_hour', data, getHeader())
    return json
}

export const alarmsCount = (data) => {
    return api.post(routes.turing.countAlarms(), data, getHeader())
}

export const reportAll = ({start_date, end_date, device_type, client_id, email, type_file}) =>  {
    return api.post(routes.turing.reportReadingsAll(), {
        start_date,
        end_date,
        device_type,
        client_id,
        email,
        type_file
    }, getHeader())
}

export const devicesDataCalcAverage = (data) => {
    return api.post(routes.turing.devices_dataCalcAverageFlowHour(), data, getHeader())
}

export const devicesDataCalcAveragePerHour = (data) => {
    return api.post(routes.turing.devices_dataCalcAverageFlowPerHour(), data, getHeader())
}

export const devicesDataCalcAccumulated = (data) => {
    return api.post(routes.turing.devices_dataCalcAccumulatedFlow(), data, getHeader())
}

export default {
    VerComoClients,
    relatorio,
    updateTag,
    filtroPontosInstall,
    pins,
    readings,
    tabelaSensores,
    updateAlarmes,
    ListUsers,
    getAlarmes,
    alarms,
    listInstallPoint,
    commmunication,
    aggregateResult,
    alarmsCount,
    reportAll,
    devicesDataCalcAverage,
    devicesDataCalcAccumulated,
    devicesDataCalcAveragePerHour
}
