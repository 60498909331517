<template>
    <h2> Pontos de Instalação </h2>
</template>

<script>
export default {
    setup() {
        
    },
}
</script>

<style scoped>
h2{
  margin: 1rem
}
</style>