import {store} from './store.js'
import api from './api.js'
import {routes} from './routes'

const getHeader = () => ({
    headers: {
      'Authorization': `Bearer ${store.token}`,
      'Content-Type': 'application/json'
    }    
})

export const login = (data) => {
    return api.post(routes.auth.login(), data)
}

export const newPassword = (senha) => {
    return api.post(routes.kdi.changePassword(), senha, getHeader())
}

export const forgotPassword = (email) => {
    return api.post(routes.auth.remember(), email)
}

export default{login, newPassword, forgotPassword}
