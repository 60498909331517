import axios from 'axios'
import {API_URL as BASE_URL} from './config';
import { store } from './store.js'

const client = axios.create({
  baseURL: (BASE_URL),
  timeout: 15000,
  headers: {'Authorization': `Bearer ' + ${store.token}`}
})

export default client